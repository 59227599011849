/********************************************************************
 *
 * /Sales/Orders/Sales.Completed/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router";
import { Box } from "@mui/material";
import Pagination from "../../../lib/Pagination";
import LiveSearch from "../../../lib/LiveSearch";
import { useApp, useAPI } from "../../../../providers/AppProvider";
import Orders from "./Orders";

const PAGE_SIZE = 15;

/**
 * Sales
 *
 * Renders a searchable paged list of Orders.
 */
const Sales = () => {
  const [orders, setOrders] = useState(null);
  const [orderCount, setOrderCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [match, setMatch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const { setTitle } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    setTitle("Sales");
  }, []);

  useEffect(() => {
    fetchOrders(currPage, pageSize, match);
  }, [currPage, pageSize, match]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * fetchOrders
   *
   * Calls the api to fetch orders.
   *
   * Orders can be queried by matching user names or text in order
   * notes. Results can be paged.
   *
   * @param {*} currPage
   * @param {*} pageSize
   * @param {*} match
   */
  const fetchOrders = (currPage, pageSize, match) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(
        `/api/orders/?match=${match}&page=${currPage}&limit=${pageSize}&verbose`
      )
      .then(({ payload: orders, max: count }) => {
        setOrders(orders);
        setOrderCount(count);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * onSearch
   *
   * Fired when the user enters a search phrase.
   *
   * @param {*} e
   */
  const onSearch = ({ match }) => {
    setCurrPage(1);
    setMatch(match);
  };

  /**
   * Fired when the user changes the current page.
   *
   * @param {*} e
   */
  const onTurnPage = ({ currPage }) => {
    setCurrPage(currPage);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Box>
      <Box
        display="flex"
        alignItems="baseLine"
        justifyContent="space-between"
        flexWrap="wrap"
        mb={2}
      >
        <LiveSearch match={match} onSearch={onSearch} />
        <Pagination
          currPage={currPage}
          totalRecords={orderCount}
          pageSize={pageSize}
          pageNeighbours={1}
          onPageChanged={onTurnPage}
        />
      </Box>
      {loaded ? (
        orders ? (
          <Orders orders={orders} />
        ) : (
          "No Orders Available"
        )
      ) : (
        ""
      )}
    </Box>
  );
};

export default Sales;
