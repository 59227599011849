/********************************************************************
 *
 * Register.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Divider } from "@mui/material";
import format from "../../../../../utils/format";
import { WidgetFrame } from "../../../../lib";
import Book from "./Book";
import Totals from "./Totals";

/**
 * Register
 *
 * Summary of charges, discounts, taxes and receipts
 */
const Register = ({ order, onUpdated }) => {
  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * Render
   *
   */
  return (
    <WidgetFrame
      title={
        order.confirmation
          ? `CONFIRMED on ${format.dateTime(order.committed)} (#${
              order.confirmation
            })`
          : "PENDING"
      }
    >
      <Box>
        <Typography variant="h3" paragraph>
          Order Summary
        </Typography>
        <Totals order={order} />

        {!order.confirmation && (
          <Box>
            <Divider sx={{ my: 2 }} />
            <Book order={order} onUpdated={onUpdated} />
          </Box>
        )}
      </Box>
    </WidgetFrame>
  );
};

/*
 **  PropTypes
 */
Register.propTypes = {
  order: PropTypes.object.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default Register;
