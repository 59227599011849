import React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, Navigate, RouterProvider } from "react-router";
import { AuthCallback } from "./providers";
import * as serviceWorker from "./serviceWorker";

import app from "./routes/app";
import www from "./routes/www";

import Error404 from "./components/Error/404";

/**
 * Router
 *
 * Define the routes for the router. Root objects will be rendered within the
 * current container, whereas routes defined with a children member will
 * be rendered within the parent route.
 *
 */
const router = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to="/app/" />,
    errorElement: <Error404 />,
  },
  {
    path: "auth/code",
    element: <AuthCallback />,
  },
  { ...app },
  { ...www },
]);

createRoot(document.getElementById("root")).render(
  <RouterProvider router={router} />
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
