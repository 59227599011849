/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardMedia,
  CardContent,
  Box,
  Typography,
  IconButton,
  Avatar,
  Table,
  TableRow,
  TableCell,
  Link,
  TableBody,
} from "@mui/material";
import { Edit } from "@mui/icons-material";
import ProfileEdit from "./ProfileEdit";
import format from "../../../../../utils/format";

/**
 *  Index
 *
 * @param {*} param0
 * @returns
 */
const Index = ({ employee, onUpdate }) => {
  const [edit, setEdit] = useState(false);

  const onUpdateProfile = (employee) => {
    !!onUpdate && onUpdate(employee);
    setEdit(false);
  };

  if (!employee) return null;

  return (
    <Card sx={{ backgroundColor: "background.default" }}>
      <CardMedia
        sx={{
          position: "relative",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          py: 4,
          backgroundColor: "primary.dark",
        }}
      >
        <Box sx={{ position: "absolute", right: 0, top: 0, p: 1 }}>
          {!!onUpdate && (
            <IconButton
              onClick={() => setEdit(true)}
              color="secondary"
              size="large"
            >
              <Edit />
            </IconButton>
          )}
        </Box>
        <Box sx={{ pb: 2 }}>
          <Avatar
            src={employee.imageUrl}
            sx={{
              height: { xs: 150, md: 75, lg: 150 },
              width: { xs: 150, md: 75, lg: 150 },
              border: "solid 2px #ffffff",
            }}
          />
        </Box>
        <Typography
          color="secondary"
          variant="h3"
        >{`${employee.fname} ${employee.lname}`}</Typography>
        <Typography color="secondary" variant="body2">
          {employee.role}
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            pt: 2,
          }}
        >
          <Typography color="secondary" variant="body2">
            <Link href={`mailto:${employee.email}`}>{employee.email}</Link>
          </Typography>
        </Box>
      </CardMedia>
      <CardContent sx={{}}>
        <>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              alignItems: "center",
            }}
          >
            <Table
              size="small"
              sx={{
                width: "100%",
                tableLayout: "fixed",
                "& td": {
                  overflow: "hidden",
                  whiteSpace: "no-wrap",
                  textOverflow: "ellipsis",
                },
                "& .MuiTableCell-root": { borderBottom: 0 },
                "& .title": { opacity: 0.5, width: "30%" },
              }}
            >
              <TableBody>
                <TableRow>
                  <TableCell className="title">
                    <Typography variant="body3">DOB</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body3">
                      {format.date(employee.DOB, "Etc/UTC")}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="title">
                    <Typography variant="body3">SIN</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body3">{employee.SIN}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="title">
                    <Typography variant="body3">Phone</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body3">{employee.phone}</Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="title">
                    <Typography variant="body3">Start</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body3">
                      {format.date(employee.start)}
                    </Typography>
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell className="title">
                    <Typography variant="body3">End</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body3">
                      {!!employee.end ? format.date(employee.end) : "--"}
                    </Typography>
                  </TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
          <ProfileEdit
            employee={employee}
            show={edit}
            onUpdate={onUpdateProfile}
            onClose={() => {
              setEdit(false);
            }}
          />
        </>
      </CardContent>
    </Card>
  );
};

/**
 * PropTypes
 *
 */
Index.propTypes = {
  employee: PropTypes.object,
};

export default Index;
