/********************************************************************
 *
 * /components/public/schedule/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Container, Box, Typography, CircularProgress } from "@mui/material";
import { CardList } from "../../lib";
import format from "../../../utils/format";
import { usePublicAPI } from "../../../providers";
import ShiftCard from "./ShiftCard";

/**
 * Schedule
 *
 * Displays an employees upcoming shifts and shift management features.
 *
 */
const Schedule = () => {
  const [shifts, setShifts] = useState(null);
  const [error, setError] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const { encodedId } = useParams();
  const publicApi = usePublicAPI();

  useEffect(() => {
    fetchShifts();
  }, [encodedId]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Functions
  //
  ///////////////////////////////////////////////////////////////////

  const fetchShifts = () => {
    //
    //  Request searches
    //
    publicApi
      .fetch(
        `/api/employees/employee/${encodedId}/shifts/${format.toApiDateTime(
          Date.now()
        )}`
      )
      .then(({ payload: shifts }) => {
        console.log(shifts);
        setShifts(shifts);
        setLoaded(true);
      })
      .catch((error) => {
        setError(error);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onUpdateShiftStatus = (shiftId, status) => {
    publicApi
      .update(
        `/api/employees/employee/${encodedId}/shift/${shiftId}/response`,
        {
          status: status,
        }
      )
      .then(({ payload: updatedShift }) => {
        let index = shifts.findIndex((shift) => shift.id === updatedShift.id);

        shifts[index] = updatedShift;

        setShifts([...shifts]);
      })
      .catch((error) => {
        setError(error);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Container component="main" maxWidth="md" sx={{ padding: 4 }}>
      <Box sx={{ mb: 4 }}>
        <Typography variant="h1">Sailing Schedule</Typography>
        <Typography color="error">{error && error.message}</Typography>
        <hr />
      </Box>

      {!loaded ? (
        <Box display="flex" justifyContent="center" mt={5}>
          <CircularProgress />
        </Box>
      ) : shifts ? (
        <CardList
          items={shifts}
          xs={12}
          sm={12}
          md={6}
          lg={6}
          xl={6}
          spacing={2}
          onRenderCard={({ item: shift }) => (
            <ShiftCard
              shift={shift}
              onUpdateShiftStatus={onUpdateShiftStatus}
            />
          )}
        />
      ) : (
        <Typography variant="h3">No Scheduled Shifts</Typography>
      )}
    </Container>
  );
};

export default Schedule;
