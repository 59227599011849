/********************************************************************
 *
 * WorkOrderEdit.jsx
 *
 * Renders a control to add or edit a work order. The control is
 * contained in a drawer and managed by a Formik form.
 *
 * The control uses a reducer patterns, and the host of the control
 * must provide a dispatch and actions array that includes a
 * CREATE_WORKORDER and UPDATE_WORKORDER handler.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, TextField } from "@mui/material";
import * as yup from "yup";
import { DatePicker } from "@mui/x-date-pickers";
import format from "../../../../utils/format";
import { EditorPane, useEditorContext } from "../../../lib";
import { FormSection, FormComponent } from "../../../lib/EditorControls";
import { useApp, useAdminGatewayAPI } from "../../../../providers/AppProvider";
import { useUser } from "../../../../providers";
import Priorities from "./Priorities";
import Severities from "./Severities";
import Users from "./Users";
import Assets from "./Assets";

/**
 * WorkOrderEdit
 *
 * Adds or edits a work order.
 *
 * @param {*} param0
 */
const WorkOrderEdit = ({
  workOrder,
  open,
  onCreated,
  onUpdated,
  onClose,
  reducer: { dispatch, actions },
}) => {
  const [timezone, setTimezone] = useState("America/Vancouver");
  const app = useApp();
  const adminGatewayAPI = useAdminGatewayAPI();
  const user = useUser();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * HandleCreate
   *
   * Fired when the user adds a new work order
   *
   * @param {*} workOrder
   */
  const handleCreate = (workOrder) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Saving");

    adminGatewayAPI
      .create(`/api/bridge/workorders/workorder/`, workOrder)
      .then(({ payload: workOrder }) => {
        dispatch({ type: actions.CREATE_WORKORDER, payload: workOrder });
        onCreated && onCreated(workOrder);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  /**
   * HandleUpdate
   *
   * Fired when the user commits the change.
   *
   * @param {*} workOrder
   */
  const handleUpdate = (workOrder) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Saving");

    adminGatewayAPI
      .update(`/api/bridge/workorders/workorder/${workOrder.id}`, workOrder)
      .then(({ payload: workOrder }) => {
        dispatch({ type: actions.UPDATE_WORKORDER, payload: workOrder });
        onUpdated && onUpdated(workOrder);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  return (
    <EditorPane
      title={workOrder ? `Edit ${workOrder.name}` : "New Work Order"}
      initialValues={
        workOrder || {
          asset: { id: "" },
          name: "",
          description: undefined,
          severity: { id: "" },
          priority: { id: "" },
          created: format.todayISO(),
          createdBy: user,
          assignedTo: { id: "" },
        }
      }
      onSubmit={(values) => {
        if (workOrder) {
          values.id = workOrder.id;
          handleUpdate(values);
        } else {
          handleCreate(values);
        }
        onClose && onClose();
      }}
      validationSchema={yup.object().shape({
        asset: yup
          .object()
          .shape({ id: yup.number().required("Asset is required") }),
        priority: yup
          .object()
          .shape({ id: yup.number().required("Priority is required") }),
        severity: yup
          .object()
          .shape({ id: yup.number().required("Severity is required") }),
        name: yup.string().required("Work order name is required"),
        description: yup
          .string()
          .nullable()
          .required("Description is required"),
        createdBy: yup
          .object()
          .shape({ id: yup.number().required("CreatedBy is required") }),
        assignedTo: yup
          .object()
          .shape({ id: yup.number().required("Work order must be assigned") }),
      })}
      open={open}
      onClose={onClose}
    >
      <FormBody timezone={timezone} />
    </EditorPane>
  );
};

/**
 * FormBody
 *
 * @param {*} param0
 * @returns
 */
const FormBody = ({ timezone }) => {
  const {
    values,
    touched,
    errors,
    dirty,
    setFieldValue,
    setValues,
    handleChange,
    handleBlur,
  } = useEditorContext();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormSection>
        <FormComponent start={1} span={4}>
          <DatePicker
            name="created"
            label="WO Date"
            value={format.toDateTime(values.created, timezone) || null}
            slotProps={{ field: { clearable: true } }}
            onChange={(value) => {
              setFieldValue("created", value);
            }}
          />
        </FormComponent>
        <FormComponent start={5} span={5}>
          <Assets
            name="asset.id"
            value={(!!values.asset && values.asset.id) || ""}
            onChange={handleChange}
            error={
              !!touched.asset &&
              !!touched.asset.id &&
              !!errors.asset &&
              !!errors.asset.id
            }
            helperText={
              (!!touched.asset &&
                !!touched.asset.id &&
                !!errors.asset &&
                errors.asset.id) ||
              " "
            }
          />
        </FormComponent>
        <FormComponent>
          <TextField
            name="name"
            label="Name"
            value={values.name || ""}
            onChange={handleChange}
            error={!!touched.name && !!errors.name}
            helperText={(!!touched.name && errors.name) || " "}
            sx={{ width: "100%" }}
          />
        </FormComponent>
        <FormComponent>
          <TextField
            name="description"
            label="Description"
            multiline
            rows="2"
            slotProps={{ htmlInput: { maxLength: 128 } }}
            value={values.description || ""}
            onChange={handleChange}
            error={!!touched.description && !!errors.description}
            helperText={(!!touched.description && errors.description) || " "}
            sx={{ width: "100%" }}
          />
        </FormComponent>
        <FormComponent start={1} span={4}>
          <DatePicker
            name="due"
            label="Date Due"
            value={format.toDateTime(values.due, timezone) || null}
            slotProps={{ field: { clearable: true } }}
            onChange={(value) => {
              setFieldValue("due", value);
            }}
          />
        </FormComponent>
        <FormComponent start={5} span={5}>
          <Severities
            name="severity.id"
            value={
              !!values.severity && values.severity.id != null
                ? values.severity.id
                : ""
            }
            onChange={handleChange}
            error={
              !!touched.severity &&
              !!touched.severity.id &&
              !!errors.severity &&
              !!errors.severity.id
            }
            helperText={
              (!!touched.severity &&
                !!touched.severity.id &&
                !!errors.severity &&
                errors.severity.id) ||
              " "
            }
          />
        </FormComponent>
      </FormSection>
      <FormSection title="Assigned">
        <FormComponent start={1} span={6}>
          <Users
            name="assignedTo.id"
            value={(values.assignedTo && values.assignedTo.id) || ""}
            onChange={(event) => {
              setFieldValue("assignedTo.id", event.target.value);
            }}
            error={
              !!touched.assignedTo &&
              !!touched.assignedTo.id &&
              !!errors.assignedTo &&
              !!errors.assignedTo.id
            }
            helperText={
              (!!touched.assignedTo &&
                !!touched.assignedTo.id &&
                !!errors.assignedTo &&
                errors.assignedTo.id) ||
              " "
            }
          />
        </FormComponent>
        <FormComponent start={7} span={6}>
          <Priorities
            name="priority.id"
            value={
              !!values.priority && values.priority.id != null
                ? values.priority.id
                : ""
            }
            onChange={handleChange}
            error={
              !!touched.priority &&
              !!touched.priority.id &&
              !!errors.priority &&
              !!errors.priority.id
            }
            helperText={
              (!!touched.priority &&
                !!touched.priority.id &&
                !!errors.priority &&
                errors.priority.id) ||
              " "
            }
          />
        </FormComponent>
      </FormSection>
    </Box>
  );
};

/**
 * PropTypes
 */
WorkOrderEdit.propTypes = {
  workOrder: PropTypes.object,
  open: PropTypes.bool,
  onCreated: PropTypes.func,
  onUpdated: PropTypes.func,
  onClose: PropTypes.func,
  reducer: PropTypes.object,
};

export default WorkOrderEdit;
