/********************************************************************
 *
 * Priorities.jsx
 *
 * Renders a control to select a priority. Currently the priorities
 * are sourced from the api, but it may make sense to avoid the call
 * and hardwire the options.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, MenuItem } from "@mui/material";
import { useApp, useAdminGatewayAPI } from "../../../../providers/AppProvider";

/**
 * Priorities
 *
 * @param {*} param0
 */
const Priorities = ({
  name,
  label = "Priority",
  value = "",
  disabled,
  onChange,
  onBlur,
  error = false,
  helperText,
  sx,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [priorities, setPriorities] = useState(null);
  const adminGatewayAPI = useAdminGatewayAPI();
  const app = useApp();

  useEffect(() => {
    fetchPriorities();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchPriorities
   */
  const fetchPriorities = () => {
    adminGatewayAPI
      .fetch(`/api/bridge/workorders/workorder/priorities`)
      .then(({ payload: priorities }) => {
        setPriorities(priorities);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  return (
    <TextField
      name={name}
      label={label}
      select
      value={value != null ? value : ""}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      sx={[{ width: "100%" }, sx]}
    >
      {!priorities ? (
        <MenuItem>Loading...</MenuItem>
      ) : (
        priorities.map((priority) => (
          <MenuItem key={priority.id} value={priority.id}>
            {priority.name}
          </MenuItem>
        ))
      )}
    </TextField>
  );
};

/**
 * PropTypes
 */
Priorities.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sx: PropTypes.object,
};

export default Priorities;
