/********************************************************************
 *
 * Timesheet.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { DateTime, Interval } from "luxon";
import {
  Box,
  Typography,
  IconButton,
  Avatar,
  Table,
  TableRow,
  TableCell,
  Link,
} from "@mui/material";
import format from "../../../../utils/format";
import useSelectedDate from "../../../../hooks/selectedDate";
import { Timesheet } from "../../../lib/Calendars";

const EmployeeTimesheet = ({ tz = "America/Vancouver" }) => {
  return <Timesheet employeeId={1} />;
};

export default EmployeeTimesheet;
