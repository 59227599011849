/********************************************************************
 *
 * /components/public/schedule/TourSummary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  Box,
  Typography,
  Stepper,
  Step,
  StepLabel,
  StepConnector,
} from "@mui/material";
import { Place } from "@mui/icons-material";
import format from "../../../utils/format";

/**
 * TourSummary
 *
 * @param {*} param0
 */
const TourSummary = ({ tour }) => {
  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <Stepper
        activeStep={-1}
        alternativeLabel
        connector={
          <Connector
            duration={
              format.isSameDay(tour.start, tour.end, tour.tz)
                ? format.hoursBetween(tour.start, tour.end)
                : format.toDurationString(tour.start, tour.end, ["days"])
            }
          />
        }
      >
        <Step>
          <StepLabel slots={{ stepIcon: Place }}>
            {format.dateTimeShort(tour.start, tour.tz)}
          </StepLabel>
        </Step>
        <Step>
          <StepLabel slots={{ stepIcon: Place }}>
            {format.dateTimeShort(tour.end, tour.tz)}
          </StepLabel>
        </Step>
      </Stepper>
      {!!tour.notes && (
        <Box sx={{ my: 2, p: 2, border: "1px solid #eeeeee" }}>
          <Typography variant="body2">{`${tour.notes}`}</Typography>
        </Box>
      )}
    </Box>
  );
};

const Connector = ({ duration }) => (
  <StepConnector
    sx={{
      "& span": { textAlign: "center" },
      "& span:before": { content: `"${duration}"` },
    }}
  />
);
export default TourSummary;
