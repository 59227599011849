/********************************************************************
 *
 * Totals.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import format from "../../../../../utils/format";

const Category = ({ name, total, children }) => (
  <Box sx={{ pb: 0.5 }}>
    <Box sx={{ pb: 0.5 }}>
      <Typography variant="body3" sx={{ fontWeight: 600 }}>
        {name}:
      </Typography>
    </Box>
    <Box sx={{ pl: 1 }}>{children}</Box>
    <TotalsLine name={`TOTAL ${name}`} amount={total} />
  </Box>
);

const ItemLine = ({ name, amount }) => (
  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
    <Typography variant="body3">{name}:</Typography>
    <Typography variant="currency" sx={{ fontSize: "0.75rem" }}>
      {format.currency(amount)}
    </Typography>
  </Box>
);

const TotalsLine = ({ name, amount }) => (
  <Box
    sx={{ display: "flex", justifyContent: "space-between", pt: 0.5, pb: 1 }}
  >
    <Typography variant="body3" sx={{ fontWeight: 600, pt: 1 }}>
      {name}:
    </Typography>
    <Typography
      variant="currency"
      sx={{
        fontSize: "0.75rem",
        fontWeight: 500,
        borderTop: "1px solid #cccccc",
        pt: 1,
      }}
    >
      {format.currency(amount)}
    </Typography>
  </Box>
);

/**
 * Totals
 *
 * @param {*} param0
 * @returns
 */
const Totals = ({ order }) => (
  <Box sx={{ fontSize: "0.75em" }}>
    {!!order.charges.items && (
      <Category name="CHARGES" total={order.charges.amount}>
        {order.charges.items.map((charge, index) => (
          <React.Fragment key={index}>
            <ItemLine name={charge.description} amount={charge.total} />
            {charge.discounts && charge.discounts.total > 0 && (
              <ItemLine name="Discount" amount={charge.discounts.total * -1} />
            )}
          </React.Fragment>
        ))}
      </Category>
    )}
    {order.taxes && (
      <Category name="TAXES" total={order.taxes.total}>
        {order.taxes.items &&
          order.taxes.items.map((tax, index) => (
            <ItemLine key={index} name={tax.taxType.name} amount={tax.amount} />
          ))}
      </Category>
    )}
    <TotalsLine name="GRAND TOTAL" amount={order.totals.amount} />

    {order.receipts && order.receipts.items && (
      <>
        {order.receipts.items.map((receipt, index) => (
          <ItemLine
            key={index}
            name={receipt.receiptType.name}
            amount={receipt.amount}
          />
        ))}
        <TotalsLine name="TOTAL PAYMENTS" amount={order.receipts.total} />
      </>
    )}
    <TotalsLine name="AMOUNT DUE" amount={order.totals.due} />
  </Box>
);

/*
 **  PropTypes
 */
Totals.propTypes = {
  order: PropTypes.object.isRequired,
};

export default Totals;
