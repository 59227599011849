/********************************************************************
 *
 * /components/goods/goods/tour/orders.jsx
 *
 * Renders the orders that have booked products containing the
 * tour.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, Avatar, Chip, Typography } from "@mui/material";
import { ShoppingCartOutlined } from "@mui/icons-material";
import {
  WidgetFrame,
  Property,
  CardList,
  LinkCard2,
  CardContentHeader,
} from "../../lib";
import format from "../../../utils/format";
import { useApp, useAPI } from "../../../providers/AppProvider";

const Orders = ({ tour }) => {
  const [orders, setOrders] = useState(null);
  const app = useApp();
  const api = useAPI();

  /**
   *  Orders State Management
   */
  useEffect(() => {
    if (tour) fetchOrders(tour.id);
  }, [tour]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchOrders
   *
   * @param {*} tourId
   */
  const fetchOrders = (tourId) => {
    api
      .fetch(`/api/tours/tour/${tourId}/orders/?verbose`)
      .then(({ payload: orders }) => {
        setOrders(orders);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <WidgetFrame
      avatar={
        <Avatar sx={{ "&:root": { bgcolor: "primary.main" } }}>
          <ShoppingCartOutlined />
        </Avatar>
      }
      title={`${orders && orders.length ? orders.length : "No"} Orders`}
    >
      <CardList
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={6}
        items={orders}
        spacing={2}
        headerBG="#01899f"
        onRenderCard={({ item: order }) => (
          <LinkCard2
            link={`/app/sales/order/${order.id}`}
            elevation={0}
            header={
              <CardContentHeader
                avatar={<Chip color="primary" label={order.confirmation} />}
                content={
                  <Box
                    data-test-id="ORDER_INFO"
                    display="flex"
                    justifyContent="space-between"
                    sx={{
                      display: "flex",
                      gap: 2,
                      flexDirection: { xs: "column", md: "row", xl: "column" },
                      justifyContent: "space-between",
                      width: "100%",
                    }}
                  >
                    <Typography variant="h2">{order.name}</Typography>
                    <Box
                      sx={{
                        minWidth: "200px",
                        textAlign: { xs: "left", lg: "right", xl: "left" },
                      }}
                    >
                      <Typography variant="body2">{`Booked by ${order.user.fname} ${order.user.lname}`}</Typography>
                      <Typography variant="body2">{`on ${format.dateTime(
                        order.committed
                      )}`}</Typography>
                    </Box>
                  </Box>
                }
              />
            }
            content={!!order.message && <Property>{order.message}</Property>}
          />
        )}
      />
    </WidgetFrame>
  );
};

export default Orders;
