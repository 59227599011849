/********************************************************************
 *
 * /components/lib/analytics/superStat.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2021 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Card, CardHeader, CardContent, Typography } from "@mui/material";

const SuperStat = ({ icon, label, value }) => {
  return (
    <Card
      date-test-id="SUPER_STAT"
      sx={{
        height: "100%",
        p: 0,
      }}
    >
      <CardHeader
        avatar={icon}
        title={label}
        sx={{
          "&.MuiCardHeader-root": { pb: 0 },
          "& .MuiCardHeader-title": {
            color: "text.primary",
            fontSize: "0.95em",
            fontWeight: 500,
            textTransform: "uppercase",
          },
        }}
      />
      <CardContent style={{ textAlign: "center" }}>
        <Typography variant="h2">{value}</Typography>
      </CardContent>
    </Card>
  );
};

export default SuperStat;
