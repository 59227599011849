/********************************************************************
 *
 * /src/components/catalog/products/rates/RateClasses.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AccordionActions,
  Button,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RateClassSummaryHeader } from "../../RateClass/Identity/RateClassSummaryHeader";
import Rates from "./Rates";

/**
 * RateClassCard
 *
 * Renders a card for the Rate Class
 *
 * @param {*} props
 */
const RateClassCard = ({ productType, rateClass, onEdit, onDelete }) => {
  return (
    <Accordion id={`rc${rateClass.id}`}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <RateClassSummaryHeader rateClass={rateClass} />
      </AccordionSummary>
      <AccordionDetails>
        <Rates
          productType={productType}
          rateClass={rateClass}
          rates={rateClass.rates}
          onEdit={onEdit}
          onDelete={onDelete}
        />
      </AccordionDetails>
      <AccordionActions>
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => {
            onEdit({ rateClass, rate: {} });
          }}
        >
          Add Rate
        </Button>
        <Button
          size="small"
          onClick={() => {
            alert("TBD");
          }}
        >
          Delete
        </Button>
      </AccordionActions>
    </Accordion>
  );
};

export default RateClassCard;
