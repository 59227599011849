/********************************************************************
 *
 * lib/Layout/Main.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router";
import {
  Container,
  Box,
  Paper,
  Drawer,
  IconButton,
  Avatar,
  Badge,
  LinearProgress,
  Typography,
} from "@mui/material";
import { Menu as MenuIcon, MenuOpen } from "@mui/icons-material";

/**
 * Main
 *
 * @param {*} props
 *
 */
const Main = ({ menu, header, loaded = true, context, children }) => {
  const [mobileMenuVisible, setMobileMenuVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    setMobileMenuVisible(false);
  }, [location]);

  return (
    <Container disableGutters={true} maxWidth={false} className="app-main">
      <Box sx={{ display: { xs: "block", lg: "none" } }}>
        <MobileMenu
          {...menu}
          show={mobileMenuVisible}
          handleCloseMenu={() => setMobileMenuVisible(false)}
        />
      </Box>
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <BrowserMenu {...menu} />
      </Box>
      <Box className={`app-content ${!!menu && "app-content-sidebar-fixed"}`}>
        <Box className="app-content--inner">
          {!loaded ? (
            <Box
              sx={{
                width: "100%",
              }}
            >
              <LinearProgress />
            </Box>
          ) : (
            <Content
              {...header}
              context={context}
              children={children}
              handleShowMobileMenu={() => setMobileMenuVisible(true)}
            />
          )}
        </Box>
      </Box>
    </Container>
  );
};

const Content = ({
  icon,
  title,
  subtitle,
  callout,
  bonus,
  context,
  children,
  handleShowMobileMenu,
}) => {
  return (
    <Container disableGutters maxWidth={false}>
      <Paper className="app-page-title" elevation={2} square>
        <Box style={{ width: "100%" }}>
          <Box className="app-page-title--first">
            <Box className="app-page-title--mobilemenu">
              <MenuIcon onClick={handleShowMobileMenu} />
            </Box>
            <Box
              className={`app-page-title--iconbox ${
                !!subtitle ? "subtitled" : ""
              }`}
            >
              {icon}
            </Box>
            <Box className="app-page-title--heading">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                style={{ width: "100%" }}
              >
                <Box flexGrow="1">
                  <Badge
                    badgeContent={callout ? callout.label : undefined}
                    color={callout ? callout.color : undefined}
                  >
                    <Typography variant="h1">{title}</Typography>
                  </Badge>
                  <Typography
                    sx={{
                      margin: "0rem 0 0",
                      fontSize: "1.045rem",
                      opacity: "0.6",
                      fontWeight: 400,
                    }}
                    component="div"
                  >
                    {subtitle}
                  </Typography>
                </Box>
                <Box>{bonus}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Paper>
      {!!context && <Box>{context}</Box>}
      <Container
        data-test-id="PAGE_CONTENT"
        disableGutters
        maxWidth="xl"
        sx={{ px: { xs: 2, sm: 4, md: 4 }, py: 4 }}
      >
        {children}
      </Container>
    </Container>
  );
};

const MobileMenu = ({
  icon,
  title,
  subtitle,
  nav,
  bonus,
  show,
  handleCloseMenu,
}) => {
  if (!nav) return null;

  return (
    <Drawer anchor="left" open={show} onClose={handleCloseMenu}>
      <Box className="app-sidebar-header">
        <Box display="flex" alignItems="center">
          <Avatar
            className={`app-sidebar-userbox-avatar`}
            sx={{
              backgroundColor: "RGBA(1, 137, 159, 0.025)",
              color: "primary.main",
            }}
          >
            {icon}
          </Avatar>
          <Box className="header-logo-text">{title}</Box>
        </Box>
        <IconButton onClick={handleCloseMenu} size="large">
          <MenuOpen />
        </IconButton>
      </Box>
      {nav}
    </Drawer>
  );
};

const BrowserMenu = ({ icon, title, subtitle, nav, bonus }) => {
  if (!nav) return null;

  return (
    <>
      <Paper
        className="app-sidebar-wrapper app-sidebar-wrapper-fixed"
        elevation={3}
        square
      >
        <div className="app-sidebar-header"></div>
        <div className="app-sidebar-menu">
          <div className="scrollbar-container ps">
            <div className="app-sidebar-userbox mb-3">
              <Avatar
                className={`app-sidebar-userbox-avatar`}
                sx={{
                  backgroundColor: "RGBA(1, 137, 159, 0.025)",
                  color: "primary.main",
                }}
              >
                {icon}
              </Avatar>
              <div className="app-sidebar-userbox-name">
                {title}
                {!!subtitle && (
                  <div className="app-sidebar-userbox-description">
                    {subtitle}
                  </div>
                )}
              </div>
            </div>
            <div>{nav}</div>
          </div>
        </div>
      </Paper>
    </>
  );
};

/**
 * PropTypes
 */
Main.propTypes = {
  menu: PropTypes.object,
  header: PropTypes.object,
  loaded: PropTypes.bool,
  context: PropTypes.object,
  children: PropTypes.node,
};

export default Main;
