/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Container,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import { Formik, useFormikContext } from "formik";

/**
 * EditorPane
 *
 * A container used for create and update controls.
 *
 * @param {*} param0
 *
 * @returns
 */
const EditorPane = ({
  title,
  label = "Save",
  width = "70vw",
  initialValues,
  validationSchema,
  open,
  onSubmit,
  onClose,
  children,
}) => {
  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize={true}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
    >
      {(props) => (
        <Drawer
          anchor="right"
          open={open}
          sx={{ "& .MuiDrawer-paper": { backgroundColor: "#f3f3f3" } }}
        >
          <form onSubmit={props.handleSubmit}>
            <AppBar position="sticky" elevation={2}>
              <Toolbar
                sx={{ backgroundColor: "#ffffff", color: "text.primary" }}
              >
                <IconButton
                  size="large"
                  edge="start"
                  color="inherit"
                  onClick={() => {
                    props.handleReset();
                    !!onClose && onClose();
                  }}
                  sx={{ mr: 2 }}
                >
                  <Close />
                </IconButton>
                <Box sx={{ flexGrow: 1 }}>
                  <Typography variant="h4">{title}</Typography>
                </Box>
                <Button
                  variant="contained"
                  disabled={!props.dirty && props.isValid}
                  type="submit"
                >
                  {label}
                </Button>
              </Toolbar>
            </AppBar>
            <Box
              sx={{
                p: 4,
                minWidth: "600px",
                maxWidth: "1440px",
                width: width,
              }}
            >
              <Container maxWidth="md">{children}</Container>
            </Box>
          </form>
        </Drawer>
      )}
    </Formik>
  );
};

/**
 * PropTypes
 */
EditorPane.propTypes = {
  title: PropTypes.string,
  width: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
};

export { EditorPane, useFormikContext as useEditorContext };
