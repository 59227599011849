/********************************************************************
 *
 * DateController.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import { DateTime, Interval } from "luxon";
import useSelectedDate from "../../../hooks/selectedDate";

/**
 * DateController
 *
 * Renders a controller that navigates dates week-by-week.
 *
 * The @function onClick function is fired when the user changes the
 * date, which passes a @var moment representing the chosen date to
 * the event consumer.
 *
 * @param {*} props
 */
const DateController = ({ initialValue = DateTime.now(), onChange }) => {
  const [date, setDate] = useSelectedDate(
    initialValue,
    DateTime.fromFormat("1970-01-01", "yyyy-MM-dd"),
    DateTime.fromFormat("2050-01-01", "yyyy-MM-dd"),
    initialValue.zone
  );

  useEffect(() => {
    onChange(date.startOf("day"));
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  const renderDateInterval = (date) => {
    return `${Interval.fromDateTimes(
      date.startOf("week"),
      date.endOf("week")
    ).toLocaleString(DateTime.DATE_FULL)} (Week ${date.toFormat("WW")})`;
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onToday = () => {
    const newDate = DateTime.now();
    setDate(newDate);
    onChange(newDate.startOf("day"));
  };

  const onBack = () => {
    const newDate = date.minus({ week: 1 });
    setDate(newDate);
    onChange(newDate.startOf("day"));
  };

  const onForward = () => {
    const newDate = date.plus({ week: 1 });
    setDate(newDate);
    onChange(newDate.startOf("day"));
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Box
      data-test-id="DATE_CONTROLLER"
      sx={{
        minWidth: "auto!important",
        textAlign: "right",
        lineHeight: 0,
        whiteSpace: "nowrap",
      }}
    >
      <Box
        sx={{
          overflow: "hidden",
          display: "table-cell",
          height: "48px",
          verticalAlign: "middle",
          textAlign: "initial",
        }}
      >
        <Box
          sx={{
            alignItems: "center",
            display: "flex",
            lineHeight: "normal",
            marginLeft: "8px",
            width: "100%",
          }}
        >
          <Box sx={{ display: "inline-block" }}>
            <Box
              sx={{
                display: "flex",
                marginRight: "4px",
                marginLeft: "0px",
                whiteSpace: "nowrap",
                alignItems: "center",
              }}
            >
              <Button
                onClick={onToday}
                variant="outlined"
                sx={{
                  border: "1px solid #dadce0;",
                  boxSizing: "border-box",
                  fontSize: "14px",
                  height: "36px",
                  marginRight: "12px",
                  lineHeight: "20px",
                  transition: "background-color 100ms linear",
                  outline: "none!important",
                }}
              >
                Today
              </Button>
              <IconButton
                onClick={onBack}
                size="large"
                sx={{
                  height: "36px",
                  width: "36px",
                  outline: "none!important",
                }}
              >
                <ChevronLeft />
              </IconButton>
              <IconButton
                onClick={onForward}
                size="large"
                sx={{
                  height: "36px",
                  width: "36px",
                  outline: "none!important",
                }}
              >
                <ChevronRight />
              </IconButton>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
            }}
          >
            <Box
              sx={{
                display: "inline-block",
                lineHeight: 0,
                marginLeft: "8px",
                marginRight: "2px",
                overflow: "hidden",
                position: "relative",
              }}
            >
              <Box
                sx={{
                  padding: "8px",
                  float: "left",
                  fontSize: "16px",
                  position: "relative",
                  transition: "margin .3s cubic-bezier(0.4, 0, 0.2, 1)",
                  textTransform: "none",
                  textAlign: "left",
                }}
              >
                <Typography
                  sx={{
                    color: "primary.dark",
                    fontSize: "22px",
                    fontWeight: 200,
                    letterSpacing: 0,
                    lineHeight: "28px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {renderDateInterval(date)}
                </Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

/*
 **  PropTypes
 */
DateController.propTypes = {
  initialValue: PropTypes.instanceOf(DateTime),
  onChange: PropTypes.func.isRequired,
};

export default DateController;
