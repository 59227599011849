/********************************************************************
 *
 * Week.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box } from "@mui/material";
import Day from "./Day";

/**
 * Week
 *
 * Renders a row of seven CalandarDays on the calendar grid.
 */
const Week = ({ startDate, selectedDate, onDateRender, onClickDate }) => (
  <Box
    sx={{
      display: "flex",
      flexWrap: "nowrap",
      justifyContent: "space-evenly",
      alignItems: "stretch",
      alignContent: "stretch",
      width: "100% !important",
      height: "100%",
      minHeight: "60px",
      borderBottom: "1px solid rgb(235, 235, 235) !important",
    }}
  >
    {[...Array(7).keys()].map((key, index) => (
      <Day
        key={key}
        date={startDate.plus({ day: index })}
        selectedDate={selectedDate}
        onDateRender={onDateRender}
        onClickDate={onClickDate}
      />
    ))}
  </Box>
);

export default Week;
