/********************************************************************
 *
 * /src/components/sales/accounting/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router";
import {
  Paper,
  Box,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import format from "../../../../utils/format";

import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Accounting
 *
 * Component that displays the accounting transactions for an order.
 *
 */
const Accounting = () => {
  const [transactions, setTransactions] = useState(null);
  const { order } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchTransactions(order.id);
  }, [order]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * fetchTransactions
   *
   * Calls the API to fetch transactions for the current order
   *
   * @param {*} orderId
   */
  const fetchTransactions = (orderId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Transactions");

    api
      .fetch(`/api/orders/order/${orderId}/transactions`)
      .then(({ payload: transactions }) => {
        setTransactions(transactions);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setTransactions(null);
      });
  };

  if (!transactions) return null;

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      {!transactions && "No transactions available"}
      {transactions &&
        transactions.map((transaction) => (
          <Paper key={transaction.id} sx={{ p: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box>
                <Typography variant="h5">
                  {transaction.type.name} (#{transaction.id})
                </Typography>
              </Box>
              <Typography variant="h5">
                POSTED {format.dateTimeShort(transaction.date)}
              </Typography>
            </Box>
            <Box
              sx={{
                my: 2,
                borderTop: "1px solid #999999",
                borderBottom: "1px solid #999999",
              }}
            >
              <Entries entries={transaction.entries} />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Typography variant="h5">{transaction.description}</Typography>
              <Typography variant="h5">
                {`Posted By ${transaction.user.fname} ${transaction.user.lname}`}
              </Typography>
            </Box>
          </Paper>
        ))}
    </Box>
  );
};

const Entries = ({ entries }) => {
  return (
    <Table>
      <TableHead sx={{ fontWeight: 600 }}>
        <TableRow>
          <TableCell sx={{ width: "150px" }}>ID</TableCell>
          <TableCell>Account</TableCell>
          <TableCell sx={{ width: "150px" }}>DR</TableCell>
          <TableCell sx={{ width: "150px" }}>CR</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {entries.debits &&
          entries.debits.map((entry) => (
            <tr key={entry.id}>
              <TableCell>{entry.id}</TableCell>
              <TableCell>{entry.account.name}</TableCell>
              <TableCell className="text-right">
                {format.currency(entry.amount)}
              </TableCell>
              <TableCell></TableCell>
            </tr>
          ))}
        {entries.credits &&
          entries.credits.map((entry) => (
            <tr key={entry.id}>
              <TableCell>{entry.id}</TableCell>
              <TableCell>{entry.account.name}</TableCell>
              <TableCell></TableCell>
              <TableCell className="text-right">
                {format.currency(entry.amount)}
              </TableCell>
            </tr>
          ))}
      </TableBody>
    </Table>
  );
};

export default Accounting;
