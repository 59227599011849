/********************************************************************
 *
 * Partners.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router";
import {
  Grid2 as Grid,
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
} from "@mui/material";
import PartnerSummary from "../../../Sales/Partners/PartnerSummary";
import RateClasses from "./RateClasses";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Partners
 *
 * Renders a list of Partners able to sell the Product Type.
 *
 */
const Partners = () => {
  const [partners, setPartners] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const { productType } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchPartners();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchPartners
   *
   * Calls the api to fetch the partners assigned to the product type
   *
   */
  const fetchPartners = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Partners");

    api
      .fetch(`/api/productTypes/productType/${productType.id}/partners/`)
      .then(({ payload: partners }) => {
        setPartners(partners);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setPartners(null);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!loaded) return;
  if (!partners) return "No Partners Available";

  return (
    <Grid container spacing={{ xs: 2, md: 4 }}>
      {partners.map((partner) => (
        <Grid item key={partner.id} size={{ xs: 12, lg: 6 }}>
          {!partner.deleted && (
            <PartnerCard productType={productType} partner={partner} />
          )}
        </Grid>
      ))}
    </Grid>
  );
};

/**
 * PartnerCard
 *
 * Renders a list of Partner summaries in card format
 *
 * @param {*} props
 */
const PartnerCard = ({ productType, partner }) => {
  return (
    <Card sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <CardContent sx={{ flexGrow: 1 }}>
        <PartnerSummary partner={partner} />
        <Typography variant="h4">Rate Classes</Typography>
        <RateClasses productType={productType} partner={partner} />
      </CardContent>
      <CardActions>
        <Button type="button">Remove</Button>
      </CardActions>
    </Card>
  );
};
export default Partners;
