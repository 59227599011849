/********************************************************************
 *
 * Actions.jsx
 *
 * Renders the appropriate ticket actions based upon the current
 * ticket status.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import {
  FolderOpen,
  Checklist,
  PauseCircleOutline,
  ErrorOutline,
  TaskOutlined,
} from "@mui/icons-material";
import {
  useApp,
  useAdminGatewayAPI,
} from "../../../../../providers/AppProvider";

const Actions = ({ ticket, reducer: { state, dispatch, actions } }) => {
  const app = useApp();
  const adminGatewayAPI = useAdminGatewayAPI();

  /**
   * Postpone Ticket
   *
   */
  const postponeTicket = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Postponing Ticket");

    adminGatewayAPI
      .update(`/api/bridge/tickets/ticket/${ticket.id}/status/postponed`)
      .then(({ payload: { ticket, event } }) => {
        dispatch({ type: actions.TICKET_EVENT, payload: { ticket, event } });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  /**
   * Postpone Ticket
   *
   */
  const openTicket = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Opening Ticket");

    adminGatewayAPI
      .update(`/api/bridge/tickets/ticket/${ticket.id}/status/opened`)
      .then(({ payload: { ticket, event } }) => {
        dispatch({ type: actions.TICKET_EVENT, payload: { ticket, event } });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  /**
   * Resolve Ticket
   *
   */
  const completeTicket = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Completing Ticket");

    adminGatewayAPI
      .update(`/api/bridge/tickets/ticket/${ticket.id}/status/completed`)
      .then(({ payload: { ticket, event } }) => {
        dispatch({ type: actions.TICKET_EVENT, payload: { ticket, event } });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  /**
   * Close Ticket
   *
   */
  const closeTicket = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Closing Ticket");

    adminGatewayAPI
      .update(`/api/bridge/tickets/ticket/${ticket.id}/status/closed`)
      .then(({ payload: { ticket, event } }) => {
        dispatch({ type: actions.TICKET_EVENT, payload: { ticket, event } });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  return (
    <Box sx={{ display: "flex", gap: 2, justifyContent: "stretch" }}>
      {ticket.status.id == 0 || ticket.status.id == 3 ? (
        //
        //  Ticket status is postponed or closed
        //
        <Button
          size="small"
          variant="text"
          startIcon={<FolderOpen />}
          onClick={() => {
            openTicket();
          }}
          sx={{ px: 4 }}
        >
          Re-Open
        </Button>
      ) : ticket.status.id == 1 ? (
        //
        //  Ticket is open
        //
        <>
          <Button
            size="small"
            variant="text"
            startIcon={<Checklist />}
            onClick={() => {
              completeTicket();
            }}
            sx={{ px: 4 }}
          >
            Resolve
          </Button>
          <Button
            size="small"
            variant="text"
            startIcon={<PauseCircleOutline />}
            onClick={() => {
              postponeTicket();
            }}
            sx={{ px: 4 }}
          >
            Postpone
          </Button>
        </>
      ) : ticket.status.id == 2 ? (
        //
        //  Ticket is completed
        //
        <>
          <Button
            size="small"
            variant="text"
            startIcon={<ErrorOutline />}
            onClick={() => {
              openTicket();
            }}
            sx={{ px: 4 }}
          >
            Re-open
          </Button>
          <Button
            size="small"
            variant="text"
            startIcon={<TaskOutlined />}
            onClick={() => {
              closeTicket();
            }}
            sx={{ px: 4 }}
          >
            Verified
          </Button>
        </>
      ) : (
        ""
      )}
    </Box>
  );
};

/**
 * PropTypes
 *
 */
Actions.propTypes = {
  ticket: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  reducer: PropTypes.object.isRequired,
};

export default Actions;
