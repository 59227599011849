/********************************************************************
 *
 * /Sales/Orders/Abandoned/Orders.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  Typography,
  Paper,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import format from "../../../../utils/format";

/**
 * PaginatedTable
 *
 * @param {*} param0
 */
const PaginatedTable = ({ orders }) => {
  if (!orders) return <Typography variant="h4">No Orders Available</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ width: "100%" }}>
        <TableHead>
          <TableRow sx={{ "& th": { fontWeight: 600 } }}>
            <TableCell>Order</TableCell>
            <TableCell>Created</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>UID</TableCell>
            <TableCell>Name</TableCell>
            <TableCell>Account</TableCell>
            <TableCell>Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map(
            (order) =>
              !!order && (
                <TableRow
                  key={order.id}
                  hover
                  onClick={() => {
                    window.open(`/app/sales/order/${order.id}`, "_blank");
                    //navigate(`/app/sales/order/${order.id}`);
                  }}
                  sx={[
                    order.deleted ? { textDecoration: "line-through" } : null,
                    {
                      "& td, & th": { fontSize: "0.85em" },
                      "&:nth-of-type(odd)": {
                        backgroundColor: "rgba(0,0,0,0.05)",
                      },
                    },
                  ]}
                >
                  <TableCell component="th" scope="row">
                    {order.id}
                  </TableCell>
                  <TableCell>{format.date(order.timestamp)}</TableCell>
                  <TableCell>
                    {!!order.charges
                      ? format.currency(order.charges.total)
                      : "--"}
                  </TableCell>
                  <TableCell>
                    {!order.user
                      ? "--"
                      : order.user.fname && order.user.lname
                      ? `${order.user.fname} ${order.user.lname}`
                      : order.user.id}
                  </TableCell>
                  <TableCell>{!!order.name ? order.name : "--"}</TableCell>
                  <TableCell>
                    {!!order.customer
                      ? `${order.customer.fname} ${order.customer.lname}`
                      : "Unknown"}
                  </TableCell>
                  <TableCell sx={{ maxWidth: "200px" }}>
                    <Box
                      component="span"
                      sx={{
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        display: "inline-block",
                        maxWidth: "100%",
                      }}
                    >
                      {order.message}
                    </Box>
                  </TableCell>
                </TableRow>
              )
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaginatedTable;
