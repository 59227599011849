/********************************************************************
 *
 * Lead.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Typography, Link } from "@mui/material";
import format from "../../../utils/format";
import moment from "moment-timezone";
import { ExpansionCard } from "../../lib";

const Lead = ({ lead }) => {
  if (!lead) return;

  return (
    <ExpansionCard
      key={lead.id}
      header={
        <Box sx={{ width: "100%" }}>
          <Typography variant="h6">
            {lead.user && `${lead.user.fname} ${lead.user.lname}`}
          </Typography>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Box sx={{ flex: "0 0 15%" }}>
              <Typography variant="caption">{lead.status.name}</Typography>
            </Box>
            <Box sx={{ flex: "0 0 30%" }}>
              <Typography variant="caption">{lead.source.name}</Typography>
            </Box>
            <Box sx={{ flex: "0 0 40%" }}>
              <Typography variant="caption">{lead.shortDesc}</Typography>
            </Box>
            <Box sx={{ flex: "0 0 15%", textAlign: "right" }}>
              <Typography variant="caption">
                {format.shortDateTime(moment(lead.timestamp))}
              </Typography>
            </Box>
          </Box>
        </Box>
      }
      content={
        <Box
          sx={{ display: "flex", flexDirection: { xs: "column", md: "row" } }}
          container
        >
          <Box sx={{ flex: "0 0 25%" }}>
            <Typography variant="body2" style={{ color: "#999999" }}>
              {lead.type.name}
            </Typography>
          </Box>
          <Box
            sx={{
              flex: "0 0 75%",
              borderLeft: "solid 2px #999999",
              paddingLeft: "2em",
            }}
          >
            <Typography variant="body2">
              {lead.notes && lead.notes[0].message}
            </Typography>
          </Box>
        </Box>
      }
      actions={<Link href={`/app/sales/leads/lead/${lead.id}`}>Edit</Link>}
    />
  );
};

export default Lead;
