/********************************************************************
 *
 * ProductTypeHeader.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Avatar, Typography } from "@mui/material";
import { CardContentHeader } from "../../../lib";
import FeatureTypeTimeline from "./FeatureTypeTimeline";
import FeatureType from "../../ProductType/FeatureTypes/FeatureType";

/**
 * ProductTypeHeader
 *
 * @param {*} param0
 *
 * @returns
 */
const ProductTypeHeader = ({ productType }) => (
  <CardContentHeader
    avatar={
      <Avatar
        variant="circular"
        src={productType.imageURL}
        alt={productType.name}
        sx={{
          width: "100%",
          height: "100%",
          maxWidth: "75px",
          minWidth: "75px",
          maxHeight: "75px",
          minHeight: "75px",
        }}
      >
        ?
      </Avatar>
    }
    callout={
      !!productType.deleted
        ? {
            label: "Deleted",
            color: "error",
          }
        : undefined
    }
    content={
      <Box
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        flexWrap="wrap"
        style={{ height: "100%", width: "100%" }}
      >
        <Box flexGrow={1} className="mb-2">
          <Typography variant="h4">{productType.name}</Typography>
          {productType.featureTypes && productType.featureTypes.length === 1 ? (
            <FeatureType featureType={productType.featureTypes[0]} />
          ) : (
            <FeatureTypeTimeline featureTypes={productType.featureTypes} />
          )}
        </Box>
      </Box>
    }
  />
);

/**
 * PropTypes
 */
ProductTypeHeader.propTypes = {
  productType: PropTypes.object.isRequired,
};

export default ProductTypeHeader;
