/********************************************************************
 *
 * Month.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box } from "@mui/material";
import Week from "./Week";

/**
 * Month
 *
 * Renders a row of seven CalandarDays on the calendar grid.
 *
 */
const Month = ({
  numWeeks,
  startDate,
  selectedDate,
  onDateRender,
  onClickDate,
}) => {
  if (!startDate || !startDate.isValid) return null;

  return (
    <Box>
      <Box
        sx={{
          width: "100% !important",
          height: "1.65em !important",
          borderBottom: "1px solid rgb(235, 235, 235) !important",
          "& div": {
            width: "14.2857% !important",
            height: "100% !important",
            display: "inline-block !important",
            position: "relative !important",
            paddingLeft: "5px",
          },
        }}
      >
        <Box>Su</Box>
        <Box>Mo</Box>
        <Box>Tu</Box>
        <Box>We</Box>
        <Box>Th</Box>
        <Box>Fr</Box>
        <Box>Sa</Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "stretch",
          borderRight: "solid 1px rgb(235, 235, 235)",
        }}
      >
        {[...Array(numWeeks).keys()].map((key, index) => (
          <Week
            key={key}
            startDate={startDate.plus({ week: index })}
            selectedDate={selectedDate}
            onDateRender={onDateRender}
            onClickDate={onClickDate}
          />
        ))}
      </Box>
    </Box>
  );
};

export default Month;
