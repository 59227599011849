/********************************************************************
 *
 * /src/components/catalog/products/rates/RateEdit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, TextField, InputAdornment } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import * as yup from "yup";
import { EditorPane, useEditorContext, WidgetFrame } from "../../../lib";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * RateEdit
 *
 * Widget for quickly viewing/editting/creating a Rate
 *
 * A rate is applied to a rateclass and a product type for certain
 * time periods.
 *
 */
const RateEdit = ({
  productType,
  rateClass,
  rate,
  show,
  onSaved,
  onCancelled,
}) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user saves the changes
   *
   * @param {*} event
   */
  const onSave = (rate) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving");

    api
      .update(
        `/api/producttypes/producttype/${
          productType.id
        }/rateclasses/rateclass/${rateClass.id}/rates/rate${
          rate && rate.id ? `/${rate.id}` : ""
        }`,
        rate
      )
      .then(() => {
        onSaved && onSaved({ rate: { ...rate } });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * OnCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <EditorPane
      title="Manage Rate"
      initialValues={
        rate
          ? { ...rate, amount: parseFloat(rate.amount / 100).toFixed(2) }
          : {
              amount: 0,
              start: "",
              end: "",
              open: "",
              close: "",
            }
      }
      onSubmit={(rate) => {
        //
        //  Currency is stored as pennies
        //
        rate.amount = rate.amount * 100;
        onSave(rate);
      }}
      validationSchema={yup.object().shape({
        amount: yup
          .number()
          .min(0, "Amount must be greater than zero")
          .required("Amount is required"),
        start: yup
          .date()
          .max(yup.ref("end"), "Start date must occur before end date")
          .required("Start date is required"),
        end: yup
          .date()
          .min(yup.ref("start"), "End date must occur after start date")
          .required("End date is required"),
        open: yup
          .date()
          .max(yup.ref("close"), "Open date must occur before close date")
          .required("Open date is required"),
        close: yup
          .date()
          .min(yup.ref("open"), "Close date must occur after open date")
          .required("Close date is required"),
      })}
      open={show}
      onClose={onCancelled}
    >
      <FormBody
        rate={rate}
        productType={productType}
        onSave={onSave}
        onCancel={onCancel}
      />
    </EditorPane>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({ productType }) => {
  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useEditorContext();

  return (
    <WidgetFrame>
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <TextField
          id="amount"
          type="number"
          min="0"
          label="Amount"
          placeholder="100.00"
          value={values.amount}
          slotProps={{
            input: {
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            },
          }}
          onBlur={handleBlur}
          onChange={handleChange}
          fullWidth
          required
          error={errors.amount && touched.amount}
          helperText={errors.amount || " "}
        />
        <Box sx={{ display: "flex", gap: 2 }}>
          <DatePicker
            name="start"
            label="Rate Starts"
            value={format.toDateTime(values.start, productType.timezone)}
            onChange={(value) => {
              setFieldValue("start", format.toISO(value.startOf("day")));
            }}
            error={touched.start && errors.start}
            slotProps={{
              textField: {
                helperText: (touched.start && errors.start) || " ",
              },
            }}
          />
          <DatePicker
            name="end"
            label="Rate Ends"
            value={format.toDateTime(values.end, productType.timezone)}
            onChange={(value) => {
              setFieldValue("end", format.toISO(value.endOf("day")));
            }}
            error={errors.end && touched.end}
            slotProps={{
              textField: {
                helperText: (touched.end && errors.end) || " ",
              },
            }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <DatePicker
            name="open"
            label="Rate Opens"
            value={format.toDateTime(values.open, productType.timezone)}
            onChange={(value) => {
              setFieldValue("open", format.toISO(value.startOf("day")));
            }}
            error={errors.open && touched.open}
            slotProps={{
              textField: {
                helperText: (touched.open && errors.open) || " ",
              },
            }}
          />
          <DatePicker
            name="close"
            label="Rate Closes"
            value={format.toDateTime(values.close, productType.timezone)}
            onChange={(value) => {
              setFieldValue("close", format.toISO(value.endOf("day")));
            }}
            error={errors.close && touched.close}
            slotProps={{
              textField: {
                helperText: (touched.close && errors.close) || " ",
              },
            }}
          />
        </Box>
      </Box>
    </WidgetFrame>
  );
};

export default RateEdit;
