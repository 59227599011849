/********************************************************************
 *
 * Users.jsx
 *
 * Renders a control to select a user. Currently the users
 * are sourced from the maintenance api, but it should change to
 * the auth interface.
 *
 * TODO: Convert to using Auth interface to source employees
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, MenuItem } from "@mui/material";
import { useApp, useAdminGatewayAPI } from "../../../../providers/AppProvider";

/**
 * Users
 *
 * @param {*} param0
 */
const Users = ({
  name,
  label = "Employee",
  value = "",
  exclusions,
  disabled,
  onChange,
  onBlur,
  error = false,
  helperText,
  sx,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [users, setUsers] = useState(null);
  const adminGatewayAPI = useAdminGatewayAPI();
  const app = useApp();

  useEffect(() => {
    fetchUsers(exclusions);
  }, [exclusions]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchUsers
   */
  const fetchUsers = (excluded) => {
    const remove = Array.isArray(excluded)
      ? excluded
      : !!excluded && !!excluded.id
      ? [excluded]
      : null;

    adminGatewayAPI
      .fetch(`/api/bridge/workorders/workorder/users`)
      .then(({ payload: users }) => {
        setUsers(
          users.filter(
            (user) =>
              !remove ||
              !remove.find((excludedUser) => user.id == excludedUser.id)
          )
        );
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  return (
    <TextField
      name={name}
      label={label}
      select
      value={value != null ? value : ""}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      sx={[{ width: "100%" }, sx]}
    >
      {!users ? (
        <MenuItem>Loading...</MenuItem>
      ) : (
        users.map((user) => (
          <MenuItem key={user.id} value={user.id}>
            {user.fname} {user.lname}
          </MenuItem>
        ))
      )}
    </TextField>
  );
};

/**
 * PropTypes
 */
Users.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  exclusions: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sx: PropTypes.object,
};

export default Users;
