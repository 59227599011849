/********************************************************************
 *
 * Employee.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Outlet, useParams } from "react-router";
import { FormControlLabel, Switch, Avatar } from "@mui/material";
import { Badge, Extension } from "@mui/icons-material";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * Employee
 *
 * Renders information about an employee
 *
 * @param {*} props
 */
const Employee = () => {
  const [employee, setEmployee] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  const { id } = useParams();

  useEffect(() => {
    fetchEmployee(id);
  }, [id]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  const fetchEmployee = (id) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Employee");

    api
      .fetch(`/api/employees/employee/${id}`)
      .then(({ payload: employee }) => {
        setEmployee(employee);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        setLoaded(true);
        app.endNotify(notifyHandle);
      });
  };

  /**
   * OnUpdate
   *
   * Fired when the user saves the changes
   *
   * @param {*} values
   */
  const onUpdate = (employee) => {
    //
    //  Save
    //
    const notifyHandle = app.notify("Saving Employee Information");

    api
      .update(
        `/api/employees/employee/${employee.id ? employee.id : ""}`,
        employee
      )
      .then(({ payload: employee }) => {
        setEmployee(employee);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: employee && <Avatar src={employee.imageUrl} />,
        title: employee
          ? `${employee.fname} ${employee.lname}`
          : "New Employee",
        subtitle: employee ? employee.role : "",
        bonus: (
          <FormControlLabel
            control={
              <Switch
                checked={!!employee && employee.status}
                onChange={(event) => {
                  onUpdate({
                    ...employee,
                    status: event.target.checked ? 1 : 0,
                  });
                }}
              />
            }
            label="Active"
          />
        ),
      }}
      menu={{
        icon: <Badge />,
        title: "Staff Management",
        subtitle: "TBD.",
        nav: <Menu employee={employee} />,
      }}
      loaded={loaded}
    >
      <Outlet
        context={{
          employee,
          onUpdate,
        }}
      />
    </Main>
  );
};

/**
 * Menu
 *
 * Renders the navigation controls for the functional areas of the
 * tour type management page.
 *
 * @param {*} props
 */
const Menu = ({ employee }) => {
  const { id = 0 } = employee || {};

  return (
    <SectionList>
      <SectionItemGroup icon={<Extension />} name="Manage" open={true}>
        <SectionItem to={`/app/admin/employee/${id}/details`}>
          Details
        </SectionItem>
        <SectionItem to={`/app/admin/employee/${id}/timesheet`}>
          Timesheet
        </SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

export default Employee;
