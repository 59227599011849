/********************************************************************
 *
 * WaterLevels.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  Avatar,
  Box,
  Typography,
} from "@mui/material";
import {
  TouchApp,
  Warning,
  ArrowUpward,
  ArrowDownward,
} from "@mui/icons-material";
import moment from "moment-timezone";
import { ResponsiveLine } from "@nivo/line";
import format from "../../utils/format";
import WidgetFrame from "../lib/WidgetFrame";
import { useApp, useAPI } from "../../providers/AppProvider";

const DANGER_LEVEL = 1.22; // Minimum water level for safe transit

/**
 * WaterLevels
 *
 * Renders a widget with the day's water levels using the Chart.js
 * library.
 *
 * TideStationData contains water level predictions on a 15 minute interval
 * for a given station.
 *
 * The transitLevels state object contains information about the predicted
 * levels and safety of the departure and return times.
 *
 */
const WaterLevels = ({
  date,
  tz,
  departureTime,
  returnTime,
  showDate = true,
  onTransitLevelsChanged,
}) => {
  const [tideStationData, setTideStationData] = useState(null);
  const [transitLevels, setTransitLevels] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (date) fetchWaterLevels("5cebf1de3d0f4a073c4bb94e", date, tz);
  }, [date, tz]);

  useEffect(() => {
    //
    //  Time change
    //
    if (tideStationData) {
      computeTransitLevels(tideStationData, departureTime, returnTime);
    }
  }, [tideStationData, departureTime, returnTime]);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchWaterLevels
   *
   * Calls the CCAPI to fetch the water levels for the day
   *
   * A successful call will update the state with the tide station
   * information
   *
   * TODO: The Squamish tide station is currently hard coded
   *
   * @param {*} stationId
   * @param {*} date
   * @param {*} tz
   */
  const fetchWaterLevels = (stationId, date, tz) => {
    //
    //  Validate parameters
    //
    if (!stationId || !date || !tz) return;

    //
    //  Request data for the timezone-adjusted start of day
    //
    let _date = format.getMomentLocalized(date, tz).startOf("day");

    //
    //  Make the API call after the tideStationData state is cleared
    //  to smooth out the data transition
    //
    //  BUGBUG: More data is needed if the tour length is more than one day
    //
    //      setTideStationData(null);
    api
      .fetch(
        `/api/tides/levels/${stationId}/${format.toApiDateTime(
          _date
        )}/${format.toApiDateTime(moment(_date).add(1, "day"))}`
      )
      .then(({ payload: tideStationData }) => {
        setTideStationData(tideStationData);
      })
      .catch((error) => {
        app.error({ error });
        setTideStationData(null);
      });
  };

  /**
   * ComputeTransitLevels
   *
   * @param {*} tideStationData
   * @param {*} departureTime
   * @param {*} returnTime
   */
  const computeTransitLevels = (tideStationData, departureTime, returnTime) => {
    let transitLevels = {};

    if (!tideStationData || !departureTime || !returnTime) return;

    //
    //  Compute departure levels
    //
    let departureIndex = findWaterLevelIndex(
      departureTime,
      tideStationData.predictions
    );

    transitLevels.departure = tideStationData.predictions[departureIndex];
    transitLevels.departureNext = tideStationData.predictions[
      departureIndex + 1
    ]
      ? tideStationData.predictions[departureIndex + 1]
      : null;

    //
    //  Compute return levels
    //
    let returnIndex = findWaterLevelIndex(
      returnTime,
      tideStationData.predictions
    );

    transitLevels.return = tideStationData.predictions[returnIndex];
    transitLevels.returnNext = tideStationData.predictions[returnIndex + 1]
      ? tideStationData.predictions[returnIndex + 1].height
      : null;

    //
    //  Determine if water levels are safe for transit
    //
    transitLevels.safeTransit =
      transitLevels.departure.height > DANGER_LEVEL &&
      transitLevels.return.height > DANGER_LEVEL;

    //
    //  Call event handler
    //
    onTransitLevelsChanged && onTransitLevelsChanged(transitLevels);

    setTransitLevels(transitLevels);
  };

  /**
   * FindWaterLevelIndex
   *
   * Performs a binary search to find the water level at a specified
   * date and time.
   *
   * Returns the index of the closest prediction.
   *
   * @param {moment} dateTime
   * @param {Array} predictions
   *
   * @returns {int}
   */
  const findWaterLevelIndex = (dateTime, predictions) => {
    let lo = 0,
      hi = predictions.length - 1;
    let curr;
    let mDateTime = moment(dateTime).utc();

    while (lo <= hi) {
      curr = Math.round((lo + hi) / 2);

      if (moment(predictions[curr].moment).isSame(mDateTime, "minutes")) {
        return curr;
      } else if (moment(predictions[curr].moment).isBefore(mDateTime)) {
        lo = curr + 1;
      } else {
        hi = curr - 1;
      }
    }

    return curr;
  };

  /**
   * TransformGraphData
   *
   * @param {*} tideStationData
   */
  const transformGraphData = (tideStationData) => {
    if (!tideStationData) return null;

    let newData = { id: "Squamish" };

    newData.data = tideStationData.predictions.map(
      ({ moment: time, height }) => ({
        x: format.toDateTime(time).toFormat("yyyy-MM-dd'T'HH:mm':00'"), // moment(time).format("YYYY-MM-DDTHH:mmZ"),
        y: height >= DANGER_LEVEL ? height : null,
      })
    );

    return [newData];
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <WidgetFrame
      title={
        <Box display="flex" justifyContent="space-between" flexWrap="no-wrap">
          <Box>
            <Typography variant="h4">
              Water Levels
              {date && showDate ? `: ${format.dayDate(date, tz)}` : ""}
            </Typography>
          </Box>
          <Box>
            {departureTime && returnTime ? (
              <TransitLevels
                transitLevels={transitLevels}
                departureTime={departureTime}
                returnTime={returnTime}
                tz={tz}
              />
            ) : null}
          </Box>
        </Box>
      }
    >
      <Box style={{ height: "400px" }}>
        <WaterLevelGraph data={transformGraphData(tideStationData)} />
      </Box>
    </WidgetFrame>
  );
};

/**
 * WaterLevelGraph
 *
 * @param {*} param0
 */
const WaterLevelGraph = ({ data /* see data tab */ }) => {
  return (
    !!data && (
      <ResponsiveLine
        margin={{ top: 20, right: 20, bottom: 60, left: 80 }}
        data={data}
        xScale={{
          type: "time",
          format: "%Y-%m-%dT%H:%M:%S",
          precision: "minute",
          useUTC: false,
        }}
        xFormat="time:%Y-%m-%dT%H:%M:%S"
        yScale={{
          type: "linear",
          min: 1.22,
          max: "auto",
          stacked: false,
          reverse: false,
        }}
        curve="basis"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          format: "%H:%M",
          tickValues: "every hour",
          tickRotation: 45,
          legend: "Time",
          legendOffset: 50,
          legendPosition: "middle",
        }}
        axisLeft={{
          orient: "left",
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: "Depth",
          legendOffset: -40,
          legendPosition: "middle",
        }}
        colors={{ scheme: "category10" }}
        pointSize={5}
        pointColor={{ theme: "background" }}
        pointBorderWidth={1}
        pointBorderColor={{ from: "serieColor" }}
        useMesh={true}
        enableSlices={false}
        enableGridX={false}
        enableGridY={true}
        enableArea={true}
        tooltip={(data) => (
          <Card>
            <CardHeader
              avatar={
                <Avatar style={{ backgroundColor: data.point.serieColor }}>
                  <TouchApp />
                </Avatar>
              }
              title={data.point.serieId}
              subheader={data.point.data.x.toLocaleString("en-CA")}
            />
            <CardContent>
              <Typography variant="h4">
                {data.point.data.yFormatted} meters
              </Typography>
            </CardContent>
          </Card>
        )}
        useStyles={true}
        legends={[]}
      />
    )
  );
};

/**
 * TransitLevels
 *
 * @param {*} param0
 */
const TransitLevels = ({ transitLevels, departureTime, returnTime, tz }) => {
  if (!transitLevels) return null;

  return (
    <div style={{ position: "relative" }} className="media">
      {!transitLevels.safeTransit ? <Warning /> : ""}
      <div className="media-body small ml-5 mb-0">
        <div className="text-nowrap">
          {transitLevels.departure.height >
          transitLevels.departureNext.height ? (
            <ArrowDownward />
          ) : (
            <ArrowUpward />
          )}
          {` ${Number(transitLevels.departure.height).toFixed(
            2
          )}m Departure (${format.shortDateTime(departureTime, tz)})`}
        </div>
        <div className="text-nowrap">
          {!!transitLevels.return &&
          transitLevels.returnNext &&
          transitLevels.return.height > transitLevels.returnNext.height ? (
            <ArrowDownward />
          ) : (
            <ArrowUpward />
          )}

          {` ${Number(transitLevels.return.height).toFixed(
            2
          )}m Return (${format.shortDateTime(returnTime, tz)})`}
        </div>
      </div>
    </div>
  );
};

/*
 **  PropTypes
 */

WaterLevels.propTypes = {
  date: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  showDate: PropTypes.bool,
  tz: PropTypes.string,
  departureTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  returnTime: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  onTransitLevelsChanged: PropTypes.func,
};

export default WaterLevels;
