/********************************************************************
 *
 * RateClassEdit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Box,
  FormControl,
  Select,
  InputLabel,
  OutlinedInput,
  Chip,
  TextField,
  MenuItem,
} from "@mui/material";
import * as yup from "yup";
import format from "../../../../utils/format";
import { EditorPane, useEditorContext } from "../../../lib";
import { FormSection, FormComponent } from "../../../lib/EditorControls";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * RateClassEdit
 *
 * Adds or edits a rate class.
 *
 * @param {*} param0
 */
const RateClassEdit = ({ rateClass, open, onClose }) => {
  const [policies, setPolicies] = useState(false);
  const [terms, setTerms] = useState(false);
  const [taxes, setTaxes] = useState(false);
  const app = useApp();
  const api = useAPI();

  /**
   * Initialize
   *
   */
  useEffect(() => {
    initPolicies();
    initTerms();
    initTaxes();
  }, []);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * InitPolicies
   *
   * Initializes the list of policies
   *
   */
  const initPolicies = () => {
    api
      .fetch(`/api/policies/`)
      .then(({ payload: policies }) => {
        setPolicies(policies);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * InitTerms
   *
   * Initializes the list of terms
   */
  const initTerms = () => {
    api
      .fetch(`/api/terms/`)
      .then(({ payload: terms }) => {
        setTerms(terms);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * InitTaxes
   *
   * Initializes the list of taxes
   */
  const initTaxes = () => {
    api
      .fetch(`/api/taxes/`)
      .then(({ payload: taxes }) => {
        setTaxes(taxes);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * Fired when the user commits the change.
   *
   * @param {*} event
   */
  const onSave = (rateClass) => {
    api
      .update(
        `/api/rateclasses/rateclass/${rateClass.id ? rateClass.id : ""}`,
        rateClass
      )
      .then((envelope) => {
        onClose();
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <EditorPane
      title="New Rate Class"
      initialValues={
        rateClass || {
          name: "",
          description: undefined,
          policies: [{}, {}],
          taxes: [],
        }
      }
      onSubmit={(values) => {
        alert("here");
        // if (rateClass) values.id = rateClass.id;
        // onSave(values);
      }}
      validationSchema={yup.object().shape({
        name: yup.string().required("Rate class name is required"),
        description: yup
          .string()
          .nullable()
          .required("Description is required"),
      })}
      open={open}
      onClose={onClose}
    >
      <FormBody policies={policies} terms={terms} taxes={taxes} />
    </EditorPane>
  );
};

/**
 * FormBody
 *
 * @param {*} param0
 * @returns
 */
const FormBody = ({ policies, terms, taxes }) => {
  const {
    values,
    touched,
    errors,
    dirty,
    setFieldValue,
    setValues,
    handleChange,
    handleBlur,
  } = useEditorContext();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
      <FormSection>
        <FormComponent>
          <TextField
            name="name"
            label="Name"
            value={values.name}
            onChange={handleChange}
            error={!!touched.name && !!errors.name}
            helperText={(!!touched.name && errors.name) || " "}
            sx={{ width: "100%" }}
          />
        </FormComponent>
        <FormComponent>
          <TextField
            name="description"
            label="Description"
            multiline
            rows="4"
            value={values.description ? values.description : ""}
            onChange={handleChange}
            error={!!touched.description && !!errors.description}
            helperText={(!!touched.description && errors.description) || " "}
            sx={{ width: "100%" }}
          />
        </FormComponent>
        <FormComponent start={1} span={6}>
          <TextField
            name="term.id"
            label="Payment Terms"
            value={!!values.term ? values.term.id : ""}
            select
            onChange={(event) => {
              //
              //  If the rate is not commissionable (i.e. wholesale), then
              //  clear the commission values
              //
              setValues({
                ...values,
                commission:
                  event.target.value == 0 ? values.commission : undefined,
                term: { id: event.target.value },
              });
            }}
            error={!!touched.term && !!touched.term.id && !!errors.term.id}
            helperText={
              (!!touched.term && !!touched.term.id && errors.term.id) || " "
            }
            sx={{ width: "100%" }}
          >
            {!terms ? (
              <MenuItem>Loading...</MenuItem>
            ) : (
              terms.map((term) => (
                <MenuItem key={term.id} value={term.id}>
                  {term.name}
                </MenuItem>
              ))
            )}
          </TextField>
        </FormComponent>
        <FormComponent start={7} span={6}>
          <FormControl sx={{ width: "100%" }}>
            <InputLabel id="taxes-label">Taxes</InputLabel>
            <Select
              labelId="taxes-label"
              name="taxes"
              multiple
              value={values.taxes}
              onChange={handleChange}
              input={<OutlinedInput id="select-multiple-chip" label="Chip" />}
              renderValue={(selected) => {
                return (
                  <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                    {!!selected &&
                      selected.map((value) => (
                        <Chip
                          key={value}
                          variant="outlined"
                          size="small"
                          label={taxes.find((tax) => tax.id == value).name}
                          sx={{ backgroundColor: "#dfdfdf" }}
                        />
                      ))}
                  </Box>
                );
              }}
              MenuProps={{
                PaperProps: {
                  style: {
                    // maxHeight: 48 * 4.5 + 8,
                    width: 250,
                  },
                },
              }}
              error={!!touched.tax && !!touched.tax.id && !!errors.tax.id}
              helperText={
                (!!touched.tax && !!touched.tax.id && errors.tax.id) || " "
              }
              sx={{ width: "100%" }}
            >
              {!taxes ? (
                <MenuItem>Loading...</MenuItem>
              ) : (
                taxes.map((tax) => (
                  <MenuItem key={tax.id} value={tax.id}>
                    {format.tax(tax)}
                  </MenuItem>
                ))
              )}
            </Select>
          </FormControl>
        </FormComponent>
      </FormSection>
      <FormSection
        title={
          values.term && values.term.id == 0
            ? "Commisions"
            : "Non-Commisionable Payment Term"
        }
      >
        <Box sx={{ gridColumn: { xs: "1/span 2" } }}>
          <TextField
            name="commission.rate"
            label="Commission Rate"
            value={!!values.commission && values.commission.rate}
            disabled={!values.term || values.term.id != 0}
            type="number"
            onChange={(event) => {
              if (event.target.value < 0) event.target.value = 0;
              handleChange(event);
            }}
            error={
              !!touched.commission &&
              !!touched.commission.rate &&
              !!errors.commission.rate
            }
            helperText={
              (!!touched.commission &&
                !!touched.commission.rate &&
                errors.commission.rate) ||
              " "
            }
            sx={{ width: "100%" }}
          />
        </Box>
        <Box sx={{ gridColumn: { xs: "3/span 5" } }}>
          <TextField
            name="commission.id"
            label="Commission Type"
            value={!!values.commission ? values.commission.id : ""}
            disabled={!values.term || values.term.id != 0}
            select
            onChange={handleChange}
            error={
              !!touched.commission &&
              !!touched.commission.id &&
              !!errors.commission.id
            }
            helperText={
              (!!touched.commission &&
                !!touched.commission.id &&
                errors.commission.id) ||
              " "
            }
            sx={{ width: "100%" }}
          >
            <MenuItem value="0">Percentage</MenuItem>
            <MenuItem value="1">Fixed</MenuItem>
          </TextField>
        </Box>
      </FormSection>
      <FormSection title="Policies">
        <Box sx={{ gridColumn: { xs: "1/span 6" } }}>
          <TextField
            name="policies[0].id"
            label="Booking Policy"
            value={values.policies ? values.policies[0].id : ""}
            select
            onChange={handleChange}
            error={
              !!touched.policies &&
              !!touched.policies[0].id &&
              !!errors.policies[0].id
            }
            helperText={
              (!!touched.policies &&
                !!touched.policies[0].id &&
                errors.policies[0].id) ||
              " "
            }
            sx={{ width: "100%" }}
          >
            {!policies ? (
              <MenuItem>Loading...</MenuItem>
            ) : (
              policies.map((policy) => (
                <MenuItem key={policy.id} value={policy.id}>
                  {policy.name}
                </MenuItem>
              ))
            )}
          </TextField>
        </Box>
        <Box sx={{ gridColumn: { xs: "7/span 6" } }}>
          <TextField
            name="policies[1].id"
            label="Cancellation Policy"
            select
            onChange={handleChange}
            value={values.policies ? values.policies[1].id : ""}
            error={
              !!!!touched.policies &&
              !!touched.policies[1].id &&
              !!errors.policies[1].id
            }
            helperText={
              (!!touched.policies &&
                !!touched.policies[1].id &&
                errors.policies[1].id) ||
              " "
            }
            sx={{ width: "100%" }}
          >
            {!policies ? (
              <MenuItem>Loading...</MenuItem>
            ) : (
              policies.map((policy) => (
                <MenuItem key={policy.id} value={policy.id}>
                  {policy.name}
                </MenuItem>
              ))
            )}
          </TextField>
        </Box>
      </FormSection>
    </Box>
  );
};

export default RateClassEdit;
