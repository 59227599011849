/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Typography, TextField, Card, CardContent } from "@mui/material";
import format from "../../../../../utils/format";
import { WidgetFrame } from "../../../../lib";
import {
  useApp,
  useAdminGatewayAPI,
} from "../../../../../providers/AppProvider";

/**
 * Communication
 *
 * Information to help manage orders
 */
const Communication = ({ order, onUpdated }) => {
  const [logItems, setLogItems] = useState(null);
  const [loaded, setLoaded] = useState(null);
  const app = useApp();
  const adminGatewayAPI = useAdminGatewayAPI();

  useEffect(() => {
    fetchLogItems(order.id);
  }, [order]);

  /**
   * fetchLogItems
   *
   * Calls the API to fetch the specified order
   *
   * @param {*} orderId
   */
  const fetchLogItems = (orderId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Order");

    adminGatewayAPI
      .fetch(`/api/core/logs/order/${orderId}`)
      .then(({ payload: logItems }) => {
        setLogItems(logItems);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  return (
    <WidgetFrame title="Communication">
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 4,
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            gap: 4,
            justifyContent: "space-between",
          }}
        >
          <TextField
            id="email"
            label="Email"
            placeholder="Email"
            value="bob@abc.com"
            // onBlur={onBlur}
            // onChange={onChange}
            fullWidth
            // error={errors.email && touched.email}
            // helperText={errors.reference || " "}
          />
          <TextField
            id="phone"
            label="Phone"
            placeholder="Phone"
            value="555-1212"
            // onBlur={onBlur}
            // onChange={onChange}
            fullWidth
            // error={errors.email && touched.email}
            // helperText={errors.reference || " "}
          />
        </Box>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {!!logItems &&
            logItems.map((logItem) => (
              <Card key={logItem.id} sx={{}}>
                <CardContent sx={{ height: "100%" }}>
                  <Typography variant="h5" component="div">
                    {logItem.type.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    {logItem.description}
                  </Typography>
                  <Typography variant="caption" color="text.secondary">
                    {`${format.dateTimeShort(logItem.timestamp)} by
                    ${logItem.user.fname} ${logItem.user.lname}`}
                  </Typography>
                </CardContent>
              </Card>
            ))}
        </Box>
      </Box>
    </WidgetFrame>
  );
};

/*
 **  PropTypes
 */
Communication.propTypes = {
  order: PropTypes.object.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default Communication;
