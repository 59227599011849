/********************************************************************
 *
 * WorkOrderItems.jsx
 *
 * Renders a control that manages work order items. Uses the reducer
 * pattern, so the host needs to pass a dispatch function and actions
 * objects, and handle the CREATE_ITEM, UPDATE_ITEM and DELETE_ITEM
 * actions.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Formik, useFormikContext } from "formik";
import * as yup from "yup";
import {
  Menu as Hamburger,
  Publish,
  PostAdd,
  RemoveCircleOutline,
  PauseCircleOutline,
  PlayCircleOutline,
  TaskAlt,
} from "@mui/icons-material";
import {
  Table,
  TableHead,
  TableFooter,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
  Menu,
  MenuItem,
  Typography,
  TextField,
  Chip,
  Divider,
} from "@mui/material";
import { WidgetFrame } from "../../../../lib";
import ItemStatus from "./Status";
import ItemCategories from "../../lib/ItemCategories";
import {
  useApp,
  useAdminGatewayAPI,
} from "../../../../../providers/AppProvider";
import { useUser } from "../../../../../providers";

const STATUS = { POSTPONED: 0, PAUSED: 1, PROGRESSING: 2, COMPLETED: 3 };

const pickChip = (id, disabled) =>
  [
    <Chip
      icon={<RemoveCircleOutline />}
      disabled={disabled}
      variant="filled"
      color="text"
      label="Posponed"
      sx={{
        backgroundColor: "#aaaaaa",
        color: "#ffffff",
        "& .MuiChip-icon": { color: "#ffffff" },
      }}
    />,
    <Chip
      icon={<PauseCircleOutline />}
      disabled={disabled}
      variant="outlined"
      color="text"
      label="Paused"
    />,
    <Chip
      icon={<PlayCircleOutline />}
      disabled={disabled}
      variant="outlined"
      color="text"
      label="Progressing"
    />,
    <Chip
      icon={<TaskAlt />}
      disabled={disabled}
      label="Completed"
      sx={{ color: "#ffffff", "& .MuiChip-icon": { color: "#ffffff" } }}
    />,
  ][id];

/**
 * WorkOrderItems
 *
 * @param {*} param0
 *
 * @returns
 */
const WorkOrderItems = ({
  workOrder,
  reducer: { state, dispatch, actions },
}) => {
  const adminGatewayAPI = useAdminGatewayAPI();
  const app = useApp();
  const user = useUser();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * AddItem
   *
   * @param {*} newItem
   * @param {*} param1
   */
  const addItem = (newItem, { resetForm }) => {
    adminGatewayAPI
      .create(
        `/api/bridge/workorders/workorder/${workOrder.id}/items/item`,
        newItem
      )
      .then(({ payload: workOrderItem }) => {
        dispatch({ type: actions.CREATE_ITEM, payload: workOrderItem });
        resetForm();
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  return (
    <WidgetFrame title="Work Items">
      <Formik
        initialValues={{
          category: { id: null },
          description: "",
          user: user,
        }}
        onSubmit={addItem}
        validationSchema={yup.object().shape({
          category: yup.object().shape({
            id: yup.number().required("Please choose a category."),
          }),
          description: yup.string().required("Please provide a description."),
          user: yup
            .object()
            .shape({ id: yup.number().required("Initialization error.") }),
        })}
      >
        <FormBody
          workOrder={workOrder}
          reducer={{ state, dispatch, actions }}
        />
      </Formik>
    </WidgetFrame>
  );
};

/**
 * FormBody
 *
 * @param {*} param0
 * @returns
 */
const FormBody = ({ workOrder, reducer: { state, dispatch, actions } }) => {
  const [context, setContext] = useState(null);
  const { active, completed } = state;

  const {
    values,
    touched,
    errors,
    dirty,
    isSubmitting,
    setFieldValue,
    handleChange,
    handleBlur,
    handleSubmit,
  } = useFormikContext();

  return (
    <form>
      <Table
        sx={{
          "& .status": { width: "175px" },
          "& .category": { width: "200px" },
          "& .context": { width: "150px" },
          "& .disabled": { color: "#999999" },
          "& .completed": { color: "#cccccc" },
        }}
      >
        <TableHead>
          <TableRow>
            <TableCell className="status">Status</TableCell>
            <TableCell>Description</TableCell>
            <TableCell className="category">Category</TableCell>
            <TableCell className="context"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!!workOrder &&
            !!workOrder.items &&
            workOrder.items.map((item) => (
              <TableRow key={item.id}>
                <TableCell className="status">
                  {pickChip(item.status.id, !active)}
                  {/* <ItemStatus
                    label=""
                    value={item.status.id}
                    onChange={(event) => {
                      onUpdate(item, { status: { id: event.target.value } });
                    }}
                  /> */}
                </TableCell>
                <TableCell className={`${!active && "disabled"}`}>
                  {item.description}
                </TableCell>
                <TableCell className={`category ${!active && "disabled"}`}>
                  {item.category.name}
                </TableCell>
                <TableCell className="context">
                  <IconButton
                    disabled={completed}
                    onClick={(event) => {
                      setContext({
                        workOrder,
                        item,
                        active,
                        anchorEl: event.currentTarget,
                      });
                    }}
                  >
                    <Hamburger />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TableCell
              className="status"
              sx={{ verticalAlign: "top", pt: "30px" }}
            >
              <Typography variant="h4" className={completed ? "completed" : ""}>
                Add an Item:
              </Typography>
            </TableCell>
            <TableCell sx={{ verticalAlign: "top" }}>
              <TextField
                disabled={completed}
                value={values.description}
                onBlur={handleBlur}
                onChange={(event) => {
                  setFieldValue("description", event.target.value);
                }}
                error={!!touched.description && !!errors.description}
                helperText={
                  (!!touched.description && errors.description) || " "
                }
                sx={{ width: "100%" }}
              />
            </TableCell>
            <TableCell className="category" sx={{ verticalAlign: "top" }}>
              <ItemCategories
                name="category.id"
                disabled={completed}
                value={(!!values.category && values.category.id) || ""}
                onChange={(event) => {
                  setFieldValue("category.id", event.target.value);
                }}
                error={
                  !!touched.category &&
                  touched.category.id &&
                  !!errors &&
                  !!errors.category &&
                  !!errors.category.id
                }
                helperText={
                  (!!touched.category &&
                    touched.category.id &&
                    !!errors.category &&
                    errors.category.id) ||
                  " "
                }
              />
            </TableCell>
            <TableCell
              className="context"
              sx={{ verticalAlign: "top", pt: "25px" }}
            >
              <IconButton
                variant="contained"
                disabled={completed}
                onClick={handleSubmit}
                sx={{ color: "#aaaaaa" }}
              >
                <PostAdd />
              </IconButton>
            </TableCell>
          </TableRow>
        </TableFooter>
      </Table>
      <ContextMenu
        context={context}
        reducer={{ dispatch, actions }}
        onClose={() => {
          setContext(null);
        }}
      />
    </form>
  );
};

/**
 * Context Menu
 *
 * Menu for managing individual work items
 *
 * @param {*} param0
 * @returns
 */
const ContextMenu = ({ context, reducer: { dispatch, actions }, onClose }) => {
  const adminGatewayAPI = useAdminGatewayAPI();
  const app = useApp();
  const { workOrder, item, active, anchorEl } = context || {
    workOrder: null,
    item: null,
    active: false,
    anchorEl: null,
  };

  /**
   * UpdateItem
   *
   * @param {*} item
   * @param {*} mask
   */
  const updateItem = (workOrder, item, mask) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Updating Item");

    adminGatewayAPI
      .update(
        `/api/bridge/workorders/workorder/${workOrder.id}/items/item/${item.id}`,
        mask
      )
      .then(({ payload: workOrderItem }) => {
        dispatch({ type: actions.UPDATE_ITEM, payload: workOrderItem });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  /**
   * DeleteItem
   *
   * @param {*} item
   */
  const deleteItem = (workOrder, item) => {
    if (!window.confirm("Do you want to delete this work item?")) return;

    //
    //  Status message
    //
    const notifyHandle = app.notify("Updating Item");

    adminGatewayAPI
      .delete(
        `/api/bridge/workorders/workorder/${workOrder.id}/items/item/${item.id}`
      )
      .then(() => {
        dispatch({ type: actions.DELETE_ITEM, payload: item });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  if (!item) return;
  return (
    <Menu
      anchorEl={anchorEl}
      open={!!anchorEl}
      onClose={() => {
        onClose();
      }}
      //   MenuListProps={{
      //     "aria-labelledby": "basic-button",
      //   }}
    >
      <Typography
        variant="body1"
        sx={{
          fontWeight: 600,
          pt: 1,
          pb: 0.75,
          pl: 1,
          pr: 2,
          display: active ? "inherit" : "none",
        }}
      >
        Item Status
      </Typography>
      <MenuItem
        onClick={() => {
          onClose();
          updateItem(workOrder, item, { status: { id: STATUS.POSTPONED } });
        }}
        sx={{
          display:
            active &&
            (item.status.id == STATUS.PAUSED ||
              item.status.id == STATUS.PROGRESSING)
              ? "inherit"
              : "none",
        }}
      >
        Postponed
      </MenuItem>
      <MenuItem
        onClick={() => {
          onClose();
          updateItem(workOrder, item, { status: { id: STATUS.PAUSED } });
        }}
        sx={{
          display:
            active && item.status.id == STATUS.PROGRESSING ? "inherit" : "none",
        }}
      >
        Paused
      </MenuItem>
      <MenuItem
        onClick={() => {
          onClose();
          updateItem(workOrder, item, { status: { id: STATUS.PROGRESSING } });
        }}
        sx={{
          display:
            active &&
            (item.status.id == STATUS.POSTPONED ||
              item.status.id == STATUS.PAUSED ||
              item.status.id == STATUS.COMPLETED)
              ? "inherit"
              : "none",
        }}
      >
        Progressing
      </MenuItem>
      <MenuItem
        onClick={() => {
          onClose();
          updateItem(workOrder, item, {
            status: { id: STATUS.COMPLETED },
          });
        }}
        sx={{
          display:
            active &&
            (item.status.id == STATUS.POSTPONED ||
              item.status.id == STATUS.PAUSED ||
              item.status.id == STATUS.PROGRESSING)
              ? "inherit"
              : "none",
        }}
      >
        Completed
      </MenuItem>

      <Divider sx={{ display: active ? "inherit" : "none" }} />
      <MenuItem
        onClick={() => {
          deleteItem(workOrder, item);
          onClose();
        }}
      >
        Delete
      </MenuItem>
    </Menu>
  );
};

/**
 * PropTypes
 */
WorkOrderItems.propTypes = {
  workOrder: PropTypes.object.isRequired,
  reducer: PropTypes.object.isRequired,
};

export default WorkOrderItems;
