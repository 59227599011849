/********************************************************************
 *
 * Tickets.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Grid2 as Grid, Typography } from "@mui/material";
import { Alert } from "@mui/material";
import { ConfirmationNumber, ChildCare } from "@mui/icons-material";
import { LinkCard2 as LinkCard, CardList, CardAction } from "./index";
import format from "../../utils/format";

/**
 * Tickets
 *
 * Renders a card deck of Tickets.
 */
const Tickets = ({ tickets, tz }) => {
  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!tickets) return null;

  return (
    <CardList
      xs={12}
      sm={12}
      md={12}
      lg={6}
      xl={6}
      items={tickets}
      spacing={2}
      headerBG="#01899f"
      onRenderCard={({ item: ticket }) => (
        <LinkCard
          link={`/app/goods/ticket/${ticket.id}`}
          header={<TicketHeader ticket={ticket} />}
          content={<TicketSummary ticket={ticket} tz={tz} />}
          actions={
            <>
              <CardAction to="#" label="Reschedule" />
              <CardAction to="#" label="Delete" />
            </>
          }
        />
      )}
    />
  );
};

const TicketHeader = ({ ticket }) => {
  const { customer, good } = ticket;

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        px: 2,
        py: 1,
        backgroundColor: "primary.main",
        color: "white",
      }}
    >
      <Box>
        <ConfirmationNumber sx={{ fontSize: "1.5em", marginRight: "1em" }} />
        {customer ? (
          customer.isMinor ? (
            <>
              <Typography variant="subtitle1" component="span">
                {customer.minorName}
              </Typography>
              <ChildCare style={{ fontSize: "1.5em", marginLeft: "0.75em" }} />
            </>
          ) : (
            <Typography variant="body2" component="span">
              {`${customer.fname} ${customer.lname}`}
            </Typography>
          )
        ) : (
          ""
        )}
      </Box>
      <Box sx={{ textAlign: "right" }}>
        <Typography variant="body2">{good.name}</Typography>
      </Box>
    </Box>
  );
};

const TicketSummary = ({ ticket, tz }) => {
  const { good, groupName, customer, timestamp } = ticket;

  return (
    <Box sx={{ py: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: { xs: "row", md: "row" },
          justifyContent: "space-between",
          gap: 2,
        }}
      >
        <Box sx={{ flex: { xs: "1 1 40%", md: "1 1 20%" } }}>
          <Typography variant="subtitle1">Order</Typography>
          <Typography variant="body2">{groupName}</Typography>
        </Box>
        <Box sx={{ flex: { xs: "1 1 40%", md: "1 1 25%" } }}>
          <Typography variant="subtitle1">Issued</Typography>
          <Typography variant="body2">
            {format.dateTime(timestamp, tz)}
          </Typography>
        </Box>
        <Box sx={{ flex: { xs: "1 1 40%", md: "1 1 20%" } }}>
          <Typography variant="subtitle1">Supplier</Typography>
          <Typography variant="body2">{good.supplier.name}</Typography>
        </Box>
        <Box sx={{ flex: { xs: "1 1 40%", md: "1 1 20%" } }}>
          <Typography variant="subtitle1">Rate Class</Typography>
          <Typography variant="body2">{ticket.rateClass.name}</Typography>
        </Box>
        {!!customer && !!customer.isMinor && (
          <>
            <Grid item xs={4}>
              Age: {customer.age}
            </Grid>
            <Grid item xs={4}>
              Weight: {customer.weight}
            </Grid>
            <Grid item xs={4}>
              Height: {customer.height}
            </Grid>
          </>
        )}
        {!!customer && (
          <>
            <Grid
              item
              xs={12}
              sx={{
                borderTop: "solid 1px #eeeeee",
                display: !customer.medical ? "none" : "inherit",
              }}
            >
              <Typography>Medical</Typography>
              <Alert severity="info">{customer.medical}</Alert>
            </Grid>
            <Grid
              item
              xs={12}
              sx={{
                borderTop: "solid 1px #eeeeee",
                display:
                  !customer.allergies || customer.allergies.length === 0
                    ? "none"
                    : "inherit",
              }}
            >
              <Typography className="small">Allergies</Typography>
              <Alert severity="info">{customer.allergies}</Alert>
            </Grid>
          </>
        )}
      </Box>
    </Box>
  );
};

/*
 **  Tickets
 */
Tickets.propTypes = {
  tickets: PropTypes.array,
  tz: PropTypes.string,
};

export default Tickets;
