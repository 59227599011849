/********************************************************************
 *
 * WeeklyCalendarContent.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Typography } from "@mui/material";

import makeStyles from "@mui/styles/makeStyles";

/**
 * WeeklyCalendarContent
 */
const WeeklyCalendarContent = () => {
  const classes = styles();

  return (
    <Box className={classes.contentContainer}>
      <Box className={classes.content}>
        <Box className={classes.contentPrefix}>
          <Box className={classes.timeContainer}>
            {[...Array(24).keys()].map((time, index) => (
              <Box key={index} className={classes.timeCell}>
                <Typography className={classes.time}>{time * 100}</Typography>
              </Box>
            ))}
          </Box>
        </Box>
        <Box className={classes.contentCalendarContainer}>
          <Box className={classes.contentCalendar}>
            <Box className={classes.dayContainer}>
              <Box className={classes.day}>&nbsp;</Box>
            </Box>
            <Box className={classes.dayContainer}>
              <Box className={classes.day}>&nbsp;</Box>
            </Box>
            <Box className={classes.dayContainer}>
              <Box className={classes.day}>
                <Box className={classes.event}>Event</Box>
              </Box>
            </Box>
            <Box className={classes.dayContainer}>
              <Box className={classes.day}></Box>
            </Box>
            <Box className={classes.dayContainer}>
              <Box className={classes.day}></Box>
            </Box>
            <Box className={classes.dayContainer}>
              <Box className={classes.day}></Box>
            </Box>
            <Box className={classes.dayContainer}>
              <Box className={classes.day}></Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

/*
 **  Styles
 */
const styles = makeStyles((theme) => ({
  contentContainer: {
    display: "flex",
    flexDirection: "column",
    flex: "1 1 60%",
    position: "relative",
    overflow: "hidden",
  },
  content: {
    overflow: "hidden",
    display: "flex",
    flex: "1 1 auto",
    alignItems: "stretch",
  },
  contentPrefix: {
    overflowY: "hidden",
    height: "auto",
    flex: "none",
    display: "flex",
    alignItems: "flex-start",
    minWidth: "40px",
  },
  timeContainer: {
    position: "relative",
    backgroundColor: "#fff",
    boxSizing: "borderBox",
    marginLeft: "auto",
    display: "block",
  },
  timeCell: {
    position: "relative",
    height: "48px",
    paddingRight: "8px",
    textAlign: "right",
  },
  time: {
    display: "block",
    position: "relative",
    top: "-6px",
    color: "#70757a",
    fontSize: "10px",
  },
  contentCalendarContainer: {
    overflowX: "auto",
    overflowY: "scroll",
    display: "flex",
    alignItems: "flex-start",
    flex: "1 1 auto",
  },
  dayContainer: {
    width: "81px",
    minWidth: "81px",
    outline: "none",
    boxSizing: "border-box",
    paddingRight: "12px",
    position: "relative",
    borderRight: "#dadce0 1px solid",
    flex: "1 0 auto",
  },
  day: { position: "relative", height: "100%", width: "100%" },
  event: {
    position: "absolute",
    borderRadius: "4px",
    marginLeft: "-1px",
    marginTop: "1px",
    outline: "none",
    top: "527px",
    height: "46px",
    left: "0%",
    width: "100%",
    zIndex: 5,
    backgroundColor: "rgb(215, 219, 239)",
    borderColor: "rgb(188, 195, 229)",
  },
}));

export default WeeklyCalendarContent;
