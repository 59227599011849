/********************************************************************
 *
 * Index.jsx
 *
 * Renders a control that manages the list of work orders.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useNavigate } from "react-router";
import { Box, Tab, Tabs, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from "@mui/x-data-grid";
import format from "../../../../../utils/format";
import { WidgetFrame } from "../../../../lib";
import { SEVERITY_ICONS } from "../../lib/iconLib";
import {
  useApp,
  useAdminGatewayAPI,
} from "../../../../../providers/AppProvider";

const PAGE_SIZE = 5;

const columns = [
  { field: "id", headerName: "ID", width: 60 },
  {
    field: "asset",
    headerName: "Asset",
    valueGetter: (value, row) => row.asset.name,
    width: 90,
  },
  {
    headerName: "Name",
    field: "name",
    width: 200,
    renderCell: (value) => (
      <Tooltip title={value.row.description} placement="top">
        <>{value.row.name}</>
      </Tooltip>
    ),
  },
  {
    field: "priority",
    headerName: "Priority",
    renderCell: (value) => value.row.priority.name,
  },
  {
    field: "severity",
    headerName: "Severity",
    renderCell: (value) => (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "100%",
        }}
      >
        {SEVERITY_ICONS[value.row.severity.id]}
      </Box>
    ),
  },
  {
    field: "due",
    headerName: "Due",
    valueGetter: (value, row) => (!!row.due ? format.date(row.due) : "-"),
    width: 120,
  },
  {
    field: "assigned",
    headerName: "Assigned",
    valueGetter: (value, row) =>
      `${row.assignedTo.fname} ${row.assignedTo.lname}`,
    width: 180,
  },
  {
    field: "created",
    headerName: "Created",
    valueGetter: (value, row) =>
      !!row.created ? format.date(row.created) : "-",
    width: 120,
  },
];

/**
 * CustomToolbar
 *
 * Renders a custom toolbar for the Datagrid
 *
 * @returns
 */
const CustomToolbar = () => (
  <GridToolbarContainer sx={{ p: 2 }}>
    <GridToolbarQuickFilter />
    <Box sx={{ flexGrow: 1 }} />
    <GridToolbarFilterButton slotProps={{ button: { color: "text" } }} />
    <GridToolbarExport
      slotProps={{
        tooltip: { title: "Export data" },
        button: { variant: "outlined" },
      }}
    />
  </GridToolbarContainer>
);

/**
 * WorkOrderCollection
 *
 * @returns
 */
const Collection = ({ reducer: { state, dispatch, actions } }) => {
  const [tab, setTab] = useState(0);
  const navigate = useNavigate();
  const adminGatewayAPI = useAdminGatewayAPI();
  const app = useApp();

  useEffect(() => {
    fetchWorkOrders(1 - tab);
  }, [tab]);

  /**
   * FetchWorkOrders
   */
  const fetchWorkOrders = (active) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Work Orders");

    adminGatewayAPI
      .fetch(`/api/bridge/workorders/?active=${active}`)
      .then(({ payload: workOrders }) => {
        dispatch({ type: actions.INITIALIZE_WORKORDERS, payload: workOrders });
      })
      .catch((error) => {
        app.error({ error });
        dispatch({ type: actions.INITIALIZE_WORKORDERS, payload: null });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  if (!state.data) return;

  return (
    <WidgetFrame>
      <Box sx={{ width: "100%", mb: 2 }}>
        <Tabs
          value={tab}
          onChange={(event, tab) => {
            setTab(tab);
          }}
        >
          <Tab label="Active" />
          <Tab label="Completed" />
        </Tabs>
      </Box>
      <DataGrid
        loading={!state.data}
        rows={state.data}
        columns={columns}
        slots={{ toolbar: CustomToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
          loadingOverlay: {
            variant: "skeleton",
            noRowsVariant: "skeleton",
          },
        }}
        onRowClick={(params) => {
          navigate(`/app/admin/workorder/${params.row.id}`);
        }}
        initialState={{
          pagination: {
            paginationModel: {
              page: 0,
              pageSize: PAGE_SIZE,
            },
          },
          pinnedColumns: {
            left: ["id"],
          },
        }}
        pageSizeOptions={[5, 10, 25]}
      />
    </WidgetFrame>
  );
};

/**
 * PropTypes
 */
Collection.propTypes = {
  reducer: PropTypes.object,
};

export default Collection;
