/********************************************************************
 *
 * TourMiniEdit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Box,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  FormHelperText,
  Button,
} from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTime } from "luxon";
import { Formik, getIn } from "formik";
import * as yup from "yup";
import format from "../../../utils/format";
import { WidgetFrame } from "../../lib";
import { useApp, useAPI } from "../../../providers/AppProvider";

/**
 * TourMiniEdit
 *
 * Widget for quickly viewing/editting/creating a Tour.
 *
 */
const TourMiniEdit = ({ tour, tourType, onSaved, onCancelled }) => {
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSave
   *
   * @param {*} event
   */
  const onSave = (tour) => {
    //
    //  Save the tour data
    //
    const notifyHandle = app.notify("Saving");
    api
      .update(
        tour.id
          ? `/api/tours/tour/${tour.id}/`
          : `/api/tourtypes/tourtype/${tourType.id}/tours/tour/`,
        tour
      )
      .then((tour) => {
        onSaved && onSaved({ tour });
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  /**
   * onCancel
   *
   * Fired when the user aborts changes.
   *
   */
  const onCancel = (dirty) => {
    if (
      !dirty ||
      window.confirm(
        "You have unsaved changes. Are you sure you want to close this dialog and loose your changes?"
      )
    ) {
      onCancelled && onCancelled();
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Formik
      initialValues={tour}
      //      enableReinitialize={true}
      onSubmit={(values) => {
        onSave(values);
      }}
      validationSchema={yup.object().shape({
        acquire: yup
          .date("Invalid acquire time format")
          .required("Acquire time is required")
          .max(yup.ref("start"), "Acquire time must be prior to start time."),
        start: yup
          .date("Invalid departure time format")
          .required("Departure time is required")
          .max(yup.ref("end"), "Departure time must be prior to return time."),
        end: yup
          .date("Invalid return time format")
          .required("Return time is required")
          .max(
            yup.ref("release"),
            "Return time must be prior to release time."
          ),
        release: yup
          .date("Invalid release time format")
          .required("Release time is required"),
        capacity: yup
          .number()
          .integer()
          .min(0, "Capacity cannot be less than zero.")
          .max(12, "Capacity must be twelve or less."),
      })}
    >
      {({
        values,
        touched,
        errors,
        dirty,
        isSubmitting,
        isValid,
        setFieldValue,
        setFieldTouched,
        setValues,
        handleChange,
        handleBlur,
        handleSubmit,
        validateForm,
      }) => {
        return (
          <Box sx={{ display: "flex", flexDirection: "column", gap: 4 }}>
            <Summary tourType={tourType} tour={values} />
            <form onSubmit={handleSubmit}>
              <Editor
                tour={values}
                setFieldValue={setFieldValue}
                setValues={setValues}
                onBlur={handleBlur}
                errors={errors}
              />
              {/* <Debugging
                values={values}
                touched={touched}
                dirty={dirty}
                isValid={isValid}
              /> */}
              <Box
                sx={{ display: "flex", justifyContent: "space-between", pt: 2 }}
              >
                <Box>
                  <Button
                    size="small"
                    href={`/app/goods/tour/${tour && tour.id ? tour.id : ""}`}
                    onClick={() => onCancelled()}
                    disabled={!(tour && tour.id)}
                    target="_blank"
                  >
                    More Options
                  </Button>
                </Box>
                <Box sx={{ display: "flex", gap: 2 }}>
                  <Button
                    type="submit"
                    size="small"
                    variant="contained"
                    disabled={
                      isSubmitting ||
                      !(isValid && ((touched && dirty) || !values.id))
                    }
                  >
                    Save
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => onCancel(dirty)}
                  >
                    Cancel
                  </Button>
                </Box>
              </Box>
            </form>
          </Box>
        );
      }}
    </Formik>
  );
};

const Editor = ({ tour, setFieldValue, setValues, onBlur, errors }) => (
  <WidgetFrame title="Edit Fields">
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <FormControl sx={{ pb: 2 }}>
        <TimePicker
          format="HH:mm"
          ampm={false}
          slotProps={{
            textField: {
              label: "Departure",
              size: "small",
              fullWidth: true,
            },
          }}
          timeSteps={{ hours: 1, minutes: 15 }}
          value={format.toDateTime(tour.start, tour.tz)}
          onBlur={onBlur}
          onChange={(value) => {
            setValues({
              ...tour,
              acquire: format.toISO(
                value.minus({
                  minutes:
                    process.env.REACT_APP_DEFAULT_TOUR_ASSET_PRETOUR_INTERVAL,
                })
              ),
              start: format.toISO(value),
            });
          }}
        />
      </FormControl>
      <FormControl sx={{ pb: 2 }}>
        <TimePicker
          format="HH:mm"
          ampm={false}
          slotProps={{
            textField: {
              label: "Return",
              size: "small",
              fullWidth: true,
            },
          }}
          timeSteps={{ hours: 1, minutes: 15 }}
          value={format.toDateTime(tour.end, tour.tz)}
          onBlur={onBlur}
          onChange={(value) => {
            setValues({
              ...tour,
              release: format.toISO(
                value.plus({
                  minutes:
                    process.env.REACT_APP_DEFAULT_TOUR_ASSET_PRETOUR_INTERVAL,
                })
              ),
              end: format.toISO(value),
            });
          }}
        />
        <FormHelperText error>
          {getIn(errors, "acquire") ||
            getIn(errors, "start") ||
            getIn(errors, "end") ||
            getIn(errors, "release") ||
            " "}
        </FormHelperText>
      </FormControl>
      <FormControl sx={{ pb: 0 }} size="small">
        <InputLabel id="capacity-label">Capacity</InputLabel>
        <Select
          labelId="capacity-label"
          id="tour.capacity"
          value={tour && Number.isInteger(tour.capacity) ? tour.capacity : 10}
          label="Capacity"
          onChange={(event) => {
            setFieldValue("capacity", event.target.value, true);
          }}
        >
          <MenuItem value={0}>0</MenuItem>
          <MenuItem value={1}>1</MenuItem>
          <MenuItem value={2}>2</MenuItem>
          <MenuItem value={3}>3</MenuItem>
          <MenuItem value={4}>4</MenuItem>
          <MenuItem value={5}>5</MenuItem>
          <MenuItem value={6}>6</MenuItem>
          <MenuItem value={7}>7</MenuItem>
          <MenuItem value={8}>8</MenuItem>
          <MenuItem value={9}>9</MenuItem>
          <MenuItem value={10}>10</MenuItem>
          <MenuItem value={11}>11</MenuItem>
          <MenuItem value={12}>12</MenuItem>
        </Select>
        <FormHelperText error>
          {getIn(errors, "capacity") || " "}
        </FormHelperText>
      </FormControl>
    </Box>
  </WidgetFrame>
);

const Summary = ({ tourType, tour }) => (
  <WidgetFrame
    title={
      tour && tour.id ? `${tourType.name} ${tour.id}` : `New ${tourType.name}`
    }
    action={`PAX: ${tour.capacity}`}
  >
    <Table variant="caption" size="small">
      <TableBody>
        <TableRow sx={{ "& > td": { fontSize: "0.8em" }, bgcolor: "#f6f6f6" }}>
          <TableCell>Acquire</TableCell>
          <TableCell>
            {DateTime.fromISO(tour.acquire)
              .setZone(tour.tz)
              .toLocaleString({
                ...DateTime.DATETIME_SHORT,
                weekday: "short",
                timeZoneName: "short",
              })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Depart</TableCell>
          <TableCell>
            {DateTime.fromISO(tour.start)
              .setZone(tour.tz)
              .toLocaleString({
                ...DateTime.DATETIME_SHORT,
                weekday: "short",
                timeZoneName: "short",
              })}
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell>Return</TableCell>
          <TableCell>
            {DateTime.fromISO(tour.end)
              .setZone(tour.tz)
              .toLocaleString({
                ...DateTime.DATETIME_SHORT,
                weekday: "short",
                timeZoneName: "short",
              })}
          </TableCell>
        </TableRow>
        <TableRow sx={{ "& > td": { fontSize: "0.8em" }, bgcolor: "#f6f6f6" }}>
          <TableCell>Release</TableCell>
          <TableCell>
            {DateTime.fromISO(tour.release)
              .setZone(tour.tz)
              .toLocaleString({
                ...DateTime.DATETIME_SHORT,
                weekday: "short",
                timeZoneName: "short",
              })}
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  </WidgetFrame>
);

const Debugging = ({ values, touched, dirty, isValid }) => (
  <Box sx={{ border: "solid 1px #999999", borderRadius: 2, p: 2 }}>
    <Typography variant="h5" sx={{ pb: 2 }}>
      Debugging Information
    </Typography>
    {DateTime.fromISO(values.start).toISO()}
    <Typography variant="caption" component="p">
      ID: {values.id}
    </Typography>
    <Typography variant="caption" component="p">
      Capacity: {values && values.capacity}
    </Typography>
    <Typography variant="caption" component="p">
      Touched: {touched ? "TRUE" : "FALSE"}
    </Typography>
    <Typography variant="caption" component="p">
      Dirty: {dirty ? "TRUE" : "FALSE"}
    </Typography>
    <Typography variant="caption" component="p">
      IsValid:
      {isValid ? "TRUE" : "FALSE"}
    </Typography>
    <Typography variant="caption" component="p">
      Ready:
      {isValid && ((touched && dirty) || !values.id) ? "TRUE" : "FALSE"}
    </Typography>
  </Box>
);

TourMiniEdit.propTypes = {
  tourType: PropTypes.object.isRequired,
  tour: PropTypes.object,
  onSaved: PropTypes.func.isRequired,
  onCancelled: PropTypes.func.isRequired,
};

export default TourMiniEdit;
