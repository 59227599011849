/********************************************************************
 *
 * index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { useOutletContext } from "react-router";
import { Box } from "@mui/material";
import { StatCard } from "../../../lib";
import Shifts from "./Shifts";
import Access from "./Access";
import Settings from "./Settings";
import Profile from "./Profile";
import ShiftStats from "./ShiftStats";
import TourStats from "./TourStats";
import { useApp, useAPI } from "../../../../providers/AppProvider";
import format from "../../../../utils/format";

/**
 * EmployeeDetails
 *
 * Renders information about an employee
 *
 * @param {*} props
 */
const EmployeeDetails = () => {
  const { employee, onUpdate } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  return (
    !!employee && (
      <Box
        sx={{
          display: "grid",
          gap: 4,
          gridTemplateColumns: { xs: "repeat(1, 1fr)", md: "repeat(12, 1fr)" },
          gridTemplateAreas: {
            xs: '"profile"  "info"  "shifts"',
            md: '"profile profile profile profile info info info info info info info info" \
            "profile profile profile profile shifts shifts shifts shifts shifts shifts shifts shifts"',
          },
        }}
      >
        <Box sx={{ gridColumn: { gridArea: "info" } }}>
          <Box
            sx={{
              display: "flex",
              flexDirection: { xs: "column" },
              gap: 4,
              width: "100%",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
                width: "100%",
              }}
            >
              <StatCard
                label="Service Period"
                value={format.toDurationString(
                  employee.start,
                  !!employee.end ? employee.end : format.todayISO(),
                  ["years", "months"]
                )}
              />
              <TourStats employee={employee} />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", md: "row" },
                gap: 2,
                width: "100%",
              }}
            >
              <ShiftStats employee={employee} />
            </Box>
          </Box>
        </Box>
        {/* <Box sx={{ gridColumn: { gridArea: "settings" } }}>
           <Access employee={employee} /> */}
        {/*
        </Box> */}
        <Box sx={{ gridArea: "profile" }}>
          <Profile employee={employee} onUpdate={onUpdate} />
        </Box>
        <Box sx={{ gridArea: "shifts" }}>
          <Shifts employee={employee} start={"2019-06-01T00:00:00Z"} />
        </Box>
      </Box>
    )
  );
};

export default EmployeeDetails;
