/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { useOutletContext } from "react-router";
import { Link, Box, Card, Typography, Button } from "@mui/material";
import { AddShoppingCart } from "@mui/icons-material";
import moment from "moment-timezone";
import { DateTime } from "luxon";
import { WidgetFrame, TipChip } from "../../../lib";
import Calendar from "../../../lib/Calendar/Calendar";
import format from "../../../../utils/format";
import { useApp, useAPI } from "../../../../providers/AppProvider";
import ProductFactory from "./ProductFactory";
import Agenda from "./Agenda";

/**
 * Products
 *
 * Renders a calendar-based view of the products belonging to
 * a product type.
 *
 */
const Products = () => {
  const [products, setProducts] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    DateTime.now().toFormat("yyyy-MM-dd")
  );
  const [tz] = useState("America/Vancouver");
  const [dateRange, setDateRange] = useState(null);
  const [showAddProduct, setShowAddProduct] = useState(false);
  const { productType } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchProducts
   *
   * Calls the API to fetch the products for the dates visible on the
   * calendar.
   *
   * @param {*} start
   * @param {*} end
   */
  const fetchProducts = (start, end) => {
    if (!start) throw new Error("Start date not defined");
    if (!end) throw new Error("End date not defined");
    if (end < start) throw new Error("Start date must be before end date");

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Products");

    //
    //  Request searches
    //
    api
      .fetch(
        `/api/producttypes/producttype/${
          productType.id
        }/products/daterange/${format.toISO(
          start.startOf("day")
        )}/${format.toISO(end.endOf("day"))}`
      )
      .then(({ payload: products }) => {
        setProducts(products);
      })
      .catch((error) => {
        app.error({ error });
        setProducts(null);
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * OnCalendarUpdate
   *
   * Fired when the dates on the calendar change.
   *
   * Fetches products for dates visible on the calendar.
   *
   * @param {*} interval
   */
  const onCalendarUpdate = (interval) => {
    setDateRange(interval);

    if (interval && interval.startDate && interval.endDate)
      fetchProducts(interval.startDate, interval.endDate);
  };

  const onRefresh = () => {
    if (dateRange) fetchProducts(dateRange.startDate, dateRange.endDate);
  };

  /**
   * OnDateRender
   *
   * Fired when a calendar date renders.
   *
   * Determines if there are tours on the specified date and
   * marks up the calendar accordingly.
   *
   * @param {*} event
   */
  const onDateRender = ({ date }) => {
    let dayProducts = [];

    for (let i = 0; products && i < products.length; i++) {
      let product = products[i];
      if (
        format.toDateTime(product.start, product.timezone).hasSame(date, "day")
      )
        dayProducts.push(product);
    }

    return (
      <Box
        sx={[
          { paddingLeft: "2px", height: "100%", width: "100%" },
          !!dayProducts.length && {
            backgroundColor: "#def1ed",
          },
        ]}
      >
        <Typography variant="body2">{format.calendarDay(date)}</Typography>
        <Box sx={{ margin: "0.1em" }}>
          {dayProducts.map((dayProduct, index) => (
            <Typography
              key={index}
              component="div"
              sx={{ fontSize: "10px", lineHeight: "10px" }}
            >
              {format.time(dayProduct.start, dayProduct.timezone)}
            </Typography>
          ))}
        </Box>
      </Box>
    );
  };

  /**
   * onSelectedDateChanged
   *
   * Fired when the user clicks a date cell in the calendar.
   *
   * @param {event} event
   */
  const onSelectedDateChanged = ({ date }) => {
    setSelectedDate(date);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Box>
      <Box sx={{ display: "flex", gap: 4 }}>
        <Box sx={{ flex: { xs: "1 1 50%" } }}>
          <WidgetFrame>
            <Calendar
              date={undefined}
              width={500}
              onCalendarUpdating={onCalendarUpdate}
              onDateRender={onDateRender}
              onSelectedDateChanged={onSelectedDateChanged}
            />
          </WidgetFrame>
        </Box>
        <Box sx={{ flex: { xs: "1 1 50%" } }}>
          <WidgetFrame>
            <Agenda products={products} date={selectedDate} tz={tz} />
          </WidgetFrame>
        </Box>
      </Box>
      {dateRange && (
        <Box mt={2}>
          <Button
            variant="outlined"
            onClick={() => {
              setShowAddProduct(true);
            }}
          >
            Add Products
          </Button>
          {showAddProduct && (
            <ProductFactory
              productType={productType}
              start={dateRange.startDate}
              end={dateRange.endDate}
              show={showAddProduct}
              onClose={() => {
                setShowAddProduct(false);
                onRefresh();
              }}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default Products;
