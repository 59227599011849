/********************************************************************
 *
 * WidgetFrame.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Card, CardHeader, CardContent } from "@mui/material";

/**
 * WidgetFrame
 *
 * A container for on-page features.
 *
 * @param {*} param0
 *
 * @returns
 */
const WidgetFrame = ({ avatar, title, action, background, children }) => {
  const [bgClass, setBgClass] = useState(null);

  useEffect(() => {
    switch (background) {
      case "dark":
        setBgClass({
          backgroundColor: "primary.dark",
          color: "#ffffff",
        });
        break;
      default:
        setBgClass(null);
    }
  }, [background]);

  return (
    <Card
      data-test-id="WIDGET_FRAME"
      sx={[
        {
          height: "100%",
          padding: 2,
          overflow: "visible",
          flex: 1,
          border: "1px solid rgba(122,123,151,.3)",
        },
        bgClass,
      ]}
    >
      {(!!avatar || !!title || !!action) && (
        <CardHeader
          avatar={avatar}
          title={title}
          action={action}
          sx={{
            "&.MuiCardHeader-root": { pb: 0 },
            "& .MuiCardHeader-title": {
              color: "text.secondary",
              fontSize: "1em",
              fontWeight: 500,
              textTransform: "uppercase",
            },
          }}
        />
      )}
      <CardContent>{children}</CardContent>
    </Card>
  );
};

//
//  PropTypes
//
WidgetFrame.propTypes = {
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.element,
    PropTypes.string,
  ]),
};

export default WidgetFrame;
