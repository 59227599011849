/********************************************************************
 *
 * IsoDateTimePicker.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { DateTime } from "luxon";
import { DateTimePicker } from "@mui/x-date-pickers";
import { Box, Typography } from "@mui/material";

/**
 * IsoDateTimePicker
 *
 * Component to manage dates and times within the context of a
 * specified timezone. If a timezone is not provided, the browser's
 * default timezone is used.
 *
 */
const IsoDateTimePicker = ({
  id,
  label,
  value,
  tz = "America/Vncouver",
  showUTC,
  onChange,
  onBlur,
  clearable = false,
  error,
  helperText,
}) => {
  if (!!value && !(value instanceof DateTime))
    value = DateTime.fromISO(value).setZone(tz);

  if (!!value && !value.isValid)
    throw new Error(
      "Invalid date value. Please use a valid ISO date string as the 'value' parameter."
    );

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////
  return (
    <Box sx={{ width: "100%" }}>
      <DateTimePicker
        id={id}
        label={label}
        ampm={false}
        value={value}
        timezone={tz}
        onBlur={onBlur}
        onChange={(dateTime) => {
          onChange({
            target: {
              id,
              value: !!dateTime ? dateTime.toUTC().toISO() : null,
            },
          });
        }}
        slotProps={{
          textField: { error: error, helperText: helperText },
          field: { clearable },
        }}
        sx={{ width: "100%" }}
      />
      {showUTC && (
        <Typography variant="caption" component="div">
          {!!value ? value.toUTC().toString() : "--"}
        </Typography>
      )}
      {tz && (
        <Typography variant="caption" component="div">
          {!!value
            ? value.setLocale().toLocaleString({
                ...DateTime.DATETIME_SHORT,
                weekday: "short",
                timeZoneName: "short",
              })
            : "--"}
        </Typography>
      )}
    </Box>
  );
};

/*
 **  PropTypes
 */
IsoDateTimePicker.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string.isRequired,
  tz: PropTypes.string,
  showUTC: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  clearable: PropTypes.bool,
  helperText: PropTypes.string,
  error: PropTypes.bool,
};

export default IsoDateTimePicker;
