/********************************************************************
 *
 * /src/components/sales/order/payment/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import * as yup from "yup";
import {
  Box,
  InputAdornment,
  TextField,
  MenuItem,
  Typography,
} from "@mui/material";
import { AttachMoney } from "@mui/icons-material";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { WidgetFrame, EditorPane, useEditorContext } from "../../../lib";
import IsoDatePicker from "../../../lib/Inputs/IsoDatePicker";
import { useApp, useAPI } from "../../../../providers/AppProvider";
import format from "../../../../utils/format";

const PMTTYPE_CREDIT = 0;
const PMTTYPE_CASH = 1;
const PMTTYPE_CHEQ = 2;
const PMTTYPE_ETRANS = 3;

/**
 * Payment
 *
 * Process a payment for an order
 *
 */
const Payment = ({ order, show, onUpdated, onClose }) => {
  const [receiptTypes, setReceiptTypes] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchReceiptTypes();
  }, []);

  /**
   * FetchReceiptTypes
   *
   * Calls the API to initialize the types of receipts
   */
  const fetchReceiptTypes = () => {
    api
      .fetch(`/api/receiptTypes`)
      .then(({ payload: receiptTypes }) => {
        setReceiptTypes(receiptTypes);
      })
      .catch((error) => {
        app.error({ error });
        setReceiptTypes(null);
      });
  };

  /**
   * SavePayment
   *
   * Processes the payment receipt
   *
   * @param {*} order
   * @param {*} receiptType
   * @param {*} amount
   * @param {*} token
   */
  const savePayment = (order, date, receiptType, amount, token = null) => {
    //
    // NB: Transactions are handled in cents. Multiply amount by 100
    //
    return new Promise((resolve, reject) => {
      api
        .create(`/api/orders/order/${order.id}/receipt`, {
          date,
          receiptType,
          amount: amount * 100,
          token,
          application: "admin",
        })
        .then(({ payload: order }) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * GetToken
   *
   * Gets a receipt token based upon the receipt type
   */
  const getToken = (receipt) => {
    return new Promise((resolve, reject) => {
      switch (receipt.type.id) {
        case PMTTYPE_CREDIT:
          //
          //  Defer to getStripeToken.
          //
          getStripeToken()
            .then((token) => {
              resolve(token);
            })
            .catch((error) => {
              reject(error);
            });
          break;
        case PMTTYPE_CHEQ:
        case PMTTYPE_ETRANS:
          resolve(receipt.reference);
          break;
        default:
          resolve(null);
      }
    });
  };

  /**
   * GetStripeToken
   *
   * Requests an auth token from Stripe and returns the id.
   */
  const getStripeToken = () => {
    let card = null;

    return new Promise((resolve, reject) => {
      //
      // Ensure Stripe.js has loaded
      //
      if (!stripe || !elements)
        reject(new Error("Stripe has not initialized."));

      card = elements.getElement(CardElement);

      stripe
        .createToken(card)
        .then((result) => {
          if (result.error) throw result.error;

          resolve(result.token.id);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  /**
   * OnSave
   *
   * @param {*} receipt
   */
  const onSave = (receipt) => {
    getToken(receipt)
      .then((token) => {
        return savePayment(
          order,
          receipt.date,
          receipt.type,
          receipt.amount,
          token
        );
      })
      .then((order) => {
        !!onUpdated && onUpdated(order);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  if (!receiptTypes || !order) return null;

  return (
    <EditorPane
      title="Apply A Payment"
      label="Pay"
      initialValues={{
        date: format.todayISO(),
        type: receiptTypes.find(
          (receiptType) => receiptType.id == PMTTYPE_CREDIT
        ),
        reference: "",
        cardElement: null,
        ccComplete: false,
        amount: parseFloat(order.totals.due / 100).toFixed(2),
      }}
      onSubmit={(values) => {
        onSave(values);
      }}
      validationSchema={yup.object().shape({
        ccComplete: yup.boolean().when("type", (type, schema) => {
          return type.id == PMTTYPE_CREDIT
            ? schema.oneOf([true], "The credit card details are not complete.")
            : schema;
        }),
        amount: yup
          .number()
          .positive()
          .max(
            parseFloat(order.totals.due / 100).toFixed(2),
            "Cannot exceed amount due"
          )
          .required("Amount required"),
      })}
      open={show}
      onClose={onClose}
    >
      <FormBody
        order={order}
        receiptTypes={receiptTypes}
        onUpdated={onUpdated}
      />
    </EditorPane>
  );
};

/**
 * FormBody
 *
 * @param {*} param0
 * @returns
 */
const FormBody = ({ order, receiptTypes }) => {
  const [stripeError, setStripeError] = useState(null);
  const {
    values,
    touched,
    errors,
    dirty,
    setFieldValue,
    setValues,
    handleChange,
    handleBlur,
  } = useEditorContext();

  /**
   * HandleStripeCardElementChange
   *
   * @param {*} event
   * @returns
   */
  const handleStripeCardElementChange = (event) => {
    const { complete, error } = event;

    if (error) setStripeError(error.message);

    return complete;
  };

  return (
    <WidgetFrame>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
            <Typography variant="h2">
              {order.customer.fname} {order.customer.lname}
            </Typography>
            <Typography>Due: {format.currency(order.totals.due)}</Typography>
          </Box>
          <IsoDatePicker
            id="date"
            label="Date"
            value={values.date}
            clearable
            onChange={handleChange}
            error={!!touched.date && !!errors.date}
            helperText={(!!touched.date && errors.date) || " "}
            sx={{ maxWidth: "250px" }}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2, justifyContent: "space-between" }}>
          <TextField
            name="type"
            label="Method"
            select
            onChange={(e) => {
              if (e.target.value == PMTTYPE_CREDIT)
                setFieldValue("reference", null);

              setFieldValue(
                "type",
                receiptTypes.find(
                  (receiptType) => receiptType.id == e.target.value
                )
              );
            }}
            value={values.type.id}
            sx={{ width: "100%" }}
          >
            {receiptTypes &&
              receiptTypes.map((receiptType) => (
                <MenuItem key={receiptType.id} value={receiptType.id}>
                  {receiptType.name}
                </MenuItem>
              ))}
          </TextField>
          <TextField
            id="amount"
            type="number"
            label="Amount"
            slotProps={{
              input: {
                startAdornment: (
                  <InputAdornment position="start">
                    <AttachMoney />
                  </InputAdornment>
                ),
              },
            }}
            value={values.amount}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            required
            error={errors.amount && touched.amount}
            helperText={errors.amount || " "}
            sx={{ maxWidth: "250px" }}
          />
        </Box>
        {values.type.id == PMTTYPE_CREDIT ? (
          <Box
            sx={{
              mb: 1,
              "& #card-element": {
                alignItems: "center",
                border: "1px solid #ced4da",
                borderRadius: "0.25rem",
                // height: "calc(1.5em + 0.75rem + 2px)",
                py: "20px",
                px: "12px",
              },
            }}
          >
            <CardElement
              id="card-element"
              options={{
                style: {
                  base: {
                    color: "#484848",
                    fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
                    fontSmoothing: "antialiased",
                    fontSize: "16px",
                    "::placeholder": {
                      color: "#484848",
                    },
                  },
                  invalid: {
                    color: "#484848",
                    iconColor: "#999999",
                  },
                },
              }}
              onChange={(e) =>
                setFieldValue("ccComplete", handleStripeCardElementChange(e))
              }
            />
            <Typography color="error" variant="caption" component="div">
              {errors.ccComplete || stripeError || "\u00A0"}
            </Typography>
          </Box>
        ) : (
          values.type.id != PMTTYPE_CASH && (
            <TextField
              id="reference"
              label={
                values.type && values.type.id === PMTTYPE_CHEQ
                  ? "Cheque Number"
                  : "Reference Number"
              }
              placeholder={
                values.type && values.type.id === PMTTYPE_CHEQ
                  ? "Cheque #"
                  : "Reference #"
              }
              value={values.reference}
              onBlur={handleBlur}
              onChange={handleChange}
              fullWidth
              error={errors.reference && touched.reference}
              helperText={errors.reference || " "}
            />
          )
        )}
      </Box>
    </WidgetFrame>
  );
};

export default Payment;
