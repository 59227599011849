/********************************************************************
 *
 * Edit.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, TextField } from "@mui/material";
import * as yup from "yup";
import { EditorPane, useEditorContext, WidgetFrame } from "../../../../lib";
import TimeZoneField from "./TimeZoneField";
import IsoDatePicker from "../../../../lib/Inputs/IsoDatePicker";

/**
 * Edit
 *
 * Component for adding a new, or editting the descriptive information
 * an exiting employee.
 *
 */
const Edit = ({ employee, show, onUpdate, onClose }) => {
  if (!employee) return;

  return (
    <EditorPane
      title="Manage Employee Details"
      initialValues={
        employee || {
          fname: "",
          lname: "",
          phone: "",
          email: "",
          imageUrl: "",
          timeZone: { id: 0 },
          rates: {
            hourly: 0,
            daily: 0,
          },
        }
      }
      onSubmit={(values) => {
        onUpdate(values);
      }}
      validationSchema={yup.object().shape({})}
      open={show}
      onClose={onClose}
    >
      <FormBody />
    </EditorPane>
  );
};

/**
 * FormBody
 *
 * @param {*} props
 */
const FormBody = ({}) => {
  const {
    values,
    touched,
    errors,
    dirty,
    setFieldValue,
    setValues,
    handleChange,
    handleBlur,
  } = useEditorContext();
  return (
    <WidgetFrame>
      <Box
        test-data-id="FLEX"
        sx={{ display: "flex", flexDirection: "column", gap: 0 }}
      >
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            name="fname"
            label="First Name"
            value={values.fname}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            error={!!touched.fname && !!errors.fname}
            helperText={(!!touched.fname && errors.fname) || " "}
          />
          <TextField
            name="lname"
            label="Last Name"
            value={values.lname}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            error={!!touched.lname && !!errors.lname}
            helperText={(!!touched.lname && !!errors.lname) || " "}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            name="role"
            label="Role"
            value={values.role}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            error={!!touched.role && !!errors.role}
            helperText={(!!touched.role && errors.role) || " "}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            name="SIN"
            label="SIN"
            value={values.SIN}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            error={!!touched.SIN && !!errors.SIN}
            helperText={(!!touched.SIN && errors.SIN) || " "}
          />
          <IsoDatePicker
            id="DOB"
            label="Birth Date"
            value={values.DOB}
            tz={values.timeZone.name}
            clearable
            onChange={handleChange}
            error={!!touched.DOB && !!errors.DOB}
            helperText={(!!touched.DOB && errors.DOB) || " "}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            name="email"
            label="Email"
            value={values.email}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            error={!!touched.email && !!errors.email}
            helperText={(!!touched.email && !!errors.email) || " "}
          />
          <TextField
            id="phone"
            label="Phone"
            value={values.phone}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            error={!!touched.phone && !!errors.phone}
            helperText={(!!touched.phone && errors.phone) || " "}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            id="imageURL"
            type="url"
            label="Image URL"
            value={values.imageUrl}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            error={!!touched.imageUrl && !!errors.imageUrl}
            helperText={(!!touched.imageUrl && !!errors.imageUrl) || " "}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <IsoDatePicker
            id="start"
            label="Start Date"
            value={values.start}
            tz={values.timeZone.name}
            clearable
            onChange={handleChange}
            error={!!touched.start && !!errors.start}
            helperText={
              (!!touched.start && !!errors.start && errors.start) || " "
            }
          />
          <IsoDatePicker
            id="end"
            label="End Date"
            value={values.end}
            tz={values.timeZone.name}
            clearable
            onChange={handleChange}
            error={!!touched.end && !!errors.end}
            helperText={(!!touched.end && errors.end) || " "}
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TimeZoneField
            id="timeZone.id"
            value={values.timeZone.id}
            onBlur={handleBlur}
            onChange={handleChange}
            error={
              !!touched.timeZone &&
              !!touched.timeZone.id &&
              !!errors.timeZone &&
              !!errors.timeZone.id
            }
            helperText={
              (!!touched.timeZone &&
                !!touched.timeZone.id &&
                !!errors.timeZone &&
                !!errors.timeZone.id &&
                errors.timeZone.id) ||
              " "
            }
          />
        </Box>
        <Box sx={{ display: "flex", gap: 2 }}>
          <TextField
            id="rates.hourly"
            type="currency"
            label="Hourly Rate"
            value={!!values.rates.hourly ? values.rates.hourly : 0}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            error={
              !!touched.rates &&
              !!touched.rates.hourly &&
              !!errors.rates &&
              !!errors.rates.hourly
            }
            helperText={
              (!!touched.rates &&
                !!touched.rates.hourly &&
                !!errors.rates &&
                !!errors.rates.hourly &&
                errors.rates.hourly) ||
              " "
            }
          />
          <TextField
            id="rates.daily"
            type="currency"
            label="Daily Rate"
            value={!!values.rates.daily ? values.rates.daily : 0}
            onBlur={handleBlur}
            onChange={handleChange}
            fullWidth
            error={
              !!touched.rates &&
              !!touched.rates.daily &&
              !!errors.rates &&
              !!errors.rates.daily
            }
            helperText={
              (!!touched.rates &&
                !!touched.rates.daily &&
                !!errors.rate &&
                !!errors.rates.daily) ||
              " "
            }
          />
        </Box>
      </Box>
    </WidgetFrame>
  );
};

export default Edit;
