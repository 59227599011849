/********************************************************************
 *
 * Charge.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { LinkCard2, CardAction } from "../../../../lib";
import DiscountDialog from "../DiscountDialog";
import CouponDialog from "../CouponDialog";
import ChargeSummary from "./ChargeSummary";
import { useApp, useAPI } from "../../../../../providers/AppProvider";

/**
 * Charge
 *
 * Component that renders information for a charge.
 *
 */
const Charge = ({ order, charge, onUpdated }) => {
  const [showAddDiscount, setShowAddDiscount] = useState(null);
  const [showAddCoupon, setShowAddCoupon] = useState(null);
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////

  const removeDiscount = (order, charge, discount) => {
    return new Promise((resolve, reject) => {
      api
        .delete(
          `/api/orders/order/${order.id}/charges/charge/${charge.id}/discount/${discount.id}`
        )
        .then(({ payload: order }) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  const removeCharge = (order, charge) => {
    return new Promise((resolve, reject) => {
      api
        .delete(`/api/orders/order/${order.id}/charges/charge/${charge.id}`)
        .then(({ payload: order }) => {
          resolve(order);
        })
        .catch((error) => {
          reject(error);
        });
    });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onDiscountAdded = (order) => {
    onUpdated && onUpdated(order);
    setShowAddDiscount(null);
  };

  const onRemoveDiscount = ({ discount }) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Removing Discount");

    removeDiscount(order, charge, discount)
      .then((order) => {
        onUpdated && onUpdated(order);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  const onCouponAdded = (order) => {
    onUpdated && onUpdated(order);
    setShowAddCoupon(null);
  };

  const onRemoveCharge = (charge) => {
    if (!window.confirm("Are you sure you want to delete this charge?")) return;

    const notifyHandle = app.notify("Removing Charge");

    removeCharge(order, charge)
      .then((order) => {
        onUpdated && onUpdated(order);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <>
      <LinkCard2
        link={`/app/catalog/product/${charge.product.id}`}
        title={charge.description}
        content={
          <ChargeSummary charge={charge} onRemoveDiscount={onRemoveDiscount} />
        }
        actions={
          <>
            <CardAction
              label="Add Discount"
              onClick={() => {
                setShowAddDiscount(true);
              }}
            />
            <CardAction
              label="Add Coupon"
              onClick={() => {
                setShowAddCoupon(true);
              }}
            />
            <CardAction
              label="Remove"
              onClick={() => {
                onRemoveCharge(charge);
              }}
            />
          </>
        }
      />
      <DiscountDialog
        show={showAddDiscount}
        order={order}
        charge={charge}
        onSaved={onDiscountAdded}
        onCancelled={() => {
          setShowAddDiscount(null);
        }}
      />
      <CouponDialog
        show={showAddCoupon}
        order={order}
        charge={charge}
        onSaved={onCouponAdded}
        onCancelled={() => {
          setShowAddCoupon(null);
        }}
      />
    </>
  );
};

Charge.propTypes = {
  order: PropTypes.object.isRequired,
  charge: PropTypes.object.isRequired,
  onUpdated: PropTypes.func.isRequired,
};

export default Charge;
