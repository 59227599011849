/********************************************************************
 *
 * Timesheet.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import "./styles.css";

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Container, Card, Box, Button } from "@mui/material";
import { DateTime } from "luxon";
import DateController from "../DateController";
import Header from "./Header";
import Summary from "./Summary";
import Schedule from "./Schedule";
import { useApp, useAPI } from "../../../../providers";
import format from "../../../../utils/format";

/**
 * Timesheet
 *
 * Renders a seven day view, from Sun-Sat, containing the specified
 * date.
 *
 * @param {*} props
 */
const Timesheet = ({ employeeId, tz = "America/Vancouver" }) => {
  const [date, setDate] = useState(DateTime.now().setZone(tz));
  const [shiftToggle, setShiftToggle] = useState(false);
  const [timesheet, setTimesheet] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (!date) return;

    fetchTimesheet(employeeId, date.year, date.weekNumber); //date.startOf("week"), date.endOf("week"));
  }, [date]);

  /**
   * FetchTimesheet
   *
   * Fetches the timesheet
   *
   * @param {*} id
   */
  const fetchTimesheet = (employeeId, year, week) => {
    api
      .fetch(`/api/timesheets/timesheet/employee/${employeeId}/${year}/${week}`)
      .then(({ payload: timesheet }) => {
        setTimesheet(timesheet);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!timesheet) return;

  return (
    <Container maxWidth="md">
      <Box sx={{ pb: 3 }}>
        <DateController
          initialValue={date}
          onChange={(date) => {
            setDate(date);
          }}
        />
      </Box>
      <Card sx={{ p: "10px" }}>
        <Box
          sx={{
            color: "text.secondary",
          }}
        >
          <Box
            data-test-id="TIMESHEET_FRAME"
            className="frame"
            sx={{
              px: 2,
              pb: 2,
              overflow: "hidden",
              overflowX: "auto",
            }}
          >
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "subgrid",
                gridColumn: "1/span 1",
                gridTemplateRows: "sungrid",
                gridRow: "1 / span 1",
              }}
            >
              <Button
                onClick={() => {
                  setShiftToggle(!shiftToggle);
                }}
              >
                Shifts
              </Button>
            </Box>
            <Header start={date.startOf("week")} />
            <Schedule
              dates={timesheet.dates}
              startDate={format.toDateTime(timesheet.start)}
              show={shiftToggle}
            />
            <Summary
              timesheet={timesheet}
              startDate={format.toDateTime(timesheet.start)}
            />
          </Box>
        </Box>
      </Card>
    </Container>
  );
};

/*
 **  PropTypes
 */
Timesheet.propTypes = {
  date: PropTypes.instanceOf(DateTime),
};

export default Timesheet;
