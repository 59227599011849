/********************************************************************
 *
 * /components/goods/goods/tour/summary.jsx
 *
 * Renders a summary of tour information.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Box, Avatar, IconButton } from "@mui/material";
import {
  AccessTime,
  Update,
  DirectionsBoat,
  Edit as EditIcon,
} from "@mui/icons-material";
import { DateTime } from "luxon";
import { WidgetFrame, TipChip, HorizontalGroup } from "../../lib";
import { StatCard, SuperStat } from "../../lib";
import format from "../../../utils/format";
import EditTour from "./Edit";

/**
 * Summary
 *
 * Render a summary of a tour.
 *
 * @param {*} param0
 *
 * @returns
 */
const Summary = ({ tour, onUpdate }) => {
  const [edit, setEdit] = useState(false);

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnSaved
   *
   * Fired once the edit state has saved the descriptive information.
   *
   * Returns to display mode.
   *
   * @param {*} event
   */
  const onSaved = (event) => {
    if (onUpdate) {
      onUpdate(event);
      setEdit(false);
    }
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle Methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!tour) return "Invalid Tour";

  return (
    <WidgetFrame
      title={timePeriodStringBuilder(tour.start, tour.end, tour.tz)}
      action={
        !!onUpdate && (
          <IconButton onClick={() => setEdit(true)} size="small">
            <EditIcon />
          </IconButton>
        )
      }
    >
      <>
        <Box
          sx={{
            display: "grid",
            gap: 4,
            gridTemplateColumns: "repeat(6, minmax(0, 1fr))",
            width: "100%",
          }}
        >
          <Box sx={{ gridColumn: { xs: "1/span 6" } }}>
            <HorizontalGroup>
              <TipChip label={tour.tz} icon={<AccessTime />} tip="Timezone" />
              <TipChip
                label={format.duration(
                  DateTime.fromISO(tour.end)
                    .diff(DateTime.fromISO(tour.start))
                    .as("minutes")
                )}
                icon={<Update />}
                tip="Return Time"
              />
            </HorizontalGroup>
          </Box>
          <Box
            sx={{
              gridColumn: { xs: "1/span 6", sm: "1/span 3" },
            }}
          >
            <StatCard label="Capacity" value={tour.capacity} />
          </Box>
          <Box
            sx={{
              gridColumn: { xs: "1/span 6", sm: "4/span 3   " },
            }}
          >
            <StatCard label="Available" value={tour.available} />
          </Box>
          {!!tour.asset && (
            <Box
              sx={{
                gridColumn: {
                  xs: "1/span 6",
                },
              }}
            >
              <SuperStat
                icon={
                  <Avatar>
                    <DirectionsBoat />
                  </Avatar>
                }
                label="Asset"
                value={tour.asset.name}
              />
            </Box>
          )}
          {!!tour.notes && (
            <Box sx={{ gridColumn: { xs: "1/span 6" } }}>{tour.notes}</Box>
          )}
        </Box>
        {onUpdate && edit && (
          <EditTour
            tour={tour}
            show={!!edit}
            onSaved={onSaved}
            onCancelled={() => setEdit(false)}
          />
        )}
      </>
    </WidgetFrame>
  );
};

/**
 * TimePeriodStringBuilder
 *
 * Builds a string based upon the tour's start and end times
 * @param {*} start
 * @param {*} end
 * @param {*} tz
 */
const timePeriodStringBuilder = (start, end, tz) => {
  const _start = DateTime.fromISO(start).setZone(tz);
  const _end = DateTime.fromISO(end).setZone(tz);

  if (_start.hasSame(_end, "day"))
    return `${_start.toFormat("EEE, MMM d, yyyy HHmm")} - ${_end.toFormat(
      "HHmm"
    )}`;
  else
    return `${_start.toFormat("EEE, MMM d, yyyy HHmm")} - ${_end.toFormat(
      "EEE, MMM D, yyyy HHmm"
    )}`;
};

export default Summary;
