/********************************************************************
 *
 * /lib/Schedule/TimeGrid.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box } from "@mui/material";
import format from "../../../utils/format";

/**
 * TimeGrid
 *
 * @param {Array} props
 */
const TimeGrid = ({ date, tz, onTimeSlotRender }) => {
  const hours = [...Array(24).keys()];
  const _date = format.toDateTime(date, tz).startOf("day");

  return (
    <Box sx={{ display: "flex" }}>
      {/* Index */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          flex: "0 0 auto",
          minWidth: "50px",
          overflowY: "hidden",
          fontSize: "60%",
          fontWeight: "400",
        }}
      >
        <Box
          sx={{
            display: "block",
            boxSizing: "border-box",
            position: "relative",
          }}
        >
          {hours.map((hour) => (
            <Box
              key={hour}
              sx={{
                display: "block",
                height: "48px",
                paddingLeft: "20px",
                position: "relative",
                textAlign: "right",
                top: "-6px",
              }}
            >
              {hour !== 0 ? `${hour}:00` : ""}
            </Box>
          ))}
        </Box>
      </Box>
      {/* Agenda */}
      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          flexBasis: "auto",
          flex: "1 1 auto",
          overflowY: "hidden",
          fontSize: "10px",
          fontFamily: "Roboto, Helvetica, Arial, sans-serif",
          fontWeight: 400,
        }}
      >
        {/* Grid */}
        <Box
          sx={{
            display: "block",
            flexBasis: "auto",
            flexGrow: 1,
            flexShrink: 0,
            minWidth: 129,
            boxSizing: "border-box",
            overflowX: "visible",
            overflowY: "visible",
            paddingRight: "12px",
            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "block",
              boxSizing: "border-box",
              position: "relative",
            }}
          >
            {hours.map((hour) => (
              <Box
                key={hour}
                sx={{
                  display: "block",
                  height: "48px",
                  paddingRight: "8px",
                  position: "relative",
                  borderTop: "solid 1px #eeeeee",
                  backgroundColor:
                    hour >= 9 && hour < 17 ? "#ffffff" : "#f8f8f8",
                }}
              >
                {onTimeSlotRender &&
                  onTimeSlotRender({
                    dateTime: _date.plus({ hour }),
                    tz: tz,
                  })}
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default TimeGrid;
