/********************************************************************
 *
 * Header.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { DateTime } from "luxon";

/**
 * Header
 *
 * @param {*} props
 */
const Header = ({ start }) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "subgrid",
        gridColumn: "1 / 10",
        gridTemplateRows: "subgrid",
        gridRow: "1 / 2",
      }}
    >
      {[...Array(7).keys()].map((d, index) => {
        const day = start.plus({ day: index });
        const isToday = day.hasSame(DateTime.now().setZone(start.zone), "day");

        return (
          <Box key={index} className={`dow${index + 1}`}>
            <Box
              sx={{
                fontWeight: 400,
                margin: 0,
                textAlign: "center",
                width: "100%",
              }}
            >
              <Typography
                sx={[
                  {
                    lineHeight: "32px",
                    position: "relative",
                    zIndex: "2",
                    fontSize: "11px",
                    fontWeight: 500,
                    letterSpacing: "0.8px",
                    marginLeft: 0,
                    marginRight: 0,
                    textIndent: "0.8px",
                    textTransform: "uppercase",
                  },
                  isToday && {
                    color: "primary.main",
                  },
                ]}
              >
                {day.toFormat("ccc")}
              </Typography>
              <Typography
                sx={[
                  {
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    zIndex: "2",
                    fontSize: "24px",
                    letterSpacing: "0px",
                    textIndent: "0px",
                    fontWeight: 200,
                    fontVariant: "tabular-nums",
                    fontFeatureSettings: "'tnum' 1",
                    borderRadius: "100%",
                    lineHeight: "40px",
                    height: "40px",
                    width: "40px",
                    marginLeft: "auto",
                    marginRight: "auto",
                    marginTop: "-8px",
                  },
                  isToday && {
                    color: "primary.contrastText",
                    backgroundColor: "primary.main",
                  },
                ]}
              >
                {day.toFormat("dd")}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};

/*
 **  PropTypes
 */
Header.propTypes = {
  start: PropTypes.instanceOf(DateTime).isRequired,
};

export default Header;
