/********************************************************************
 *
 * Format.js
 *
 * Formatting functions functions
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 ********************************************************************/

import moment from "moment-timezone";
import { DateTime, Interval } from "luxon";
import { isString } from "./utils";

const isValidDateTime = (dateTime) =>
  !!dateTime && dateTime instanceof DateTime && dateTime.isValid;

let format = new (class Format {
  timeFormat = "HHmm";

  ///////////////////////////////////////////////////////////////////
  //
  // LUXON Date and Time Functions
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * Converts an ISO date / time string to a Luxon DateTime
   * object with a spcified timezone.
   *
   * @param {*} isoDateTime
   * @param {*} timezone
   *
   * @returns A Luxon DateTime object with an initialized timezone.
   *
   */
  toDateTime(isoDateTime, timezone = "America/Vancouver") {
    if (isoDateTime == null) return undefined;

    return DateTime.fromISO(isoDateTime).setZone(timezone);
  }

  /**
   * Date
   *
   * @param {string | DateTime} dateTime - An ISO string format date
   * @param {string} tz - An ISO timezone descriptor
   *
   * @returns A localiezd string representation of an ISO format date and time
   */
  date(dateTime, tz) {
    if (dateTime == null) return null;

    if (isString(dateTime)) dateTime = this.toDateTime(dateTime, tz);

    if (!(dateTime instanceof DateTime))
      throw new Error(
        "Expected DateTime object or valid ISO date string as parameter"
      );

    return dateTime.toLocaleString(DateTime.DATE_MED);
  }

  /**
   * DateTime
   *
   * @param {string | DateTime} dateTime - An ISO string format date or DateTime object
   * @param {string} tz - An ISO timezone descriptor
   *
   * @returns A localiezd string representation of an ISO format date and time
   */
  dateTime(dateTime, tz) {
    if (dateTime == null) return null;

    if (isString(dateTime)) dateTime = this.toDateTime(dateTime, tz);

    if (!isValidDateTime(dateTime))
      throw new Error(
        "Expected DateTime object or valid ISO date string as parameter"
      );

    return dateTime.toLocaleString(DateTime.DATETIME_MED);
  }

  /**
   * dateTimeShort
   *
   * @param {string | DateTime} dateTime - An ISO string format date
   * @param {string} tz - An ISO timezone descriptor
   *
   * @returns A localiezd string representation of an ISO format date and time
   */
  dateTimeShort(dateTime, tz) {
    if (dateTime == null) return null;

    if (isString(dateTime)) dateTime = this.toDateTime(dateTime, tz);

    if (!(dateTime instanceof DateTime))
      throw new Error(
        "Expected DateTime object or valid ISO date string as parameter"
      );

    return dateTime.toFormat("LLL dd HHmm");
  }

  /**
   * dayDateShort
   *
   * @param {string | DateTime} dateTime - An ISO string format date
   * @param {string} tz - An ISO timezone descriptor
   *
   * @returns A localiezd string representation of an ISO format date and time
   */
  dayDateShort(dateTime, tz) {
    if (dateTime == null) return null;

    if (isString(dateTime)) dateTime = this.toDateTime(dateTime, tz);

    if (!(dateTime instanceof DateTime))
      throw new Error(
        "Expected DateTime object or valid ISO date string as parameter"
      );

    return dateTime.toFormat("ccc, LLL dd yyyy");
  }

  /**
   * dayDateTimeShort
   *
   * @param {string | DateTime} dateTime - An ISO string format date
   * @param {string} tz - An ISO timezone descriptor
   *
   * @returns A localiezd string representation of an ISO format date and time
   */
  dayDateTimeShort(dateTime, tz) {
    if (dateTime == null) return null;

    if (isString(dateTime)) dateTime = this.toDateTime(dateTime, tz);

    if (!(dateTime instanceof DateTime))
      throw new Error(
        "Expected DateTime object or valid ISO date string as parameter"
      );

    return dateTime.toFormat("ccc, LLL dd yyyy HHmm");
  }

  /**
   * CalendarDay
   *
   * @param {*} dateTime
   *
   * @returns A day of the month formatted for a calendar
   */
  calendarDay(dateTime) {
    if (!isValidDateTime(dateTime)) return null;

    return 1 === dateTime.day
      ? dateTime.toFormat("d MMM")
      : dateTime.toFormat("d");
  }

  /**
   * TimeInterval
   *
   * @param {*} dateTime
   *
   * @returns A day of the month formatted for a calendar
   */
  timeInterval(start, end, tz) {
    const interval = Interval.fromDateTimes(
      this.toDateTime(start, tz),
      this.toDateTime(end, tz)
    );

    return `${interval.toFormat("hh:mm")} ${interval.start.offsetNameShort}`;
  }

  /**
   * ShortDOW
   *
   * @param {string} dateTime - An ISO string format date
   * @param {string} tz - An ISO timezone descriptor
   *
   * @returns A day of the week, as an unabbreviated localized string
   */
  shortDOW(dateTime, tz) {
    const _date = this.toDateTime(dateTime, tz);
    return _date.toFormat("EEEE");
  }

  /**
   * ShortMonthYear
   *
   * @param {string} dateTime - An ISO string format date
   * @param {string} tz - An ISO timezone descriptor
   *
   * @returns A month as an abbreviated localized string and four digit year
   */
  shortMonthYear(dateTime, tz) {
    const _date = this.toDateTime(dateTime, tz);
    return _date.toFormat("LLL yyyy");
  }

  /**
   * Time
   *
   * @param {string} dateTime - An ISO string format date
   * @param {string} tz - An ISO timezone descriptor
   *
   * @returns A time (hours and minutes) in 24-hour time.
   */
  time(dateTime, tz) {
    const _date = this.toDateTime(dateTime, tz);
    return _date.toFormat(this.timeFormat);
  }

  /**
   * HoursBetween
   *
   * @param {string} a - An ISO string format date
   * @param {string} b - An ISO string format date
   * @param {string} tz - An ISO timezone descriptor
   *
   * @returns The number of hours difference between the two values.
   */
  hoursBetween(a, b, tz) {
    const diffInHours = this.toDateTime(b, tz).diff(
      this.toDateTime(a, tz),
      "hours"
    );
    return `${diffInHours.hours} hrs`;
  }

  /**
   * ToDurationString
   *
   * @param {string} a - An ISO string format date
   * @param {string} b - An ISO string format date
   *
   * @returns A string desribing the duration between the two dates
   */
  toDurationString(
    a,
    b,
    options = ["years", "months", "days", "hours", "minutes", "seconds"]
  ) {
    let duration = null;

    if (!a || !b) return null;

    duration = Interval.fromDateTimes(
      this.toDateTime(a),
      this.toDateTime(b)
    ).toDuration(options);

    return duration.toHuman({
      listStyle: "narrow",
      maximumDigits: 2,
      unitDisplay: "long",
      maximumFractionDigits: 0,
    });
  }

  /**
   * IsSameDay
   *
   * @param {string} a - An ISO string format date
   * @param {string} b - An ISO string format date
   * @param {string} tz - An ISO timezone descriptor
   *
   * @returns A boolean representing whether to values are the same
   * date within the specified timzezone.
   */
  isSameDay(a, b, tz) {
    //
    //  BUGBUG: How should this be handled if a timezone is not
    // passed? Currently the conversion uses a default timezone.
    //
    return this.toDateTime(a, tz).hasSame(this.toDateTime(b, tz), "day");
  }

  /**
   * TodayISO
   *
   * @returns Today's date in UTC ISO format
   */
  todayISO() {
    return DateTime.now().toUTC().toISO();
  }

  /**
   * ToISO
   *
   * Converts a Luxon DateTime object to a UTC ISO string.
   *
   * @param {DateTime} dateTime - A Luxon DateTime objecyt
   *
   * @returns An UTC ISO string
   */
  toISO(dateTime) {
    if (dateTime == null) return null;

    if (!(dateTime instanceof DateTime))
      throw new Error("Expected DateTime object type as parameter");

    return dateTime.toUTC().toISO();
  }

  ///////////////////////////////////////////////////////////////////
  //
  // MOMENT (legacy) Date and Time Functions
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * getMomentLocalized
   *
   * Initializes a date. If a timezone argument is included, then the
   * time is timezone adjusted, otherwise the time will be localized
   * to the browser locale.
   *
   * @param {date} date
   * @param {timezone} tz
   */
  getMomentLocalized(date, tz) {
    if (tz) {
      return moment.tz(date, tz);
    }
    return moment(date);
  }

  duration(duration) {
    return `${(duration / 60).toFixed(2)} hrs`;
  }

  dayDate(date, tz) {
    return this.getMomentLocalized(date, tz).format("dddd, MMM D, YYYY");
  }

  shortDate(date, tz) {
    return this.getMomentLocalized(date, tz).format(`MMM D`);
  }

  day(date, tz) {
    return this.getMomentLocalized(date, tz).format(`dddd`);
  }

  shortDayDate(date, tz) {
    return this.getMomentLocalized(date, tz).format(`ddd, MMM D`);
  }

  month(date, tz) {
    return this.getMomentLocalized(date, tz).format("MMMM YYYY");
  }

  dayDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `ddd, MMM D YYYY ${this.timeFormat}`
    );
  }

  //   dateTime(date, tz) {
  //     return this.getMomentLocalized(date, tz).format(
  //       `MMM D YYYY ${this.timeFormat} ${tz ? "z" : ""}`
  //     );
  //   }

  shortDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `MMM D ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  numDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `YYYY/MM/DD ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  shortDayDateTime(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `ddd, MMM D ${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  timeTZ(date, tz) {
    return this.getMomentLocalized(date, tz).format(
      `${this.timeFormat} ${tz ? "z" : ""}`
    );
  }

  timezone(tz) {
    return this.getMomentLocalized(moment(), tz).format(`Z z`);
  }

  fromApiDate(date, tz) {
    return this.getMomentLocalized(date, tz).format("YYYY-MM-DD");
  }

  fromApiTime(date, tz) {
    return this.getMomentLocalized(date, tz).format("HH:mm");
  }

  fromApiDateTime(datetime, tz) {
    return this.getMomentLocalized(datetime, tz).format("YYYY-MM-DD HH:mm");
  }

  toApiDateTime(datetime) {
    return `${moment(datetime).utc().format("YYYY-MM-DDTHH:mm")}Z`;
  }

  toApiDate(datetime) {
    return moment(datetime).utc().format("YYYY-MM-DD");
  }

  ///////////////////////////////////////////////////////////////////
  //
  // Other Format Functions
  //
  ///////////////////////////////////////////////////////////////////

  percent(amount) {
    return `${amount * 100}%`;
  }

  currency(amount) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
    });

    return formatter.format(amount / 100);
  }

  tax(tax) {
    if (!tax) throw new Error("Invalid tax object");

    switch (tax.calculationType.id) {
      case 0:
        return `${tax.name} (${(tax.rate * 100).toFixed(2)}%)`;
      case 1:
        return `${tax.name} ($${Number(tax.rate).toFixed(2)})`;
    }
  }

  waterLevel(metricData) {
    if (!metricData) return "--";
    return `${parseFloat(metricData).toFixed(2)}m`;
  }
})();

export default format;

/*
{

    public static function dbDate(DateTime $date) {
        return $date->format('Y-m-d');
    }

    public static function dbDateTime(DateTime $date) {
        return $date->format('Y-m-d H:i:s');
    }

    public static function shortdate($date) {
        return date( 'M d', strtotime($date));
    }

    public static function datetime($date) {
        return date( 'M d, Y g:i A', strtotime($date));
    }


    public static function fulldatetime($date) {
        return date( 'l, M d, Y g:i A', strtotime($date));
    }


    public static function time24($date) {
        return date( 'G:i', strtotime($date));
    }

    public static function currency($amount) {
        return money_format('$%.2n', $amount);
    }    

    
    public static function simoText($simoText){
        // Converts Single Input, Multiple Output text to HTML format
        $htmlText = htmlspecialchars($simoText);
        $htmlText = str_replace(["\r\n", "\r", "\n"], '<br />', $htmlText);

        return $htmlText;
    }

    public static function htmlTextToSimo($htmlText){
        // Converts Single Input, Multiple Output text to HTML format
        $text = htmlspecialchars_decode($htmlText);
        $text = str_replace(["<br />","<br>"], "\n", $text);

        return $text;
    }

    public static function teaserText($str, $len){
        if (strlen($str) > $len)
            $str = substr($str, 0, $len-3)."...";
        return htmlspecialchars($str);
    }
}
*/
