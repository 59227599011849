/********************************************************************
 *
 * /Sales/Orders/Abandoned/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useOutletContext } from "react-router";
import { Box } from "@mui/material";
import Pagination from "../../../lib/Pagination";
import LiveSearch from "../../../lib/LiveSearch";
import { useApp, useAPI } from "../../../../providers/AppProvider";
import Orders from "./Orders";

const PAGE_SIZE = 15;

/**
 * Abandoned
 *
 * Renders a searchable paged list of Orders.
 */
const Abandoned = () => {
  const [orders, setOrders] = useState(null);
  const [orderCount, setOrderCount] = useState(0);
  const [currPage, setCurrPage] = useState(1);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [match, setMatch] = useState("");
  const [loaded, setLoaded] = useState(false);
  const { setTitle } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    setTitle("Abandoned Orders");
  }, []);

  useEffect(() => {
    fetchAbandonedOrders(currPage, pageSize, match);
  }, [currPage, pageSize, match]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * fetchAbandonedOrders
   *
   * Calls the api to fetch abandoned orders.
   *
   * @param {*} currPage
   * @param {*} pageSize
   * @param {*} match
   */
  const fetchAbandonedOrders = (currPage, pageSize, match) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading");

    api
      .fetch(
        `/api/orders/abandoned?match=${match}&page=${currPage}&limit=${pageSize}&verbose`
      )
      .then(({ payload: orders, max: count }) => {
        setOrders(orders);
        setOrderCount(count);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * onSearch
   *
   * Fired when the user enters a search phrase.
   *
   * @param {*} e
   */
  const onSearch = ({ match }) => {
    setCurrPage(1);
    setMatch(match);
  };

  /**
   * Fired when the user changes the current page.
   *
   * @param {*} e
   */
  const onTurnPage = ({ currPage }) => {
    setCurrPage(currPage);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Box>
      {loaded && !orders
        ? "No Orders Available"
        : loaded && (
            <>
              <Box
                display="flex"
                alignItems="baseLine"
                justifyContent="space-between"
                flexWrap="wrap"
                mb={2}
              >
                <LiveSearch match={match} onSearch={onSearch} />
                <Pagination
                  currPage={currPage}
                  totalRecords={orderCount}
                  pageSize={pageSize}
                  pageNeighbours={1}
                  onPageChanged={onTurnPage}
                />
              </Box>
              <Orders orders={orders} />
            </>
          )}
    </Box>
  );
};

export default Abandoned;
