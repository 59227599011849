/********************************************************************
 *
 * Utils.js
 *
 * Utility functions
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 ********************************************************************/

/**
 * IsString
 *
 * Javascript has both string objects and string types. We need to
 * check for both.
 *
 * @param {*} value
 *
 * @returns A boolean reflecting whether the value is a string
 * (object or type)
 */
export const isString = (value) => {
  return typeof value === "string" || value instanceof String;
};
