/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Typography } from "@mui/material";
import format from "../../../../../utils/format";
import AgendaItem from "./Item";

/**
 * Agenda
 *
 * @param {*} props
 */
const Agenda = ({ products, date, tz }) => {
  return (
    <Box>
      <Typography variant="h4" component="p">
        {date ? format.date(date, tz) : "No date selected"}
      </Typography>
      <hr />
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        {products &&
          products.map(
            (product, index) =>
              format.toDateTime(product.start, product.timezone) <=
                date.endOf("day") &&
              format.toDateTime(product.end, product.timezone) >=
                date.startOf("day") && (
                <AgendaItem key={index} product={product} />
              )
          )}
      </Box>
    </Box>
  );
};

export default Agenda;
