/********************************************************************
 *
 * Item.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Link, Box, Card, Typography } from "@mui/material";
import { AddShoppingCart } from "@mui/icons-material";
import { TipChip } from "../../../../lib";
import format from "../../../../../utils/format";

/**
 * AgendaItem
 *
 * @param {*} param0
 *
 * @returns
 */
const AgendaItem = ({ product }) => (
  <Card elevation={0}>
    <Link
      href={`/app/catalog/product/${product.id}`}
      sx={{
        display: "block",
        padding: 2,
        color: "#666666",
        textDecoration: "none",
        "&:hover": {
          backgroundColor: "background.default",
          color: "#666666",
          textDecoration: "none",
        },
      }}
    >
      <Box
        sx={{
          display: "grid",
          gap: 0,
          gridTemplateColumns: "repeat(12, 1fr)",
        }}
      >
        <Box sx={{ gridColumn: { xs: "1/span 8" } }}>
          <Typography variant="body2" component="div">
            {`#${product.id} | ${product.name}`}
          </Typography>
          {format.isSameDay(product.start, product.end, product.tinezone) ? (
            <Typography variant="body3" component="div">
              {format.timeInterval(
                product.start,
                product.end,
                product.timezone
              )}
            </Typography>
          ) : (
            <TipChip label={"multiday"} tip="Multi Day Product" />
          )}
        </Box>
        <Box sx={{ gridColumn: { xs: "9/span 4", justifySelf: "flex-end" } }}>
          <TipChip
            label={product.available}
            icon={<AddShoppingCart />}
            tip="Available"
          />
        </Box>
        <Box sx={{ gridColumn: { xs: "1/span 12" } }}>
          <Typography variant="h6" sx={{ pt: 1 }}>
            Features
          </Typography>
          {Object.keys(product.features).map((key, index) => (
            <Box
              key={index}
              sx={[
                { display: "flex", flexDirection: "row", gap: 0 },
                {
                  borderTop: "solid 1px #cccccc",
                  py: 0,
                },
              ]}
            >
              <Box sx={{ flex: "0 0 100px" }}>
                <Typography variant="caption">
                  {`${format.time(product.features[key].start)} - ${format.time(
                    product.features[key].end
                  )}`}
                </Typography>
              </Box>
              <Box sx={{ flex: "1 1 auto" }}>
                <Typography variant="caption">
                  {`${format.hoursBetween(
                    product.start,
                    product.end,
                    product.timezone
                  )} @ ${product.features[key].name} (${
                    product.features[key].goodId
                  })`}
                </Typography>
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    </Link>
  </Card>
);

export default AgendaItem;
