/********************************************************************
 *
 * Sandbox.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { DateTime } from "luxon";
import { Box, Container, Card } from "@mui/material";
import { DateController, WeeklyCalendar, Timesheet } from "../../lib/Calendars";
//import { useApp } from "../../../providers/AppProvider";

/**
 * Sandbox
 */
const Sandbox = () => {
  //  const app = useApp();
  const [date, setDate] = useState(DateTime.now().setZone("America/Vancouver"));

  useEffect(() => {
    //    app.debug("I'm here");
    //    app.error("This is a warning");
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Timesheet employeeId={1} />
    // <Container maxWidth="md">
    //   <Box sx={{ padding: "20px 0 20px 0" }}>
    //     <DateController
    //       date={date}
    //       onChange={(date) => {
    //         setDate(date);
    //       }}
    //     />
    //   </Box>
    //   <Card sx={{ padding: "10px" }}>
    //     <WeeklyCalendar date={date} />
    //   </Card>
    // </Container>
  );
};

export default Sandbox;
