/********************************************************************
 *
 * Shifts.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2019 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import {
  Box,
  CircularProgress,
  Accordion,
  AccordionSummary,
  Typography,
  Tabs,
  Tab,
} from "@mui/material";
import { DateRangePicker } from "react-dates";
import { START_DATE, END_DATE } from "react-dates/constants";
import moment from "moment-timezone";
import format from "../../../../utils/format";
import { WidgetFrame } from "../../../lib";
import ShiftStatus from "../../../ShiftStatus";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * Shifts
 *
 * Displays an employee's shifts.
 *
 * @param {*} props
 */
const Shifts = ({ employee }) => {
  const [startDate, setStartDate] = useState(moment().startOf("week"));
  const [endDate, setEndDate] = useState(moment().endOf("week"));
  const [shifts, setShifts] = useState(null);
  const [focusedInput, setFocusedInput] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [tab, setTab] = useState(0);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchShifts(employee, startDate, endDate);
  }, [employee, startDate, endDate]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchShifts
   *
   * Request employee shifts for a timeframe
   *
   * @param {*} employee
   * @param {*} startDate
   * @param {*} endDate
   */
  const fetchShifts = (employee, startDate, endDate) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Shifts");

    api
      .fetch(
        `/api/employees/employee/${employee.id}/shifts/${format.toApiDateTime(
          startDate
        )}/${format.toApiDateTime(endDate)}`
      )
      .then(({ payload: shifts }) => {
        setShifts(shifts);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onDatesChange = ({ startDate, endDate }) => {
    setStartDate(startDate);
    setEndDate(endDate);
  };

  const onFocusChange = (focusedInput) => {
    setFocusedInput(focusedInput);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <WidgetFrame title="Shifts">
      <Box
        sx={{ width: "100%", borderBottom: 1, borderColor: "divider", mb: 4 }}
      >
        <Tabs
          value={tab}
          onChange={(event, tab) => {
            setTab(tab);
          }}
        >
          <Tab label="Scheduled" />
          <Tab label="Completed" />
        </Tabs>
      </Box>
      <Box sx={{ mb: 4 }}>
        <DateRangePicker
          startDate={startDate}
          startDateId={START_DATE}
          endDate={endDate}
          endDateId={END_DATE}
          focusedInput={focusedInput}
          onDatesChange={onDatesChange}
          onFocusChange={onFocusChange}
          isOutsideRange={() => false}
          withPortal={true}
        />
      </Box>
      {!loaded ? (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
          <CircularProgress />
        </Box>
      ) : shifts ? (
        shifts.map((shift) => (
          <Accordion key={shift.id}>
            <AccordionSummary>
              <Box sx={{ flexBasis: "33.33%" }}>
                <Typography>{format.dayDate(shift.schedule.start)}</Typography>
                <Typography variant="h4">
                  {shift["@type"].name == "tour"
                    ? `Tour ${shift["@type"].tour.id} ${
                        shift["@type"].tour.asset
                          ? shift["@type"].tour.asset.name
                          : ""
                      }`
                    : "Shift?"}
                </Typography>
              </Box>
              <Box sx={{ flexBasis: "33.33%" }}>
                <Typography variant="h4">
                  {`${format.time(shift.schedule.start)} -
                      ${format.time(shift.schedule.end)}`}
                </Typography>
              </Box>
              <Box sx={{ flexBasis: "33.33%" }}>
                <ShiftStatus status={shift.status} />
              </Box>
            </AccordionSummary>
          </Accordion>
        ))
      ) : (
        <Typography>No Shifts</Typography>
      )}
    </WidgetFrame>
  );
};

export default Shifts;
