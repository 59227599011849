/********************************************************************
 *
 * ShiftStats.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  Table,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * User authentication and authorization component.
 *
 * @param {*} param0
 * @returns
 */
const ShiftStats = ({ employee }) => {
  const [stats, setStats] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (!!employee) fetchStats(employee);
  }, []);

  const fetchStats = (employee) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Fetching shift stats");

    api
      .fetch(`/api/stats/employee/${employee.id}/shift/response`)
      .then(({ payload: stats }) => {
        setStats(stats);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  if (!stats) return;

  return (
    <Card
      sx={{
        "& .MuiCardHeader-subheader": {
          color: !!parseInt(stats.open) ? "red" : "inherit",
        },
      }}
    >
      <CardHeader
        title={`Response Ratio ${parseFloat(
          (parseInt(stats.accepted) * 100) /
            (parseInt(stats.accepted) +
              parseInt(stats.rejected) +
              parseInt(stats.ignored))
        ).toFixed(2)}
                  %`}
        subheader={`Open: ${stats.open}`}
        sx={{ pb: 0 }}
      />
      <CardContent>
        <Table
          size="small"
          sx={{
            width: "100%",
            tableLayout: "fixed",
            "& td": {
              overflow: "hidden",
              whiteSpace: "no-wrap",
              textOverflow: "ellipsis",
              p: 0.5,
            },
            "& .MuiTableCell-root": { borderBottom: 0 },
            "& .title": { opacity: 0.5, width: "30%" },
            "& .data": { opacity: 0.5, width: "20%" },
          }}
        >
          <TableBody>
            <TableRow>
              <TableCell className="title">
                <Typography variant="body3">Accepted</Typography>
              </TableCell>
              <TableCell className="data">
                <Typography variant="body3">{stats.accepted}</Typography>
              </TableCell>
              <TableCell className="title">
                <Typography variant="body3">Ignored</Typography>
              </TableCell>
              <TableCell className="data">
                <Typography variant="body3">{stats.ignored}</Typography>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell className="title">
                <Typography variant="body3">Rejected</Typography>
              </TableCell>
              <TableCell className="data">
                <Typography variant="body3">{stats.rejected}</Typography>
              </TableCell>
              <TableCell className="title" sx={{ fontWeight: 600 }}>
                <Typography variant="body3">Total</Typography>
              </TableCell>
              <TableCell className="data" sx={{ fontWeight: 600 }}>
                <Typography variant="body3">
                  {parseInt(stats.accepted) +
                    parseInt(stats.rejected) +
                    parseInt(stats.ignored)}
                </Typography>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
};

/**
 * PropTypes
 *
 */
ShiftStats.propTypes = {
  employee: PropTypes.object,
};

export default ShiftStats;
