/********************************************************************
 *
 * /components/public/schedule/ShiftCard.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  Box,
  Avatar,
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Typography,
  Button,
} from "@mui/material";
import { Sailing } from "@mui/icons-material";
import format from "../../../utils/format";
import TourSummary from "./TourSummary";

/**
 * ShiftCard
 *
 * @param {*} param0
 */
const ShiftCard = ({ shift, onUpdateShiftStatus }) => {
  return (
    <Card
      data-test-id="SHIFT_CARD"
      sx={{ display: "flex", flexDirection: "column", height: "100%" }}
    >
      <CardHeader
        avatar={
          <Avatar sx={{ bgcolor: "primary.main", fontSize: 12 }}>
            <Sailing />
          </Avatar>
        }
        title={format.dayDateShort(shift.schedule.start)}
        subheader={`${format.time(shift.schedule.start)} -
          ${format.time(shift.schedule.end)}`}
        action={
          <Typography variant="caption" component="span">
            {shift.status === 0
              ? `Sent ${format.shortDate(shift.created)}`
              : shift.status === 1
              ? `Confirmed ${format.shortDate(shift.responded)}`
              : shift.status === 2
              ? `Declined ${format.shortDate(shift.responded)}`
              : ""}
          </Typography>
        }
        sx={{ "& .MuiCardHeader-action": { m: 0 } }}
      />
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gap: 2,
          flex: "1 0",
        }}
      >
        <Box>
          <TourSummary tour={shift["@type"].tour} />
        </Box>
      </CardContent>
      <CardActions
        sx={{
          backgroundColor: "#f6f6f6",
          display: shift.status === 0 ? "inherit" : "none",
          p: 2,
          borderTop: "1px solid #eeeeee",
          alignItems: "flex-end",
        }}
      >
        <Button
          variant="contained"
          onClick={(e) => onUpdateShiftStatus(shift.id, 1)}
          sx={{ px: 2 }}
        >
          Accept
        </Button>
        <Button
          onClick={(e) => onUpdateShiftStatus(shift.id, 2)}
          sx={{ px: 2 }}
        >
          Decline
        </Button>
      </CardActions>
    </Card>
  );
};

export default ShiftCard;
