/********************************************************************
 *
 * Template.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardContent,
  List,
  ListItem,
  ListItemText,
  Typography,
  Box,
  Button,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { useApp, useAPI } from "../../../../providers/AppProvider";

import format from "../../../../utils/format";

/**
 * Template
 *
 * Component that displays summary information about a product
 * template. A template is a proposed configuration of goods that
 * satisifes the requirements of a product type.
 *
 */
const Template = ({ template }) => {
  const [loading, setLoading] = useState(false);
  const [published, setPublished] = useState(false);
  const app = useApp();
  const api = useAPI();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnPublish
   *
   * @param {*} event
   */
  const onPublish = (event) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Publishing");
    setLoading(true);

    api
      .create(`/api/products/product`, { template: event.template })
      .then(() => {
        //        fetchTemplates(productType, start, end);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        setLoading(false);
        app.endNotify(notifyHandle);
      });
  };

  return (
    <Card>
      <CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            <Details template={template} />
            <Features features={template.features} />
          </Box>
          <Box item style={{ flex: "0 0" }}>
            {!template.id && onPublish && (
              <LoadingButton
                variant="contained"
                loading={loading}
                disabled={published}
                size="small"
                onClick={() => {
                  setPublished(true);
                  onPublish({ template: template });
                }}
              >
                {!published ? "Publish" : "Published"}
              </LoadingButton>
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

const Details = ({ template }) => (
  <Box>
    <Typography component="span">{format.dayDate(template.start)}</Typography>
    {!format.isSameDay(template.start, template.end) && (
      <Typography variant="caption" color="error" sx={{ ml: 1 }}>
        * Product spans one or more days
      </Typography>
    )}
    <Typography variant="body2">
      {format.time(template.start)} - {format.time(template.end)}
    </Typography>
  </Box>
);

const Features = ({ features }) => (
  <List dense={true}>
    {Object.keys(features).map((key, index) => (
      <ListItem key={key}>
        <ListItemText>{`${features[key].name} ${
          features[key].good.name
        } (${format.shortDateTime(
          features[key].good.start
        )} - ${format.shortDateTime(features[key].good.end)})`}</ListItemText>
      </ListItem>
    ))}
  </List>
);

/**
 * PropTypes
 */
Template.propTypes = {
  template: PropTypes.object.isRequired,
  onPublish: PropTypes.func,
};

export default Template;
