/********************************************************************
 *
 * TourStats.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { StatCard } from "../../../lib";

import { useApp, useAPI } from "../../../../providers/AppProvider";

/**
 * User authentication and authorization component.
 *
 * @param {*} param0
 * @returns
 */
const TourStats = ({ employee }) => {
  const [stats, setStats] = useState(null);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (!!employee) fetchStats(employee);
  }, []);

  const fetchStats = (employee) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Fetching shift stats");

    api
      .fetch(`/api/stats/employee/${employee.id}/tours/sailing`)
      .then(({ payload: stats }) => {
        setStats(stats);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  if (!stats) return;

  return <StatCard label="Sailing Tours" value={`${stats.count} `} />;
};

/**
 * PropTypes
 *
 */
TourStats.propTypes = {
  employee: PropTypes.object,
};

export default TourStats;
