/********************************************************************
 *
 * /src/components/sales/details/index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { useOutletContext } from "react-router";
import { Box } from "@mui/material";
import NoteEditor from "../../../lib/NoteEditor";
import CustomerCard from "../../../lib/CustomerCard/CustomerCard";
import Charge from "./Charge";
import Register from "./Register";
import Communication from "./Communication";

/**
 * Details
 *
 * Component that displays the information for an order.
 *
 */
const Details = () => {
  const { order, onUpdated, onSave } = useOutletContext();

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnUpdateCustomer
   *
   * Fired when the order's customer is updated.
   *
   * @param {*} event
   */
  const onUpdateCustomer = ({ customer }) => {
    order.customer = customer;
    onSave({ order });
  };

  /**
   * OnUpdateNote
   *
   * Fired when the user leaves the edit text box
   *
   * @param {*} event
   */
  const onUpdateNote = ({ message }) => {
    order.message = message;
    onSave({ order });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  if (!order) return null;

  return (
    <Box
      sx={{
        display: "grid",
        gap: { xs: 2, md: 4 },
        gridTemplateColumns: { xs: "1fr", md: "repeat(3, 1fr)" },
        gridTemplateAreas: {
          xs: '"charges" "register" "customer" "notes"',
          md: '"charges charges extra" "register register extra" "customer customer extra" "notes notes extra"',
        },
      }}
    >
      <Box sx={{ gridArea: "charges" }}>
        {!order.charges && "No items"}
        {order.charges &&
          order.charges.items.map((charge, index) => (
            <Box
              key={charge.id}
              style={{ paddingTop: index === 0 ? 0 : "32px" }}
            >
              <Charge
                key={charge.id}
                order={order}
                charge={charge}
                onUpdated={onUpdated}
              />
            </Box>
          ))}
      </Box>
      <Box sx={{ gridArea: "customer", display: "flex" }}>
        <CustomerCard
          customer={order.customer}
          onCustomerSelect={onUpdateCustomer}
        />
      </Box>
      <Box sx={{ gridArea: "extra" }}>
        <Communication
          order={order}
          onUpdated={() => {
            alert("TBD");
          }}
        />
      </Box>
      <Box sx={{ gridArea: "notes" }}>
        <NoteEditor message={order.message} onSave={onUpdateNote} />
        <small className="text-muted">
          Notes for order (visible by customer)
        </small>
      </Box>
      <Box sx={{ gridArea: "register" }}>
        <Register order={order} onUpdated={onUpdated} />
      </Box>
    </Box>
  );
};

export default Details;
