/********************************************************************
 *
 * Index.jsx
 *
 * Renders a ticket.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useParams } from "react-router";
import { Avatar, Box, Chip } from "@mui/material";
import { Main } from "../../../lib";
import Summary from "./Summary";
import TicketEdit from "../lib/TicketEdit";
import TicketEvents from "./Events";
import { useApp, useAdminGatewayAPI } from "../../../../providers/AppProvider";
import useTicketReducer from "./ticketReducer";
import { PRIORITY_COLOURS, STATUS_CHIPS } from "../lib/iconLib";

/**
 * Index
 *
 * @returns
 */
const Index = () => {
  const [timezone, setTimezone] = useState("America/Vancouver");
  const [showEditTicket, setShowEditTicket] = useState(false);
  const adminGatewayAPI = useAdminGatewayAPI();
  const app = useApp();
  const [state, dispatch, actions] = useTicketReducer();
  const { id } = useParams();

  useEffect(() => {
    fetchTicket(id);
  }, [id]);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Helper methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchTicket
   *
   * @param {*} id
   */
  const fetchTicket = (id) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Ticket");

    adminGatewayAPI
      .fetch(`/api/bridge/tickets/ticket/${id}?verbose`)
      .then(({ payload: ticket }) => {
        dispatch({ type: actions.INITIALIZE_TICKET, payload: ticket });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  return (
    <Main
      header={{
        icon: (
          <Avatar
            alt={
              !!state.ticket
                ? `${state.ticket.assignedTo.fname} ${state.ticket.assignedTo.lname}`
                : undefined
            }
            src={!!state.ticket ? state.ticket.assignedTo.imageUrl : undefined}
          />
        ),
        title: !!state.ticket && state.ticket.name,
        subtitle:
          !!state.ticket &&
          `Assigned to ${state.ticket.assignedTo.fname} ${state.ticket.assignedTo.lname}`,
        callout: {
          label: (
            <Chip
              variant="filled"
              label={`${
                !!state.ticket ? state.ticket.priority.name : "UNKNOWN"
              }`}
              size="small"
              color={
                state.ticket
                  ? PRIORITY_COLOURS[state.ticket.priority.id]
                  : undefined
              }
              sx={{
                fontSize: "0.8em",
                mb: 1,
              }}
            />
          ),
        },
        bonus: !!state.ticket && STATUS_CHIPS[state.ticket.status.id],
      }}
      loaded={state.loaded}
    >
      <Box
        sx={{
          display: "flex",
          gap: 4,
          flexDirection: { xs: "column", md: "row" },
          width: "100%",
        }}
      >
        <Box sx={{ flexBasis: "30%" }}>
          <Summary
            disabled={!state.active}
            ticket={state.ticket}
            timezone={timezone}
            reducer={{ state, dispatch, actions }}
            onEdit={() => {
              setShowEditTicket(true);
            }}
            onUpdate={() => {}}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 4,
            flexBasis: "70%",
          }}
        >
          <TicketEvents
            ticket={state.ticket}
            reducer={{ state, dispatch, actions }}
          />
        </Box>
      </Box>
      <TicketEdit
        ticket={state.ticket}
        open={showEditTicket}
        reducer={{ dispatch, actions }}
        onClose={() => setShowEditTicket(false)}
      />
    </Main>
  );
};

export default Index;
