/********************************************************************
 *
 * Index.jsx
 *
 * Renders a page to manage work orders.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { useNavigate } from "react-router";
import { Engineering } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Main } from "../../../lib";
import Collection from "./Collection";
import WorkOrderEdit from "../lib/WorkOrderEdit";
import useWorkOrdersReducer from "./workOrdersReducer";

/**
 * Home page for work orders.
 *
 * @returns
 */
const Index = () => {
  const [showAddWorkOrder, setShowAddWorkOrder] = useState(false);
  const navigate = useNavigate();
  const [state, dispatch, actions] = useWorkOrdersReducer();

  ///////////////////////////////////////////////////////////////////////
  //
  //  Helper methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      header={{
        icon: <Engineering />,
        title: "Work Orders",
        subtitle: "Getting shit done",
        bonus: (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowAddWorkOrder(true)}
          >
            Create A New Order
          </Button>
        ),
      }}
      loaded={true}
    >
      <Collection reducer={{ state, dispatch, actions }} />
      <WorkOrderEdit
        open={showAddWorkOrder}
        reducer={{ dispatch, actions }}
        onCreated={(workOrder) => {
          navigate(`/app/admin/workorder/${workOrder.id}`);
        }}
        onClose={() => setShowAddWorkOrder(false)}
      />
    </Main>
  );
};

export default Index;
