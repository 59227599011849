/********************************************************************
 *
 * Summary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Button, Box, Typography } from "@mui/material";
import format from "../../../../../utils/format";

/**
 * Content
 */
const Summary = ({ timesheet, startDate }) => {
  return (
    <>
      <Box
        test-data-id="TIMESHEET_SUMMARY"
        sx={{
          display: "grid",
          gap: 0.5,
          gridTemplateColumns: "subgrid",
          gridColumn: "1 / 10",
          gridTemplateRows: "subgrid",
          gridRow: "3 / 14",
          backgroundColor: "#f9f9f9",
          textAlign: "right",
          py: 4,
          px: 2,
          borderRadius: 2,
          border: "1px solid #999999",
        }}
      >
        <Box sx={{ gridArea: "title", textAlign: "center", pb: 4 }}>
          <Typography variant="h4">
            Timesheet for Week {startDate.weekNumber}
          </Typography>
        </Box>
        <Headers />
        <TimeClock timesheet={timesheet} startDate={startDate} />
        <Box
          sx={{
            borderTop: "1px solid #cccccc",
            gridArea: "sp",
            mt: 2,
            pt: 2,
          }}
        ></Box>

        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "subgrid",
            gridColumn: "2 / 9",
            gridTemplateRows: "subgrid",
            gridRow: "2 / 11",
          }}
        >
          {[...Array(7).keys()].map((dateOffset) => {
            const date = timesheet.dates.find((item) =>
              format
                .toDateTime(item.date)
                .hasSame(startDate.plus({ day: dateOffset }), "day")
            );
            return (
              <DayTotals key={dateOffset} date={date} dayOffset={dateOffset} />
            );
          })}
        </Box>
        <Totals timesheet={timesheet} />
        <Box
          sx={{
            borderTop: "1px solid #cccccc",
            gridArea: "approve",
            textAlign: "left",
            mt: 2,
            pt: 3,
          }}
        >
          <Button variant="contained">Approve</Button>
        </Box>
      </Box>
    </>
  );
};

const Headers = () => (
  <Box
    data-test-id="TIMESHEET_JOURNAL_HEADERS"
    sx={{
      display: "grid",
      gridTemplateColumns: "subgrid",
      gridColumn: " 1 / 2",
      gridTemplateRows: "subgrid",
      gridRow: "2 / 11",
    }}
  >
    <Box className="journal header">
      <Typography variant="body3">Scheduled</Typography>
    </Box>
    <Box className="journal header">
      <Typography variant="body3">Worked</Typography>
    </Box>
    <Box></Box>
    <Box></Box>
    <Box className="journal header">
      <Typography variant="body3">Regular</Typography>
    </Box>
    <Box className="journal header">
      <Typography variant="body3">Holiday</Typography>
    </Box>
    <Box className="journal header">
      <Typography variant="body3">1.5 Time</Typography>
    </Box>
    <Box className="journal header">
      <Typography variant="body3">2.0 Time</Typography>
    </Box>
    <Box className="journal header">
      <Typography variant="body3">Day Rate</Typography>
    </Box>
  </Box>
);

const TimeClock = ({ timesheet, startDate }) => {
  return (
    <Box
      test-data-id="TIMESHEET_TIMECLOCK"
      sx={{
        display: "grid",
        gridTemplateColumns: "subgrid",
        gridColumn: "1 / 10",
        gridTemplateRows: "subgrid",
        gridRow: "2 / 5",
      }}
    >
      {[...Array(7).keys()].map((dateOffset) => {
        let dateInfo = timesheet.dates.find((item) =>
          format
            .toDateTime(item.date)
            .hasSame(startDate.plus({ day: dateOffset }), "day")
        );
        if (!dateInfo) dateInfo = {};

        return (
          <Box
            test-data-id="TIMESHEET_TIMECLOCK_DATE"
            key={dateOffset}
            sx={{
              display: "grid",
              gridTemplateColumns: "subgrid",
              gridColumnStart: dateOffset + 2,
              gridColumnEnd: dateOffset + 3,
              gridTemplateRows: "subgrid",
              gridRow: "1 / 4",
            }}
          >
            <Box className="journal">
              <Typography variant="body3">
                {formatDuration(dateInfo.scheduled)}
              </Typography>
            </Box>
            <Box className="journal">
              <Typography variant="body3">
                {formatDuration(dateInfo.worked)}
              </Typography>
            </Box>
            <Box className="journal">
              <Typography
                variant="body3"
                className={
                  !isNaN(dateInfo.worked - dateInfo.scheduled)
                    ? "sum"
                    : undefined
                }
              >
                {formatDuration(dateInfo.worked - dateInfo.scheduled)}
              </Typography>
            </Box>
          </Box>
        );
      })}
      <Box
        test-data-id="TIMESHEET_TIMECLOCK_TOTALS"
        sx={{
          display: "grid",
          gridTemplateColumns: "subgrid",
          gridColumnStart: 9,
          gridColumnEnd: 10,
          gridTemplateRows: "subgrid",
          gridRowStart: 1,
          gridRowEnd: 4,
        }}
      >
        <Box className="journal header">
          <Typography variant="body3">
            {formatDuration(timesheet.scheduled)}
          </Typography>
        </Box>
        <Box className="journal header">
          <Typography variant="body3">
            {formatDuration(timesheet.worked)}
          </Typography>
        </Box>
        <Box className="journal header">
          <Typography variant="body3">
            {formatDuration(timesheet.worked - timesheet.scheduled)}
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const DayTotals = ({ date = {}, dayOffset }) => {
  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "subgrid",
        gridColumnStart: dayOffset + 1,
        gridColumnEnd: dayOffset + 2,
        gridTemplateRows: "subgrid",
        gridRowStart: 5,
        gridRowEnd: 10,
      }}
    >
      <Box className="journal">
        <Typography variant="body3">{formatDuration(date.worked)}</Typography>
      </Box>
      <Box className="journal">
        <Typography variant="body3">{formatDuration(date.holiday)}</Typography>
      </Box>
      <Box className="journal">
        <Typography variant="body3">{formatDuration(date.overtime)}</Typography>
      </Box>
      <Box className="journal">
        <Typography variant="body3">
          {formatDuration(date.doubletime)}
        </Typography>
      </Box>
      <Box className="journal">
        <Typography variant="body3">{formatDuration(date.dayRate)}</Typography>
      </Box>
    </Box>
  );
};

const Totals = ({ timesheet }) => {
  return (
    <Box
      data-test-id="TIMESHEET_JOURNAL_TOTALS"
      sx={{
        display: "grid",
        gridTemplateColumns: "subgrid",
        gridColumnStart: 9,
        gridColumnEnd: 10,
        gridTemplateRows: "subgrid",
        gridRowStart: 6,
        gridRowEnd: 11,
      }}
    >
      <Box className="journal header">
        <Typography variant="body3">
          {formatDuration(timesheet.worked)}
        </Typography>
      </Box>
      <Box className="journal header">
        <Typography variant="body3">
          {formatDuration(timesheet.holiday)}
        </Typography>
      </Box>
      <Box className="journal header">
        <Typography variant="body3">
          {formatDuration(timesheet.overtime)}
        </Typography>
      </Box>
      <Box className="journal header">
        <Typography variant="body3">
          {formatDuration(timesheet.doubletime)}
        </Typography>
      </Box>
      <Box className="journal header">
        <Typography variant="body3">
          {formatDuration(timesheet.dayRate)}
        </Typography>
      </Box>
    </Box>
  );
};

const formatDuration = (value) => {
  if (value == null || isNaN(value)) return "-";

  return Number.parseFloat(value).toFixed(2);
};

export default Summary;
