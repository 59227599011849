/********************************************************************
 *
 * Ownership.jsx
 *
 * Renders a control to manage ownership
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, Button } from "@mui/material";
import { WidgetFrame } from "../../../lib";
import Users from "../lib/Users";
import { useApp, useAdminGatewayAPI } from "../../../../providers/AppProvider";

/**
 * Ownership
 *
 * Renders the ticket summary information
 *
 * @param {*} param0
 * @returns
 */
const Ownership = ({
  ticket,
  disabled,
  reducer: { state, dispatch, actions },
}) => {
  const [userId, setUserId] = useState(null);
  const adminGatewayAPI = useAdminGatewayAPI();
  const app = useApp();

  /**
   * ChangeOwnership
   *
   * @param {*} userId
   */
  const onChangeOwnership = (userId) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Updating Ownership...");

    adminGatewayAPI
      .update(
        `/api/bridge/tickets/ticket/${ticket.id}/assigned/${userId}?verbose`
      )
      .then(({ payload: { ticket, event } }) => {
        dispatch({ type: actions.TICKET_EVENT, payload: { ticket, event } });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  return (
    <WidgetFrame title="Change Ownership">
      <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
        <Users
          value={userId}
          disabled={disabled}
          exclusions={ticket.assignedTo}
          onChange={(event) => {
            setUserId(event.target.value);
          }}
        />
        <Button
          variant="contained"
          disabled={disabled || !userId}
          onClick={() => {
            onChangeOwnership(userId);
          }}
        >
          Assign
        </Button>
      </Box>
    </WidgetFrame>
  );
};

/**
 * PropTypes
 */
Ownership.propTypes = {
  ticket: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  timezone: PropTypes.string,
  reducer: PropTypes.object.isRequired,
};

export default Ownership;
