/********************************************************************
 *
 * /Sales/Orders/Completed/Orders.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  Typography,
  Paper,
  Box,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";
import format from "../../../../utils/format";

/**
 * PaginatedTable
 *
 * @param {*} param0
 */
const PaginatedTable = ({ orders }) => {
  if (!orders) return <Typography variant="h4">No Orders Available</Typography>;

  return (
    <TableContainer component={Paper}>
      <Table size="small" sx={{ width: "100%" }}>
        <TableHead>
          <TableRow sx={{ "& th": { fontWeight: 600 } }}>
            <TableCell sx={{ width: "125px" }}>Booked</TableCell>
            <TableCell sx={{ width: "100px" }}>Order</TableCell>
            <TableCell sx={{ width: "150px" }}>Name</TableCell>
            <TableCell sx={{ width: "150px" }}>Account</TableCell>
            <TableCell sx={{ width: "125px" }}>Confirmation</TableCell>
            <TableCell>Comments</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {orders.map((order) => (
            <TableRow
              key={order.id}
              hover
              onClick={() => {
                window.open(`/app/sales/order/${order.id}`, "_blank");
              }}
              sx={[
                order.deleted ? { textDecoration: "line-through" } : null,
                {
                  "& td, & th": { fontSize: "0.85em" },
                  "&:nth-of-type(odd)": {
                    backgroundColor: "rgba(0,0,0,0.05)",
                  },
                },
              ]}
            >
              <TableCell>{format.date(order.committed)}</TableCell>
              <TableCell component="th" scope="row">
                {order.id}
              </TableCell>
              <TableCell>{order.name}</TableCell>
              <TableCell>
                {order.user
                  ? `${order.customer.fname} ${order.customer.lname}`
                  : "Unknown"}
              </TableCell>
              <TableCell>{order.confirmation}</TableCell>
              <TableCell sx={{ maxWidth: "200px" }}>
                <Box
                  component="span"
                  sx={{
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    display: "inline-block",
                    maxWidth: "100%",
                  }}
                >
                  {order.message}
                </Box>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default PaginatedTable;
