/********************************************************************
 *
 * Index.jsx
 *
 * Renders a page to manage tickets.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import { Engineering } from "@mui/icons-material";
import { Button } from "@mui/material";
import { Main } from "../../../lib";
import Collection from "./Collection";
import TicketEdit from "../lib/TicketEdit";
import { useApp, useAdminGatewayAPI } from "../../../../providers/AppProvider";
import useTicketsReducer from "./ticketsReducer";

/**
 * Home page for tickets.
 *
 * @returns
 */
const Index = () => {
  const [showAddTicket, setShowAddTicket] = useState(false);
  const navigate = useNavigate();
  const adminGatewayAPI = useAdminGatewayAPI();
  const app = useApp();
  const [state, dispatch, actions] = useTicketsReducer();

  useEffect(() => {
    fetchTickets();
  }, []);

  ///////////////////////////////////////////////////////////////////////
  //
  //  Helper methods
  //
  ///////////////////////////////////////////////////////////////////////

  /**
   * FetchTickets
   */
  const fetchTickets = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Tickets");

    adminGatewayAPI
      .fetch(`/api/bridge/tickets/`)
      .then(({ payload: tickets }) => {
        dispatch({ type: actions.INITIALIZE_TICKETS, payload: tickets });
      })
      .catch((error) => {
        app.error({ error });
        dispatch({ type: actions.INITIALIZE_TICKETS, payload: null });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  return (
    <Main
      header={{
        icon: <Engineering />,
        title: "Tickets",
        subtitle: "Lay some tracks",
        bonus: (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setShowAddTicket(true)}
          >
            Create A New Ticket
          </Button>
        ),
      }}
      loaded={state.loaded}
    >
      <Collection tickets={state.data} />
      <TicketEdit
        open={showAddTicket}
        reducer={{ dispatch, actions }}
        onCreated={(ticket) => {
          navigate(`/app/admin/ticket/${ticket.id}`);
        }}
        onClose={() => setShowAddTicket(false)}
      />
    </Main>
  );
};

export default Index;
