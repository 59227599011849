/********************************************************************
 *
 * Schedule.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Typography } from "@mui/material";
import format from "../../../../../utils/format";

/**
 * Schedule
 */
const Schedule = ({ dates, startDate, show = false }) => {
  return (
    <>
      <Box
        data-test-id="TIMESHEET_SHIFTS"
        sx={{
          display: show ? "grid" : "none",
          gap: 1,
          pt: 2,
          pb: 4,
          gridTemplateColumns: "subgrid",
          gridColumnStart: 2,
          gridColumnEnd: 9,
          gridTemplateRows: "subgrid",
          gridRowStart: 2,
          gridRowEnd: 3,
        }}
      >
        {[...Array(7).keys()].map((dateOffset) => {
          const date = dates.find((item) =>
            format
              .toDateTime(item.date)
              .hasSame(startDate.plus({ day: dateOffset }), "day")
          );
          return (
            <ShiftsForDay
              key={dateOffset}
              offset={dateOffset + 1}
              shifts={!!date ? date.shifts : undefined}
            />
          );
        })}
      </Box>
    </>
  );
};

const ShiftsForDay = ({ offset, shifts }) => {
  const time = { scheduled: 0, worked: 0 };

  return (
    <Box className={`cell${offset}`}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
        }}
      >
        {!!shifts &&
          shifts.map((shift) => {
            const scDuration = format
              .toDateTime(shift.schedule.end)
              .diff(format.toDateTime(shift.schedule.start));
            const tcDuration =
              !!shift.timecard &&
              !!shift.timecard.start &&
              !!shift.timecard.end &&
              format
                .toDateTime(shift.timecard.end)
                .diff(format.toDateTime(shift.timecard.start));
            // time.scheduled += scDuration.shiftTo("hours").hours;
            // time.worked += !!tcDuration && tcDuration.shiftTo("hours").hours;
            // time.overtime = CalcOvertime(time.worked);
            // time.doubletime = CalcDoubletime(time.worked);
            switch (shift["@type"].name) {
              case "tour":
                return (
                  <Tour
                    key={shift.id}
                    tour={shift["@type"].tour}
                    scheduled={Number.parseFloat(
                      scDuration.shiftTo("hours").hours
                    ).toFixed(2)}
                    worked={
                      !!tcDuration
                        ? Number.parseFloat(
                            tcDuration.shiftTo("hours").hours
                          ).toFixed(2)
                        : "-"
                    }
                  />
                );
              default:
                return "Shift";
            }
          })}
      </Box>
    </Box>
  );
};

const Tour = ({ tour, scheduled, worked }) => {
  return (
    <Box
      sx={{
        borderRadius: "4px",
        border: "2px solid #999999",
        borderColor: "#66c1b9",
        height: "50px",
        width: "100%",
        p: 0.5,
        m: 0.5,
        "&:hover": {
          cursor: "pointer",
        },
      }}
      onClick={() => {
        window.open(`/app/goods/tour/${tour.id}`, "_blank");
      }}
    >
      <Typography
        variant="body3"
        color="textPrimary"
        sx={{ fontWeight: 400, textAlign: "center" }}
        component="div"
      >
        Tour #{tour.id}
      </Typography>
      <Typography
        variant="body3"
        color="textSecondary"
        sx={{ textAlign: "center" }}
        component="div"
      >
        {scheduled}/{worked}
      </Typography>
    </Box>
  );
};

// const Training = () => ()

// const Maintenance = () => ()

// const Travel = () => ()

// const FullDay = () => ()

export default Schedule;
