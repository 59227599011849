/********************************************************************
 *
 * /lib/Schedule.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useRef } from "react";
import { Box, Typography } from "@mui/material";
import format from "../../../utils/format";
import TimeGrid from "./TimeGrid";

/**
 * Schedule
 *
 * Renders an event schedule
 *
 */
const Schedule = ({
  date,
  tz,
  height = 400,
  onHeaderRender,
  onTimeSlotRender,
}) => {
  const containerRef = useRef(null);

  /**
   * useEffect
   *
   * Set the scroll position to midday
   */
  useEffect(() => {
    containerRef.current.scroll({
      top: 480,
      left: 0,
      behavior: "smooth",
    });
  });

  return (
    <Box data-test-id="SCHEDULE">
      <Box
        sx={{
          display: "flex",
          flecDirection: "row",
          flexWrap: "nowrap",
          alignItems: "center",
          justifyContent: "space-between",
          mb: 2,
        }}
      >
        <Typography>GMT{format.timezone(tz)}</Typography>
        {!!onHeaderRender && <Box>{onHeaderRender()}</Box>}
      </Box>
      <Box
        ref={containerRef}
        sx={{
          height: height,
          overflowY: "scroll",
        }}
      >
        <TimeGrid
          date={date}
          height={height}
          onTimeSlotRender={onTimeSlotRender}
          tz={tz}
        />
      </Box>
    </Box>
  );
};

export default Schedule;
