/********************************************************************
 *
 * ChargeSummary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import format from "../../../../../utils/format";
import ProductTimeDisplay from "./ProductTimeDisplay";

/**
 * ChargeSumary
 *
 * @param {*} param0
 * @returns
 */
const ChargeSummary = ({ charge, onRemoveDiscount }) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ display: "flex" }}>
          <Typography variant="largeChar">{charge.qty}</Typography>
          <Typography variant="smallChar" sx={{ ml: 1 }}>
            @ {format.currency(charge.price)}
          </Typography>
        </Box>
        <Box>
          {charge.product && (
            <ProductTimeDisplay
              start={charge.product.start}
              end={charge.product.end}
            />
          )}
        </Box>
      </Box>
      {false && (
        <Box>
          {charge.rateClass.policies.map((policy) => (
            <Box key={policy.id} sx={{ mb: 2 }}>
              <Typography variant="body2" sx={{ fontWeight: 600 }}>
                {policy.name}
              </Typography>
              <Typography variant="body3">{policy.description}</Typography>
            </Box>
          ))}
        </Box>
      )}
      {charge.discounts && charge.discounts.items && (
        <Box>
          <Typography variant="subtitle1">Discounts</Typography>
          {charge.discounts.items.map((discount) => (
            <Box
              key={discount.id}
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "baseline",
              }}
            >
              <Box sx={{ flex: "1 0 50px" }}>
                <Typography variant="body2">
                  {`${discount.discountType.name} (${discount.description})`}
                </Typography>
              </Box>
              <Box sx={{ flex: "0 0 50px" }}>
                <Typography variant="body2">
                  {format.currency(discount.amount)}{" "}
                </Typography>
              </Box>
              <Box sx={{ flex: "0 0 50px" }}>
                <IconButton
                  onClick={(e) => onRemoveDiscount({ discount })}
                  size="large"
                >
                  <Delete fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};

/**
 * PropTypes
 *
 */
ChargeSummary.propTypes = {
  charge: PropTypes.object.isRequired,
  onRemoveDiscount: PropTypes.func.isRequired,
};

export default ChargeSummary;
