/********************************************************************
 *
 * EmployeeSummary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box, Avatar, Paper, Typography } from "@mui/material";
import { CardContentHeader } from "../../lib";

const status = ["Inactive", "Active"];

/**
 *  EmployeeSummaryHeader
 *
 *  Renders a summary of the employee
 *
 * @param {*} param0
 */
const EmployeeSummaryHeader = ({ employee }) => {
  if (!employee) return "";

  return (
    <CardContentHeader
      avatar={
        <Avatar
          src={employee.imageUrl}
          alt={`${employee.fname} ${employee.lname}`}
          sx={{ height: "75px", width: "75px" }}
        />
      }
      content={
        <Box>
          <Typography variant="h4">
            {employee.fname} {employee.lname}
          </Typography>
          <Typography variant="h6">{employee.role}</Typography>
        </Box>
      }
    />
  );
};

/**
 *  EmployeeSummary
 *
 *  Renders a summary of the employee
 *
 * @param {*} param0
 */
const EmployeeSummary = ({ employee }) => {
  if (!employee) return "";

  return (
    <Paper
      elevation={0}
      sx={{
        padding: 2,
        bgcolor: "rgba(1, 137, 159, 0.05)",
        width: "100%",
      }}
    >
      <Box display="flex" justifyContent="flex-start" gap={0.5}>
        <Box sx={{ fontSize: "0.85em", lineHeight: "2em", fontWeight: 500 }}>
          Email:
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "0.85em",
              lineHeight: "2em",
              color: "text.secondary",
              fontWeight: 300,
            }}
          >
            {employee.email}
          </Typography>
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-start" gap={0.5}>
        <Box sx={{ fontSize: "0.85em", lineHeight: "2em", fontWeight: 500 }}>
          Phone:
        </Box>
        <Box>
          <Typography
            sx={{
              fontSize: "0.85em",
              lineHeight: "2em",
              color: "text.secondary",
              fontWeight: 300,
            }}
          >
            {employee.phone}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );
};

export { EmployeeSummaryHeader, EmployeeSummary };
