/********************************************************************
 *
 * Collection.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { CardList, LinkCard2, CardAction } from "../../../lib";
import ProductTypeHeader from "./ProductTypeHeader";

/**
 * ProductTypeList
 *
 * Renders a list of product type summaries in card format
 *
 * @param {*} props
 */
const Collection = ({ productTypes, onDelete }) => {
  return (
    <CardList
      xs={12}
      md={6}
      lg={4}
      items={productTypes}
      spacing={2}
      onRenderCard={({ item: productType }) => (
        <LinkCard2
          link={`/app/catalog/producttype/${productType.id}`}
          header={<ProductTypeHeader productType={productType} />}
          content={productType.shortDescription}
          actions={
            <>
              <CardAction
                to={`/app/catalog/producttype/${productType.id}/products`}
                label="Book Now"
                highlight
              />
              <CardAction
                to={`/app/catalog/producttype/${productType.id}`}
                label="Edit"
              />
              <CardAction
                to={`/app/catalog/producttype/${productType.id}/products`}
                label="Products"
              />
              <CardAction
                to={`/app/catalog/producttype/${productType.id}/rateclasses`}
                label="Rates"
              />
              <CardAction
                onClick={() => {
                  onDelete(productType);
                }}
                label="Delete"
              />
            </>
          }
        />
      )}
    />
  );
};

/**
 * PropTypes
 */
Collection.propTypes = {
  productTypes: PropTypes.array,
  onDelete: PropTypes.func,
};

export default Collection;
