/********************************************************************
 *
 * TourScheduler.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { useParams, useOutletContext } from "react-router";
import { Box, Badge, Tooltip } from "@mui/material";
import { PersonAddDisabled, NightsStay } from "@mui/icons-material";
import { DateTime } from "luxon";
import Calendar from "../../lib/Calendar/Calendar";
import {
  WaterLevels,
  Schedule,
  ModalDialog,
  DialogContent,
  WidgetFrame,
} from "../../lib";
import Alerts from "../libs/Alerts";
import format from "../../../utils/format";
import TourMiniEdit from "../libs/TourMiniEdit";
import "../assets/scheduler.css";
import { useApp, useAPI } from "../../../providers/AppProvider";

const DEFAULT_CAPACITY = 10;

/**
 * TourScheduler
 *
 * Renders the schedule management component for a tour type.
 *
 * The interface allows the user to navigate a schedule of tours.
 * Using the calendar, a user can select a date and the scheduled
 * tours appear in the agenda. Tours can be added or editted by
 * clicing in the agenda.
 *
 * When a tour is selected, or a new tour is created, a dialog
 * appears with the summary data. The user can manipulate the data
 * in the dialog, or dive into more detailed information in the
 * Tour page.
 *
 * All dates and times are relative to the tour's timezone.
 */
const TourScheduler = () => {
  const [dateRange, setDateRange] = useState(null);
  const [selectedDate, setSelectedDate] = useState(
    DateTime.now().toFormat("yyyy-MM-dd")
  );
  const [tours, setTours] = useState(null);
  const [currentTour, setCurrentTour] = useState(null);
  const [tz] = useState("America/Vancouver");
  const { goodTypeId } = useParams();
  const { tourType } = useOutletContext();
  const app = useApp();
  const api = useAPI();

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchTours
   *
   * Calls the api to fetch tours for the visible dates on the
   * calendar.
   *
   * @param {*} date
   */
  const fetchTours = (goodTypeId, start, end) => {
    //
    //  Validate parameters
    //
    if (!start) throw new Error("Start date not defined");
    if (!end) throw new Error("End date not defined");
    if (end < start) throw new Error("Start date must be before end date");

    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Tours");

    //
    //  Request product types
    //
    api
      .fetch(
        `/api/tourtypes/tourtype/${goodTypeId}/daterange/${format.toISO(
          start.startOf("day")
        )}/${format.toISO(end.endOf("day"))}/?verbose`
      )
      .then(({ payload: tours }) => {
        setTours(tours);
      })
      .catch((error) => {
        app.error({ error });
        setTours(null);
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  /**
   * CreateTour
   *
   * Creates a new tour from a given start date/time.
   *
   * Called when the user clicks on a timeslot. A tour is created
   * based upon the start date/tim and default data.
   *
   * @param {*} startDateTime
   * @param {*} tz
   */
  const createTour = (startDateTime, tz) => {
    const endDateTime = startDateTime.plus({ minutes: 180 });

    return {
      start: format.toISO(startDateTime),
      end: format.toISO(endDateTime),
      acquire: format.toISO(
        startDateTime.minus({
          minutes: process.env.REACT_APP_DEFAULT_TOUR_ASSET_PRETOUR_INTERVAL,
        })
      ),
      release: format.toISO(
        endDateTime.plus({
          minutes: process.env.REACT_APP_DEFAULT_TOUR_ASSET_POSTTOUR_INTERVAL,
        })
      ),
      tz: tz,
      shared: 1,
      capacity: DEFAULT_CAPACITY,
    };
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////
  //
  //  Calendar Component
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnCalendarUpdate
   *
   * Fired when the dates on the calendar change.
   *
   * Fetches tours for the dates visible on the calendar.
   *
   * @param {*} interval
   */
  const onCalendarUpdate = (interval) => {
    setDateRange(interval);

    if (interval && interval.startDate && interval.endDate)
      fetchTours(goodTypeId, interval.startDate, interval.endDate);
  };

  /**
   * OnDateRender
   *
   * Fired when a calendar date renders.
   *
   * Determines if there are tours on the specified date and
   * marks up the calendar accordingly.
   *
   * @param {*} event
   */
  const onDateRender = (event) => {
    let daysTours = [];

    if (tours) {
      tours.forEach((tour) => {
        if (format.toDateTime(tour.start, tour.tz).hasSame(event.date, "day"))
          daysTours.push(tour);
      });
    }

    return (
      <Box
        data-test-id="TOUR_CALENDAR_DAY_INNER"
        sx={[
          { padding: "0.2em" },
          daysTours.length > 0
            ? { height: "100%", backgroundColor: "rgba(39,159,159, 0.2)" }
            : null,
        ]}
      >
        <Box>{format.calendarDay(event.date)}</Box>
        <Box sx={{ fontSize: "0.8em" }}>
          {daysTours.map((tour) => (
            <Box key={tour.id} display="flex" justifyContent="space-between">
              <Badge
                sx={{
                  "&:badge": {
                    fontSize: "0.5rem",
                    height: "10px",
                    padding: "0 2px",
                    minWidth: "10px",
                    right: -6,
                    top: 2,
                  },
                }}
                color="primary"
                badgeContent={tour.shifts && tour.shifts.length}
              >
                {`${format.time(tour.start, tour.tz)}`}
                {!format.isSameDay(tour.start, tour.end, tour.tz) && (
                  <>
                    <Tooltip title="Multi day trip" arrow>
                      <NightsStay
                        style={{ marginLeft: "2px", fontSize: "0.65rem" }}
                      />
                    </Tooltip>
                  </>
                )}
              </Badge>
              <Box
                sx={{
                  textDecoration:
                    tour.shared == false || tour.pax == tour.capacity
                      ? "line-through"
                      : "inherit",
                }}
              >
                {` ${tour.pax}/${tour.capacity}`}
              </Box>
            </Box>
          ))}
        </Box>
      </Box>
    );
  };

  /**
   * OnSelectedDateChanged
   *
   * Fired when the user clicks a date cell in the calendar.
   *
   * @param {event} event
   */
  const onSelectedDateChanged = ({ date }) => {
    setSelectedDate(date);
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Schedule Component
  //
  ///////////////////////////////////////////////////////////////////

  /**
   *  OnHeaderRender
   *
   *  Renders alerts for the day. Alerts are stored as all-day events
   *  in the Canadian Coastal Google calendar.
   *
   * @param {*} event
   */
  const onHeaderRender = () => {
    return <Alerts date={selectedDate} />;
  };

  /**
   * OnTimeSlotRender
   *
   * Fired by the Schedule component when a time slot is being
   * rendered.
   *
   * @param {*} event
   */
  const onTimeSlotRender = (event) => (
    <Box className="timeSlotContainer" onClick={() => onClickTimeSlot(event)}>
      {tours &&
        tours.map((tour) =>
          format
            .toDateTime(tour.start, tour.tz)
            .hasSame(event.dateTime, "day") &&
          format.toDateTime(tour.start, tour.tz).hour ===
            event.dateTime.hour ? (
            <Box
              key={tour.id}
              className="timeSlotTour"
              onClick={(event) => {
                onClickTour({ tour: tour });
                event.stopPropagation();
              }}
            >
              <Box className="time">
                {format.timeInterval(tour.start, tour.end, tour.tz)}
              </Box>
              <Box className="details">
                <Box>{`${tour.pax} / ${tour.capacity}`}</Box>
                <Box>
                  {tour.shared == false && (
                    <PersonAddDisabled
                      sx={{
                        marginTop: "-3px",
                        fontSize: "14px",
                      }}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          ) : (
            ""
          )
        )}
    </Box>
  );

  /**
   * OnClickTimeSlot
   *
   * Fired when a user clicks a timeslot in the schedule. Creates the framework of a tour.
   *
   * @param {*} event
   */
  const onClickTimeSlot = (event) => {
    setCurrentTour(createTour(event.dateTime, event.tz));
  };

  /**
   * OnClickTour
   *
   * @param {*} event
   */
  const onClickTour = (event) => {
    setCurrentTour(event.tour);
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Tour Dialog Component
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * OnDialogClose
   *
   * Called as a callback from the TourMiniEdit component.
   *
   * Nullifies the currentTour (which closes the dialog), and
   * refreshes the tours. The refresh will display any new tour added
   * by the dialog.
   *
   * @param {*} e
   */
  const onDialogClose = (e) => {
    setCurrentTour(null);

    if (dateRange && dateRange.startDate && dateRange.endDate)
      fetchTours(goodTypeId, dateRange.startDate, dateRange.endDate);
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////

  return (
    <Box data-test-id="TOUR_SCHEDULER">
      <Box
        sx={{
          display: "grid",
          gap: 4,
          gridTemplateColumns: "repeat(12, 1fr)",
          gridAutoRows: "1fr",
        }}
      >
        <Box sx={{ gridColumn: "1/span 6" }}>
          <WidgetFrame>
            <Calendar
              date={undefined}
              onCalendarUpdating={onCalendarUpdate}
              onDateRender={onDateRender}
              onSelectedDateChanged={onSelectedDateChanged}
            />
          </WidgetFrame>
        </Box>
        <Box sx={{ gridColumn: "7/span 6" }}>
          <WidgetFrame
            title={`${format.day(selectedDate, tz)} ${format.shortDate(
              selectedDate,
              tz
            )} `}
          >
            <Schedule
              date={selectedDate}
              tz={tz}
              onHeaderRender={onHeaderRender}
              onTimeSlotRender={onTimeSlotRender}
            />
          </WidgetFrame>
        </Box>
        <Box sx={{ gridColumn: "1/span 12" }}>
          <WaterLevels
            date={selectedDate}
            showDate={false}
            tz={tz}
            departureTime={undefined}
            returnTime={undefined}
          />
        </Box>
      </Box>
      <ModalDialog
        title="Tour Editor"
        subtitle={`Create and edit a ${tourType.name}`}
        show={!!currentTour}
      >
        <DialogContent>
          <TourMiniEdit
            tourType={tourType}
            tour={currentTour}
            onSaved={onDialogClose}
            onCancelled={onDialogClose}
          />
        </DialogContent>
      </ModalDialog>
    </Box>
  );
};

export default TourScheduler;
