/********************************************************************
 *
 * PartnerSummary.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import { Box, Chip, Typography } from "@mui/material";
import React from "react";

/**
 * PartnerSummary
 *
 * Renders partner information.
 */
const PartnerSummary = ({ partner }) => {
  return (
    <Box sx={{ display: "flex", justifyContent: "space-between", mb: 2 }}>
      <Box
        sx={{
          display: "flex",
          flexDirection: "colimn",
          justifyContent: "space-between",
        }}
      >
        <Box sx={{ display: !!partner.deleted ? "block" : "none" }}>
          <Chip>Deleted</Chip>
        </Box>
        <Box>
          <Typography variant="h4">{partner.name}</Typography>
          <Typography variant="body2">{partner.website}</Typography>
        </Box>
      </Box>
      <Box>
        {!!partner.logoURL && (
          <Box src={partner.logoURL} alt={partner.name} component="img" />
        )}
      </Box>
    </Box>
  );
};

export default PartnerSummary;
