/********************************************************************
 *
 * TicketTypes.jsx
 *
 * Renders a control to select a ticket type. 

* @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, MenuItem } from "@mui/material";
import { useApp, useAdminGatewayAPI } from "../../../../providers/AppProvider";

/**
 * TicketTypes
 *
 * @param {*} param0
 */
const TicketTypes = ({
  name,
  label = "Types",
  value = "",
  disabled,
  onChange,
  onBlur,
  error = false,
  helperText,
  sx,
}) => {
  const [loaded, setLoaded] = useState(false);
  const [ticketTypes, setTicketTypes] = useState(null);
  const adminGatewayAPI = useAdminGatewayAPI();
  const app = useApp();

  useEffect(() => {
    fetchTicketTypes();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchTicketTypes
   */
  const fetchTicketTypes = () => {
    adminGatewayAPI
      .fetch(`/api/bridge/tickets/ticket/types`)
      .then(({ payload: ticketTypes }) => {
        setTicketTypes(ticketTypes);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  return (
    <TextField
      name={name}
      label={label}
      select
      value={value != null ? value : ""}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      sx={[{ width: "100%" }, sx]}
    >
      {!ticketTypes ? (
        <MenuItem>Loading...</MenuItem>
      ) : (
        ticketTypes.map((ticketType) => (
          <MenuItem key={ticketType.id} value={ticketType.id}>
            {ticketType.name}
          </MenuItem>
        ))
      )}
    </TextField>
  );
};

/**
 * PropTypes
 */
TicketTypes.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sx: PropTypes.object,
};

export default TicketTypes;
