/********************************************************************
 *
 * ItemCategories.jsx
 *
 * Renders a hierarchal list of categories that catalog work items.
 * Items can be queried based upon these categories to see past work
 * completed and upcoming work.
 *
 * NB: Although the category tree can have infinite branches, only
 * the first two levels are displayed in the control.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { TextField, MenuItem } from "@mui/material";
import { useApp, useAdminGatewayAPI } from "../../../../providers/AppProvider";

/**
 * ItemCategories
 *
 * @param {*} param0
 */
const ItemCategories = ({
  name,
  label = "Category",
  value = "",
  disabled,
  onChange,
  onBlur,
  error = false,
  helperText,
  sx,
}) => {
  const [loaded, setLoaded] = useState(true);
  const [categories, setCategories] = useState(null);
  const adminGatewayAPI = useAdminGatewayAPI();
  const app = useApp();

  useEffect(() => {
    fetchItemCategories();
  }, []);

  ///////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  ///////////////////////////////////////////////////////////////////

  /**
   * FetchItemCategories
   */
  const fetchItemCategories = () => {
    adminGatewayAPI
      .fetch(`/api/bridge/workorders/workorder/items/categories`)
      .then(({ payload: categories }) => {
        setCategories(categories);
        setLoaded(true);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  return (
    <TextField
      name={name}
      label={label}
      select
      value={value != null ? value : ""}
      disabled={disabled}
      onChange={onChange}
      onBlur={onBlur}
      error={error}
      helperText={helperText}
      sx={[{ "&::before": { borderBottom: "none" }, width: "100%" }, sx]}
    >
      {!categories ? (
        <MenuItem>Loading...</MenuItem>
      ) : (
        categories.map((categoryLevel1) => [
          <MenuItem
            key={categoryLevel1.id}
            value={categoryLevel1.id}
            sx={{ fontWeight: 600 }}
          >
            {categoryLevel1.name}
          </MenuItem>,
          categoryLevel1.children &&
            categoryLevel1.children.map((categoryLevel2) => (
              <MenuItem
                key={categoryLevel2.id}
                value={categoryLevel2.id}
                sx={{ ml: 2 }}
              >
                {categoryLevel2.name}
              </MenuItem>
            )),
        ])
      )}
    </TextField>
  );
};

/**
 * PropTypes
 */
ItemCategories.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  disabled: PropTypes.bool,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  sx: PropTypes.object,
};

export default ItemCategories;
