/********************************************************************
 *
 * Day.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import { Box } from "@mui/material";
import { DateTime } from "luxon";
import format from "../../../utils/format";

/**
 * Day
 *
 * Renders a day on the calendar grid. A day has continaing DIV and
 * inner DIV.
 *
 */
const Day = ({ date, selectedDate, onDateRender, onClickDate }) => {
  if (!date) return null;

  return (
    <Box
      sx={{
        width: "14.2857% !important",
        display: "inline-block !important",
        position: "relative !important",
        fontSize: "0.9em",
        borderWidth: "0.5px !important",
        borderStyle: "solid !important",
        borderColor:
          "transparent transparent transparent rgb(235, 235, 235) !important",
        borderImage: "initial !important",
        cursor: "pointer",
      }}
    >
      <Box
        data-test-id="CALENDAR_DAY"
        onClick={() => {
          onClickDate({ date: date.toFormat("yyyy-MM-dd") });
        }}
        sx={[
          {
            position: "absolute !important",
            backgroundColor: "rgb(255, 255, 255)",
            top: "0px !important",
            bottom: "0px !important",
            left: "0px !important",
            right: "0px !important",
            padding: "0em !important",
            "&:hover": {
              backgroundColor: "#eeeeee",
            },
          },
          date.hasSame(selectedDate, "day") && {
            border: "1px solid #009900",
          },
          date.endOf("day") < DateTime.now().startOf("day") && {
            backgroundColor: "#f8f8f8",
          },
        ]}
      >
        {!!onDateRender ? onDateRender({ date }) : format.calendarDay(date)}
      </Box>
    </Box>
  );
};

export default Day;
