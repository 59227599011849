/********************************************************************
 *
 * /Sales/Orders/Orders.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState } from "react";
import { Outlet } from "react-router";
import { Receipt } from "@mui/icons-material";
import { Main } from "../../lib";
import {
  SectionList,
  SectionItemGroup,
  SectionItem,
} from "../../lib/SideBar/Menu";

/**
 * Orders
 *
 * Container component for managing orders
 *
 */
const Orders = () => {
  const [loaded, setLoaded] = useState(true);
  const [title, setTitle] = useState("Loading...");

  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  return (
    <Main
      menu={{
        title: "Order Management",
        subtitle: "Review All Orders",
        nav: <Menu />,
      }}
      header={{
        icon: <Receipt />,
        title: title,
        subtitle: "",
        bonus: "",
      }}
      loaded={loaded}
    >
      <Outlet context={{ setTitle }} />
    </Main>
  );
};

/**
 * Renders the navigation controls for the functional areas of the
 * order management page.
 *
 * @param {*} props
 */
const Menu = ({ order }) => {
  const { id = 0 } = order || {};

  return (
    <SectionList id="sidebar-nav">
      <SectionItemGroup icon="" name="Order Types" open={true}>
        <SectionItem to={`/app/sales/orders/sales`}>Completed</SectionItem>
        <SectionItem to={`/app/sales/orders/abandoned`}>Abandoned</SectionItem>
      </SectionItemGroup>
    </SectionList>
  );
};

export default Orders;
