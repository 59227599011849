/********************************************************************
 *
 * src/hooks/selectedDate.js
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2022 David B. Crewson. All rights reserved.
 *
 *******************************************************************/

import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useLocation } from "react-router";
import { DateTime } from "luxon";
import format from "../utils/format";

const QSKEY = "date";

/**
 * UseSelectedDate
 *
 * Hook to manage the state of the currently selected date.
 *
 * @returns
 */
const useSelectedDate = (fallback, min, max, tz) => {
  const location = useLocation();
  const search = new URLSearchParams(location.search);
  const [selectedDate, _setSelectedDate] = useState(() => {
    //
    //  NB: Initializing the selected date must occur in the state hook
    //  initializer to avoid rerendering the calendar and a potentially
    //  expensive and unnecessary call to fetch availabilty.
    //
    //  Attempt to fetch date from query string
    //
    const qsDate = search.get(QSKEY);
    let stateDate =
      !!qsDate &&
      DateTime.fromFormat(qsDate, "yyyy-MM-dd", {
        zone: tz,
      });

    //
    //  If date exists, verify it is within bounds, and return closest valid date
    //
    if (!!stateDate && stateDate.isValid)
      return DateTime.min(DateTime.max(min, stateDate), max);

    //
    //  Selected date does not exist in query string
    //
    return format.toDateTime(fallback);
  });

  /**
   * Update Query String State
   *
   * We are using the querystring to persist selected dates in the URL.
   * When the selectedDate changes, update the query string.
   *
   */
  useEffect(() => {
    //
    //  Set or remove the query string value
    //
    if (DateTime.isDateTime(selectedDate) && selectedDate.isValid)
      search.set(QSKEY, selectedDate.toFormat("yyyy-MM-dd"));
    else search.delete(QSKEY);

    if (window.history.pushState) {
      let qs = search.toString();
      window.history.pushState(
        null,
        "",
        `${location.pathname}${!!qs ? "?" + qs : ""}`
      );
    }
  }, [selectedDate]);

  /**
   * Validate
   *
   * Validates the parameter as a valid DateTime within the min/max boundaries
   *
   * @param {DateTime} date
   *
   * @returns
   */
  const validate = (date) => {
    //
    //  If parameter is invalid, then return null
    //
    if (!DateTime.isDateTime(date) || !date.isValid) return null;

    //
    //  If parameter is outside bounds, return closest valid date
    //
    return DateTime.min(DateTime.max(min, date), max);
  };

  /**
   * Setter
   *
   * Wrapper setter. Ensure validity of incoming date
   *
   * @param {*} date
   */
  const setSelectedDate = (date) => {
    //
    //  Ensure that any valid date is between the min and max dates.
    //  Will default to the min date if value does not exist.
    //
    date = validate(date);

    //
    //  Update selected date if it has changed. Note that new date can be null.
    //
    _setSelectedDate((prevDate) => {
      if (
        DateTime.isDateTime(prevDate) &&
        DateTime.isDateTime(date) &&
        prevDate.hasSame(date, "day")
      )
        return prevDate;

      return date;
    });
  };

  return [selectedDate, setSelectedDate];
};

/*
 **  PropTypes
 */
useSelectedDate.propTypes = {
  min: PropTypes.instanceOf(DateTime),
  max: PropTypes.instanceOf(DateTime),
  tz: PropTypes.string.isRequired,
};

export default useSelectedDate;
