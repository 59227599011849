/********************************************************************
 *
 * Index.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, CircularProgress, Typography, Button } from "@mui/material";
import { WidgetFrame } from "../../lib";
import Pagination from "../../lib/Pagination";
import { useApp, useAPI } from "../../../providers/AppProvider";
import Lead from "./Lead";

const PAGE_SIZE = 6;

/**
 * Leads
 *
 * Displays a paged widge of leads
 */
const Leads = ({ pageSize: extPageSize }) => {
  const [items, setItems] = useState(null);
  const [itemCount, setItemCount] = useState(null);
  const [pageSize, setPageSize] = useState(PAGE_SIZE);
  const [currPage, setCurrPage] = useState(1);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    if (extPageSize) setPageSize(extPageSize);
  }, [extPageSize]);

  useEffect(() => {
    fetchLeads(currPage, pageSize);
  }, [currPage, pageSize]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * FetchLeads
   *
   * Calls the api to fetch recent searches
   *
   * @param currPage
   * @param pageSize
   *
   */
  const fetchLeads = (currPage, pageSize) => {
    const notifyHandle = app.notify("Loading Leads");

    //
    //  Clear the loading flag
    //
    setLoaded(false);

    //
    //  Request searches
    //
    api
      .fetch(`/api/leads/?page=${currPage}&limit=${pageSize}`)
      .then(({ payload: items, max }) => {
        console.log(items);
        setItems(items);
        setItemCount(max);
        setLoaded(true);
        app.endNotify(notifyHandle);
      })
      .catch((error) => {
        app.error({ error });
        setLoaded(true);
      });
  };

  ///////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////

  const onTurnPage = ({ currPage }) => {
    setCurrPage(currPage);
  };

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!loaded)
    return (
      <Box display="flex" justifyContent="center">
        <CircularProgress />
      </Box>
    );

  if (!items) return <Typography>Leads not available</Typography>;

  return (
    <WidgetFrame
      title={`${itemCount} Active Leads`}
      action={
        <Button
          href="/app/sales/leads/lead/"
          variant="outlined"
          color="primary"
        >
          New Lead
        </Button>
      }
    >
      <>
        {items.map((item) => (
          <Lead key={item.id} lead={item} />
        ))}
        <br />
        <Pagination
          className={"small"}
          currPage={currPage}
          totalRecords={itemCount}
          pageSize={pageSize}
          pageNeighbours={1}
          onPageChanged={onTurnPage}
        />
      </>
    </WidgetFrame>
  );
};

export default Leads;
