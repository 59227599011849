/********************************************************************
 *
 * LinkCard.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import {
  Card,
  CardHeader,
  CardContent,
  CardActionArea,
  CardActions,
} from "@mui/material";

const LinkCard = ({
  title,
  header,
  link,
  onClick,
  content,
  actions,
  elevation,
  sx,
}) => (
  <Card
    elevation={elevation}
    sx={[
      {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        flex: 1,
        padding: 1.5,
        border: "1px solid rgba(122,123,151,.3)",
        height: "100%",
      },
      sx,
    ]}
  >
    {!!title && <CardHeader title={title} />}
    {!!link || !!onClick ? (
      <CardActionArea
        href={link}
        onClick={onClick}
        sx={{
          display: "block",
          "&:hover": {
            backgroundColor: "background.default",
            color: "#666666",
            textDecoration: "none",
          },
        }}
      >
        <CardContent>
          {header}
          {content}
        </CardContent>
      </CardActionArea>
    ) : (
      <CardContent>
        {header}
        {content}
      </CardContent>
    )}
    {actions && (
      <CardActions
        sx={{
          fontSize: "0.85em",
          borderTop: "solid 1px #eeeeee",
          padding: "0.5em 0.5em 0em 0.5em",
        }}
      >
        {actions}
      </CardActions>
    )}
  </Card>
);

/**
 * PropTypes
 */
LinkCard.propTypes = {
  header: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  link: PropTypes.string,
  content: PropTypes.node,
  actions: PropTypes.object,
  elevtion: PropTypes.number,
  onClick: PropTypes.func,
};

export default LinkCard;
