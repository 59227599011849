/********************************************************************
 *
 * TimezoneField.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2025 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useEffect, useState } from "react";
import { Box, MenuItem, TextField } from "@mui/material";
import { useApp, useAPI } from "../../../../../providers/AppProvider";

const TimeZoneField = ({
  id,
  label = "Time Zone",
  value,
  onBlur,
  onChange,
  error,
  helperText,
}) => {
  const [timeZones, setTimeZones] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const app = useApp();
  const api = useAPI();

  useEffect(() => {
    fetchTimezones();
  }, []);

  const fetchTimezones = () => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Timezones");

    api
      .fetch(`/api/timezones`)
      .then(({ payload: timeZones }) => {
        setTimeZones(timeZones);
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        setLoaded(true);
        app.endNotify(notifyHandle);
      });
  };

  return (
    <TextField
      id={id}
      label={label}
      select
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      fullWidth
      error={error}
      helperText={helperText}
    >
      {!timeZones ? (
        <MenuItem>Loading...</MenuItem>
      ) : (
        timeZones.map((timeZone) => (
          <MenuItem key={timeZone.id} value={timeZone.id}>
            {timeZone.name}
          </MenuItem>
        ))
      )}
    </TextField>
  );
};

export default TimeZoneField;
