/********************************************************************
 *
 * /src/components/catalog/products/rates/Rates.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2020 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  IconButton,
  ButtonGroup,
} from "@mui/material";
import format from "../../../../utils/format";
import { Edit, DeleteForever } from "@mui/icons-material";

/**
 * Rates
 *
 * Renders the rates for a product type's rate class.
 */
const Rates = ({
  rateClass,
  rates,
  readonly,
  highlightRate,
  onEdit,
  onDelete,
}) => {
  ///////////////////////////////////////////////////////////////////////
  //
  //  Utility Methods
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Event Handlers
  //
  ///////////////////////////////////////////////////////////////////////

  ///////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  ///////////////////////////////////////////////////////////////////////

  if (!rates) return null;

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Rate</TableCell>
          <TableCell>Applied</TableCell>
          <TableCell>Open</TableCell>
          <TableCell />
        </TableRow>
      </TableHead>
      <TableBody>
        {rates.map((rate) => (
          <TableRow
            key={rate.id}
            className={`${
              highlightRate && highlightRate.id === rate.id ? "table-info" : ""
            }`}
          >
            <TableCell>{format.currency(rate.amount)}</TableCell>
            <TableCell>{`${format.date(rate.start)} - ${format.date(
              rate.end
            )}`}</TableCell>
            <TableCell>{`${format.date(rate.open)} - ${format.date(
              rate.close
            )}`}</TableCell>
            <TableCell>
              <ButtonGroup>
                <IconButton
                  sx={{ display: readonly ? "none" : "inherit" }}
                  onClick={() => onDelete({ rateClass, rate })}
                >
                  <DeleteForever />
                </IconButton>
                <IconButton
                  sx={{ display: readonly ? "none" : "inherit" }}
                  onClick={() => onEdit({ rateClass, rate })}
                >
                  <Edit />
                </IconButton>
              </ButtonGroup>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default Rates;
