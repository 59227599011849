/********************************************************************
 *
 * WorkOrderNotes.jsx
 *
 * Renders a control to manage the work item notes. Uses the reducer
 * pattern, so the host needs to pass a dispatch function and actions
 * objects, and handle the CREATE_NOTE and DELETE_NOTE actions.
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React from "react";
import PropTypes from "prop-types";
import { Box } from "@mui/material";
import { WidgetFrame, PostEditor } from "../../../../lib";
import {
  useApp,
  useAdminGatewayAPI,
} from "../../../../../providers/AppProvider";
import { useUser } from "../../../../../providers";

/**
 * WorkOrderNotes
 *
 * @param {*} param0
 *
 * @returns
 */
const WorkOrderNotes = ({ workOrder, reducer: { dispatch, actions } }) => {
  const app = useApp();
  const adminGatewayAPI = useAdminGatewayAPI();
  const user = useUser();

  /**
   * AddNote
   *
   * @param {*} post
   */
  const addNote = (post) => {
    //
    //  Status message
    //
    const notifyHandle = app.notify("Loading Work Order Notes");

    adminGatewayAPI
      .create(`/api/bridge/workorders/workorder/${workOrder.id}/notes/note`, {
        message: post.message,
        user,
      })
      .then(({ payload: workOrderNote }) => {
        dispatch({ type: actions.CREATE_NOTE, payload: workOrderNote });
      })
      .catch((error) => {
        app.error({ error });
      })
      .finally(() => {
        app.endNotify(notifyHandle);
      });
  };

  /**
   * DeleteNote
   *
   * @param {*} post
   */
  const deleteNote = (note) => {
    adminGatewayAPI
      .delete(
        `/api/bridge/workorders/workorder/${workOrder.id}/notes/note/${note.id}`
      )
      .then(() => {
        dispatch({ type: actions.DELETE_NOTE, payload: note });
      })
      .catch((error) => {
        app.error({ error });
      });
  };

  if (!workOrder) return "WorkOrder cannot be null";

  return (
    <WidgetFrame title="Notes">
      {workOrder.notes &&
        workOrder.notes.map((note) => (
          <Box
            key={note.id}
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: "100%",
              pb: 2,
            }}
          >
            <PostEditor
              id={note.id}
              value={note.message}
              author={`${note.user.fname} ${note.user.lname}`}
              date={note.timestamp}
              onPost={addNote}
              onDelete={() => {
                deleteNote(note);
              }}
            />
          </Box>
        ))}
      <Box sx={{ pt: 2 }}>
        <PostEditor edit onPost={addNote} />
      </Box>
    </WidgetFrame>
  );
};

/**
 * PropTypes
 */
WorkOrderNotes.propTypes = {
  workOrder: PropTypes.object.isRequired,
  reducer: PropTypes.object.isRequired,
};

export default WorkOrderNotes;
