/********************************************************************
 *
 * Calendar.jsx
 *
 * @author David Crewson <david.crewson@gmail.com>
 *
 * @copyright 2024 Canadian Coastal Inc. All rights reserved.
 *
 *******************************************************************/

import React, { useState, useEffect } from "react";
import { Box, Button } from "@mui/material";
import { DateTime } from "luxon";
import Month from "./Month";
import format from "../../../utils/format";
import useSelectedDate from "../../../hooks/selectedDate";

const NUM_WEEKS = 6;

/**
 * Calendar
 *
 * A calendar that has client rendered dates.
 *
 */
const Calendar = ({
  date,
  onSelectedDateChanged,
  onDateRender,
  onCalendarUpdating,
  tz = "America/Vancouver",
}) => {
  const [startDate, setStartDate] = useState(() => {
    const _date = format.toDateTime(date, tz);
    return !!_date && _date.isValid ? _date : null;
  });
  const [selectedDate, setSelectedDate] = useSelectedDate(
    DateTime.now(),
    DateTime.fromFormat("1970-01-01", "yyyy-MM-dd"),
    DateTime.fromFormat("2050-01-01", "yyyy-MM-dd"),
    tz
  );

  /**
   * UseEffect
   *
   * Fired when the 'date' prop changed
   */
  useEffect(() => {
    let _date;

    //
    //  If date parameter is null, then do not initialize _date - it will
    //  default to today's date.
    //
    if (date === null || date === undefined) return;

    _date = format.toDateTime(date, tz);

    //
    //  Initialize selected date if one provided
    //
    if (!!_date && _date.isValid && !_date.hasSame(selectedDate, "day"))
      setSelectedDate(_date);
  }, [date]);

  /**
   * UseEffect
   *
   * Fired when SelectedDate changed
   */
  useEffect(() => {
    let boundaryDates;

    //
    //  Fire selected date change event handler
    //
    if (!!onSelectedDateChanged) onSelectedDateChanged({ date: selectedDate });

    //
    //  Compute calendar start/end dates
    //
    boundaryDates = computeBoundaryDates(selectedDate);

    //
    //  If the selected date is outside if the current month, redraw the calendar
    //
    if (boundaryDates) {
      setStartDate(boundaryDates.startDate);

      //
      //  Fire calendar updating event
      //
      if (onCalendarUpdating) onCalendarUpdating(boundaryDates);
    }
  }, [selectedDate]);

  /////////////////////////////////////////////////////////////////////
  //
  //  Utility methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * ComputeBoundaryDates
   *
   * Given a date, return an appropriate starting date for the
   * calendar.
   *
   * The result is returned as a DateTime object.
   *
   * @param {date} date
   *
   * @returns {DateTime}
   */
  const computeBoundaryDates = (date) => {
    let newStartDate;

    //
    //  Evaluate calendar start date. Note that Luxon uses Monday as
    //  the start of week, so we need to subtract ab=nother day for
    //  North American calendar format.
    //
    newStartDate = date
      .startOf("month")
      .startOf("week")
      .minus({ days: 1 })
      .startOf("day");

    if (!(startDate && startDate.hasSame(newStartDate, "day")))
      return {
        startDate: newStartDate,
        endDate: newStartDate.plus({ days: NUM_WEEKS * 7 }),
      };

    return null;
  };

  /////////////////////////////////////////////////////////////////////
  //
  //  Event handler methods
  //
  /////////////////////////////////////////////////////////////////////

  /**
   * onClickPrev
   *
   * Fired when the user clicks the "Prev" month button.
   *
   * Sets the current date to the previous month and fires a date
   * changed event.
   */
  const onClickPrev = () => {
    setSelectedDate(selectedDate.minus({ month: 1 }));
  };

  /**
   * onClickNext
   *
   * Fired when the user clicks the "Next" month button.
   *
   * Sets the current date to the previous month and fires a date
   * changed event.
   */
  const onClickNext = () => {
    setSelectedDate(selectedDate.plus({ month: 1 }));
  };

  /**
   * onClickDate
   *
   * Fired when the user clicks the a calendar day.
   *
   * @param {event} event
   */
  const onClickDate = (event) => {
    const date = format.toDateTime(event.date);

    if (!date.hasSame(selectedDate, "day")) setSelectedDate(date);
  };

  /////////////////////////////////////////////////////////////////////
  //
  //  Lifecycle methods
  //
  /////////////////////////////////////////////////////////////////////

  return (
    <Box
      sx={{
        position: "relative !important",
        paddingRight: "0px !important",
        boxSizing: "border-box",
        minWidth: "300px",
        fontSize: "12px",
      }}
    >
      {/* Header */}
      <Box
        sx={{
          display: "flex",
          flexWrap: "nowrap",
          justifyContent: "space-between",
          height: "30px",
          fontSize: "1.5em",
          fontWeight: 500,
          marginBottom: "0.5em",
        }}
      >
        <Button size="small" onClick={onClickPrev}>
          &lt;
        </Button>
        <Box>{selectedDate.toFormat("MMMM yyyy")}</Box>
        <Button size="small" onClick={onClickNext}>
          &gt;
        </Button>
      </Box>
      <Month
        numWeeks={NUM_WEEKS}
        startDate={startDate}
        selectedDate={selectedDate}
        onDateRender={onDateRender}
        onClickDate={onClickDate}
      />
    </Box>
  );
};

export default Calendar;
